import { useBackendStore } from '~/stores/backend'
import { check1CAvailability } from '~/api/backend'

const names = [
  'index', 'order-create-locationFrom_locationTo',
  'order-create-locations', 'order-create', 'order-light', 
  'networkshops', 'run-schedule', 'order-manage', 'tariffs',
  'marketplace-ozon', 'marketplace-wildberries', 
  'marketplace-yandex-market', 'fast-delivery-capital', 
  'personal-order-create', 'personal-order-guid', 
  'personal-orders-tab', 'personal-auth'
]

export default defineNuxtRouteMiddleware(async (to) => {
  const backendStore = useBackendStore()
  const config = useRuntimeConfig()

  if (process.client && to?.name && names.includes(to.name) && !config?.public?.TEST) {
    try {
      const data = await check1CAvailability()
      backendStore.setBackendAvailable(data?.currentdate)
    } catch (error) {
      backendStore.setBackendAvailable(false)
    }
  } else {
    backendStore.setBackendAvailable(true)
  }
})
