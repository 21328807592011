<template>
  <form
      class="cb-form"
      @submit.prevent.stop
      @input="onInput"
  >
      <slot />
  </form>
</template>

<script setup lang="ts">
import { scrollToElem } from '~/common/functions';

const emit = defineEmits(['update:modelValue'])
const form = ref(null)

const props = defineProps({
  scrollToError: {
      type: Boolean,
      default: false,
  },
  modelValue: {
      type: Boolean,
      default: false
  },
})
defineExpose({ validate })

const boundFields = ref<any>({})
provide('bindFormField', bindFormField)
provide('unbindFormField', unbindFormField)

function bindFormField(name: any, ref: any) {
  boundFields.value[name] = ref
}

function unbindFormField(name: any) {
  delete boundFields.value[name]
}

async function formValidate(silent = false): Promise<boolean> {
  let result = true
  if (!props.modelValue) {
    silent = false
  }

  const hasAtLeastOneInputFocus = !!document.querySelector('input:focus')

  for (let key of Object.keys(boundFields.value)) {
    const ctrl = boundFields.value[key]
    if (ctrl) {
      const error = await ctrl.exposed.checkError(silent, hasAtLeastOneInputFocus)
      if (!silent && error) {
        result = false
      }
    }
  }
  return result
}

async function validate(silent = false ) {
  await nextTick()
  let result = await formValidate(silent)
  emit('update:modelValue', result)

  if (props.scrollToError) {
      nextTick(() => {
          scrollToElem('.vz-invalid-message');
      })
  }
  return result
}

async function onInput() {
  emit('update:modelValue', await formValidate(true))
}

</script>
