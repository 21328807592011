<template>
    <span class="vz-close-btn" :style="style" @click="onClick">
        <slot>
            <span class="mdi mdi-close" />
        </slot>
    </span>
</template>

<script setup lang="ts">
import { StyleValue } from 'vue';

const emit = defineEmits(['click'])

const props = defineProps({
    width: {
        type: Number,
        default: 40
    },
    height: {
        type: Number,
        default: 40
    },
    size: {
        type: Number,
        default: null
    },
})

const style = computed((): StyleValue => {
    return {
        'min-width': `${props.width}px`,
        'min-height': `${props.height}px`,
        'font-size': `${props.size}px`
    }
})


const onClick = (e: MouseEvent): void => {
    emit('click', e)
}
</script>