import {Meta} from '~/types/types';
import {useMemoize} from '@vueuse/core';
import {httpNewApi, httpApiV3, useVzFetch} from '~/composables/useVzFetch';
import {PersonalDiscount, PersonalDiscountData} from '~/types/components/personal/personal-discount';

export function getDiscount(body: { code: string }) {
  return useVzFetch(
      `${httpNewApi}/personal/discount/get`,
      { method: 'post', body }
  ) as Promise<{active: boolean}>
}

export function setDiscount(body: { code: string, active: boolean }) {
  return useVzFetch(
      `${httpNewApi}/personal/discount/set`,
      { method: 'post', body }
  ) as Promise<{message: string}>
}

export const getPersonalDiscountList = useMemoize((params?: Partial<Meta>) => {
  return useVzFetch<PersonalDiscountData>(`${httpApiV3}/personal/user/discount`, { params })
})

export const getPublicDiscountList = useMemoize((params?: Partial<Meta>) => {
  return useVzFetch<PersonalDiscount[]>(`${httpApiV3}/user/discount`, { params })
})
