import {ToastTextConfig} from '~/types/types';
import { render, h, VNode} from '@vue/runtime-dom';

export default defineNuxtPlugin( () => {
  return {
    provide: {
      showToastText: (options: ToastTextConfig) => {
        const toast = h(
            'div',
            { 
              class: 'vz-toast-text', 
              style: { 
                top: options.top, 
                left: options.left 
              } 
            },
            options.text
        )

        render(toast, options.el)

        setTimeout(() => {
          if (toast.el?.parentNode) {
            render(null, toast.el.parentNode)
          }
        }, options.autocloseDelay || 3000)

        return toast
      }
    }
  }
})
