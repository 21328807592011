import {useVzFetch, http, httpApiV3, isDevServer} from '~/composables/useVzFetch';
import CryptoJS from 'crypto-js'
import {AuthInfo, AuthData, ConfirmCodeData, VZResponse} from '~/types/api';

import isMobile from 'ismobilejs';
import {useMemoize} from '@vueuse/core';

export const login = (body: { login: string, password: string }): Promise<any> => {
  return $fetch(
      `${http}/user/login`,
      { method: 'post', body }
  )
}

export const getProfile = useMemoize((cacheToken: string|null): Promise<string|null> => {
  return useVzFetch(
      `${http}/profile`,
      { method: 'post' }
  )
})

export const getAuthInfo = (login: string|null) => {
  return useVzFetch<VZResponse<AuthInfo>>(
      `${httpApiV3}/user/auth`,
      { method: 'get', params: { login, authOrigin: 'website' } }
  )
}

export async function auth(body: AuthData) {
  return await useVzFetch<VZResponse<{ sessionKey: string, rpToken?: string }>>(
      `${httpApiV3}/user/auth`,
      { method: 'post', body, credentials: 'include' }
  ).catch((e) => {
    return e.response._data
  })
}

export const confirmCode = (body: ConfirmCodeData, backendAvailable?: string) => {
    const mobile = isMobile(window.navigator).any
    const config = useRuntimeConfig()
    const key = CryptoJS.enc.Utf8.parse(config.public?.API_KEY_CONFIRM_CODE);
    const iv = CryptoJS.enc.Utf8.parse(config.public?.API_IV_CONFIRM_CODE);

    const encrypted = CryptoJS.AES.encrypt(body.recipient, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const headers: any = {'X-Csrf-Token': `${encrypted}`, time: backendAvailable}
    if (isDevServer) {
        body.debug = localStorage.getItem('tg-debug') || useRuntimeConfig().public.TG_DEBUG || undefined
    }
    return useVzFetch<VZResponse<{ timeout: number }>>(
        `${httpApiV3}/confirm-code`,
        {
            method: 'post',
            body: {...body, authOrigin: 'website', isMobile: mobile},
            manual: true,
            credentials: 'include',
            headers
        }
    )
}