import { Country } from '~/types/types'

export const sumtaxId = (checkArr, arr): number => {
  return checkArr.map((num, index) => num * arr[index]).reduce((a, b) => a + b, 0)
}

export const checkInn = (value: string, param: Country): boolean => {
  if (!value) {
    return true
  }
  const v = value.toString()

  if (param === 'BY') {
    if (isNaN(Number(v)) || v.length > 12 || v.length === 11) {
      return false
    }

    if (v.length === 9) {
      return true
    }
  }

  if (param === 'AM') {
    return value.length === 8
  }

  if (param === 'KZ') {
    return value.length === 12
  }

  if (param === 'KG') {
    return value.length === 14
  }

  const checkL = [2, 4, 10, 3, 5, 9, 4, 6, 8]
  const checkP = [
    [7, 2, 4, 10, 3, 5, 9, 4, 6, 8],
    [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
  ]

  if (v.length === 10) {
    const sum = sumtaxId(checkL, v)
    const div = sum % 11
    return div % 10 === Number(v[9])
  }

  if (v.length === 12) {
    if (v === '000000000000') {
      return false
    }
    const sum = [sumtaxId(checkP[0], v), sumtaxId(checkP[1], v)]
    const div = sum.map(s => s % 11)
    return div[0] % 10 === Number(v[10]) && div[1] % 10 === Number(v[11])
  }

  if (v.length === 0 || (v.length > 9 && v.length !== 10 && v.length !== 12 && v.length !== 11)) {
    return true
  }

  return false
}

export const innValidator = (value: string, param: Country): boolean | string => {
  let errorMSg = 'Неверно введен ИНН'

  if (param === 'BY') {
    errorMSg = 'Неверно введен УНП'
  }
  if (param === 'KZ') {
    errorMSg = 'Неверно введен БИН'
  }
  return checkInn(value, param) || errorMSg
}
