<template>
  <div
    ref="$el"
    v-on-click-outside="onClickOutside"
    class="vz-select-dropdown"
    :class="{disabled, tiny}"
  >
    <label
      v-if="label"
      class="vz-input-label"
      :class="{'public-style': labelPublicStyle, 'required': required}"
      v-text="label"
    />
    <!--    <div v-if="label && clearable && modelValue" ref="dropdownLabel" class="vz-select-dropdown-label" :class="{ 'public-style': props.labelPublicStyle }">-->
    <vz-icon
      v-if="clearable && modelValue"
      class="vz-new-autocomplete-clear vz-cursor-pointer"
      name="close"
      variant="white-gray-ws"
      :size="13"
      rounded
      @click.stop="emit('update:modelValue', null)"
    />
    <!--    </div>-->
    <div
      ref="selectValue"
      class="vz-select-dropdown-value"
      :class="valueClass"
      tabindex="0"
      @click.stop="onOpenList"
    >
      <vz-icon
        v-show="mainIconUrl"
        :key="mainIconUrl"
        :size="iconSize"
        :name="iconName"
        :url="mainIconUrl"
        :color="iconColor"
        :variant="iconVariant"
        no-masked-icon
      />
      <span v-if="selectedItem" :title="selectedItem.title" class="vz-select-dropdown-value-title">{{selectedItem.title}}</span>
      <span v-else class="empty" style="display:block; height: 12px; width: 16px;"></span>
      <vz-icon
        v-if="hasCopy"
        class="vz-select-dropdown-value-copy"
        name="content-copy"
        :size="16"
        @click.stop.prevent="copyItemTitle(selectedItem.title)"
      />
    </div>
    <slot/>
    <teleport to="body">
      <transition name="fade">
        <div
          v-if="isOpen && hasItems"
          ref="itemsList"
          v-scroll="calculateListPosition"
          class="vz-select-dropdown-list vz-scroll"
          :class="itemsListClass"
        >
          <div
            v-for="(item, index) of items"
            :key="index"
            class="vz-select-dropdown-list-item"
            :class="listItemClass(item, index)"
            :title="getItemTitle(item)"
            @click="onClickByItem(item)"
          >
            <vz-icon
              v-if="item.url"
              :url="item.url"
              :size="16"
              no-masked-icon
              :title="getItemTitle(item)"
            />
            <span v-if="item.title" class="vz-select-dropdown-list-item-title">{{ item.title }}</span>
            <vz-tooltip-info
              v-if="withTooltips && item.tooltip"
              :instantHide="tooltipsInstantHide"
              class="ml-5"
            >
              <template #baloon>
                <span v-html="item.tooltip"/>
              </template>
            </vz-tooltip-info>
            <vz-icon
              v-if="item.hasCopy"
              class="vz-select-dropdown-list-item-copy"
              name="content-copy"
              :size="16"
              @click.stop.prevent="copyItemTitle(item.title)"
            />
          </div>
        </div>
      </transition>
    </teleport>
    <vz-invalid-message v-if="isShowError">
      {{ errorText }}
    </vz-invalid-message>
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue'
import {useProps} from '~/common/ui-kit-common'
import {KeyValueObject, SelectItem} from '~/types/types'
import {useIconProps} from "~/common/ui-kit-icon";
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzTooltipInfo from "~/components/ui-kit/vz-tooltip-info.vue";
import {useClipboard} from '@vueuse/core'

const emit = defineEmits([
  'update:modelValue',
  'change'
])

const props = defineProps({
  ...useProps,
  ...useIconProps,
  registerToValidate: {
    type: Boolean,
    default: true
  },
  modelValue: {
    type: [String, Number, Boolean],
    default: ''
  },
  items: {
    type: Array as PropType<SelectItem[] | any[]>,
    default: () => []
  },
  clearable: {
    default: false,
    type: Boolean
  },
  tiny: {
    default: false,
    type: Boolean
  },
  iconRight: {
    default: false,
    type: Boolean
  },
  withTooltips: {
    default: false,
    type: Boolean
  },
  tooltipsInstantHide: {
    default: false,
    type: Boolean
  },
  listClass: {
    default: '',
    type: String
  },
  manualScrollItem: {
    default: undefined,
    type: String
  },
  alwaysLoudValidation: {
    default: false,
    type: Boolean
  },
  hasCopy: {
    default: false,
    type: Boolean
  },
  optionSecondTypeItem: {
    default: '',
    type: String
  }
})

const {copy} = useClipboard()
const $el = ref<HTMLDivElement>()
const itemsList = ref({} as HTMLDivElement)
const selectValue = ref({} as HTMLDivElement)
const dropdownLabel = ref({} as HTMLLabelElement)
const isOpen = ref(false)
const currentIndex = ref(0)
const {isShowError, errorText, doValidate} = useValidateble()
const selectedItem = ref()

onMounted((): void => {
  if (isOpen.value) {
    setOpen(false)
  }
  selectValue.value?.addEventListener('keydown', onClickESC)
})

function copyItemTitle(title: string) {
  copy(title)
  useNuxtApp().$showToast({
    title: 'Скопировано',
    keepAlive: true
  })
}

async function checkError(silent: boolean = false) {
  return await doValidate(
    props.modelValue,
    [
      ...props.rules,
      {
        cb: () => {
          if (selectedItem?.value?.disabled) {
            return 'Выбранное значение недоступно'
          }
          return true
        }
      }
    ],
    props.alwaysLoudValidation ? false : silent
  )
}

watch(() => [props.modelValue, props.items], (n, o) => {
  if (JSON.stringify(n) !== JSON.stringify(o)) {
    const item = props.items.find(i => i.value === props.modelValue)
    // if (item) {
    selectedItem.value = item
    emit('update:modelValue', item?.value)
    const hasDisaibledItem = props.items.find(i => i.disabled !== undefined)
    if (hasDisaibledItem) {
      checkError()
    }
    // }
  }
}, {immediate: true})

const hasItems = computed((): boolean => {
  return props.items.length > 0
})

const mainIconUrl = computed((): string => {
  return props.iconUrl || selectedItem?.value?.url || ''
})

const valueClass = computed((): KeyValueObject => {
  return {
    big: props.big,
    large: props.large,
    open: isOpen.value,
    'icon-right': props.iconRight,
    'has-error': isShowError.value,
    'has-copy': props.hasCopy,
    'option-color-type': props.optionSecondTypeItem,
    [`option-color-type__${props.optionSecondTypeItem}`]: props.optionSecondTypeItem
  }
})

const itemsListClass = computed((): KeyValueObject => {
  return {
    open: isOpen.value,
    [props.listClass]: !!props.listClass
  }
})

function getItemTitle(item: SelectItem): string | undefined {
  return (!props.withTooltips && item.tooltip) || undefined
}

function onClickByItem(item: SelectItem): void {
  if (item.disabled) {
    return
  }
  setOpen(false)
  emit('update:modelValue', item.value)
  emit('change', item)
  nextTick(() => {
    checkError()
    $el.value?.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}))
  })
}

function isActive(item: SelectItem): boolean {
  return item.value === props.modelValue
}

function onOpenList(): void {
  if (props.readonly) {
    return
  }

  const scrollToItem = selectedItem.value || props.items.find(v => v.value === props.manualScrollItem)
  if (scrollToItem) {
    currentIndex.value = props.items.findIndex(i => i.value === scrollToItem.value)
  }
  setOpen(!isOpen.value && !props.disabled)
  nextTick(() => {
    calculateListPosition()
    const divItems = itemsList.value?.querySelectorAll?.('.vz-select-dropdown-list-item')
    if (divItems?.length && scrollToItem) {
      const divItem = divItems[currentIndex.value]
      if (divItem) {
        divItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  })
}

function onClickOutside(e: any): void {
  const isTargetCopy = e.target?.classList.contains('vz-select-dropdown-list-item-copy')
  if (!isTargetCopy && isOpen.value) {
    setOpen(false)
  }
}

function listItemClass(item: SelectItem, index: number): KeyValueObject {
  return {
    active: isActive(item) || index === currentIndex.value,
    'icon-right': props.iconRight,
    disabled: item.disabled,
    big: props.big,
    'option-color-type': item.secondType,
    [`option-color-type__${item.secondType}`]: item.secondType
  }
}

function calculateListPosition(): void {
  if (!itemsList.value) {
    return
  }

  const rect = itemsList.value?.getBoundingClientRect?.()
  const {top, height, width, left} = selectValue.value?.getBoundingClientRect() || {
    top: 0,
    height: 0,
    width: 0,
    left: 0
  }
  if (rect) {
    const fullHeight = top + height + rect.height
    itemsList.value.style.left = `${left}px`
    itemsList.value.style.width = `${width}px`
    if (fullHeight > window.innerHeight) {
      itemsList.value.style.top = `${top - rect.height}px`
      itemsList.value.style.boxShadow = '2px -2px 4px rgba(0, 0, 0, 0.15)'
    } else {
      itemsList.value.style.top = `${top + height}px`
      itemsList.value.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.15)'
    }
  }
}

function onClickESC(event: KeyboardEvent): void {
  if (event.code === 'Escape' && isOpen.value) {
    setOpen(false)
    event.stopPropagation()
  }

  if (isOpen.value && ['ArrowDown', 'ArrowUp', 'PageUp', 'PageDown'].includes(event.code)) {
    event.preventDefault()
    moveSelection(event)
  }

  if (isOpen.value && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
    event.preventDefault()
    onSelect()
  }
}

function setOpen(value: boolean): void {
  isOpen.value = value
}

function onSelect(): void {
  const item = props.items[currentIndex.value]
  onClickByItem(item)
}

function moveSelection(event: KeyboardEvent): void {
  if (currentIndex.value < props.items.length - 1 && event.code === 'ArrowDown') {
    currentIndex.value += 1
  }

  if (isOpen.value && event.code === 'ArrowUp' && currentIndex.value > 0) {
    currentIndex.value -= 1
  }
  if (event.code === 'PageUp') {
    currentIndex.value = Math.max(currentIndex.value - 4, 0)
  }
  if (event.code === 'PageDown') {
    currentIndex.value = Math.min(currentIndex.value + 4, props.items.length - 1)
  }

  nextTick(() => {
    if (itemsList.value) {
      const divItems = itemsList.value.querySelectorAll('.vz-select-dropdown-list-item')
      if (divItems.length) {
        const divItem = divItems[currentIndex.value]
        if (divItem) {
          divItem.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    }
  })
}

defineExpose({
  isShowError,
  checkError
})
</script>
