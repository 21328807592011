import {cookieItems} from '~/composables/useVzFetch';
import {cookieOptions} from '~/common/functions';
import {getProfile} from '~/api/user';
import {getPromoUnread} from '~/api/promocode';
import {getTaskList} from '~/api/bonus';
import {getPersonalDiscountList} from '~/api/discount'; 
import {defineStore} from 'pinia';
import {AuthInfo as AuthInfoType} from '~/types/api';
import {PersonalDiscount} from '~/types/components/personal/personal-discount';
import {useTokenCookie, useUsernameCookie} from '~/composables/states';

export const useUserStore = defineStore('user', () => {
  const username = useUsernameCookie()
  const token = useTokenCookie()
  const wasLogged = useCookie(cookieItems.wasLogged, cookieOptions())

  const unreadNewsCount = ref(3)
  const unreadPromoCount = ref(0)
  const unreadPromoSourceCount = ref(0)
  const unreadTaskCount = ref(false)
  const personalDiscount = ref<PersonalDiscount[]>()
  const authInfo = ref<AuthInfoType>({ login: '', authType: 'password', context: '', password: false })

  const isAuth = computed(() => {
    return !!token.value
  })

  async function fetchLogin() {
    username.value = await getProfile(token.value)
    if (username.value === null) {
      token.value = null
    }
  }

  function clearAuthInfo() {
    authInfo.value =  { login: '', authType: 'password', context: '', password: false }
  }

  watch(
    () => isAuth.value,
    async () => {
      if (!isAuth.value || !process.client) {
        return
      }

      await fetchLogin()

      if (!username.value) {
        return
      }

      getTaskList().then(({meta}) => {
        unreadTaskCount.value = !!meta.totalPage
      })

      getPersonalDiscountList().then(({data}) => personalDiscount.value = data)

      getPromoUnread().then(v => {
        unreadPromoCount.value = v.promocode
        unreadPromoSourceCount.value = v.source
      })

      if (!wasLogged.value) {
        wasLogged.value = '1'
      }
    },
    { immediate: true, flush: 'post' }
  )

  return {
    username,
    unreadNewsCount,
    unreadPromoSourceCount,
    unreadPromoCount,
    unreadTaskCount,
    personalDiscount,
    token,
    wasLogged,
    isAuth,
    authInfo,

    fetchLogin,
    clearAuthInfo
  }
})
