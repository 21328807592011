<template>
  <div class="registration-discount">
    <header>
      <slot name="header-prepend" :list="discountList" />
      <div v-if="showHeader">
        <slot name="header">
          Воспользуйтесь одним из <span class="color-primary">персональных предложений</span>
        </slot>
      </div>
    </header>
    <template v-if="showList">
      <div class="registration-discount-list">
        <template v-if="discountList.length">
          <nuxt-link
              v-for="discount of discountList"
              :to="{ name: 'registration', query: { discountGuid: discount.params.discountGuid } }"
              @click="emit('select', discount.params.discountGuid)"
          >
            <vz-icon :url="discount.params.icon" size="56" variant="primary" class="mb-20" />
            <div class="registration-discount-list-value">
              {{ discount.discountValue }}
              <span>{{ discount.discountType }}</span>
            </div>
            <div>{{ discount.service }}</div>
            <div v-if="discount.note" class="mt-10 fs-12 color-inactive">{{ discount.note }}</div>

            <div class="registration-discount-list-choose">
              Выбрать
            </div>
          </nuxt-link>
        </template>
        <template v-else>
          <a
              v-for="i of 4"
              href="#"
              @click.prevent
              style="height: 236px"
          />
        </template>
      </div>
      <div class="registration-discount-list-information">
        <vz-icon name="alert" variant="primary" size="36" />
        <div>
          <p>Максимальная сумма скидки: <span style="white-space: nowrap">3000 руб.</span></p>
          <p>Доступно новым пользователям при регистрации в личном кабинете.</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {PersonalDiscount} from "~/types/components/personal/personal-discount";
import {getPublicDiscountList} from "~/api/discount";
import VzIcon from "~/components/ui-kit/vz-icon.vue";

const emit = defineEmits(['select'])

const props = defineProps({
  showList: {
    type: Boolean,
    default: true
  },
  showHeader: {
    type: Boolean,
    default: true
  }
})

const discountList = ref<PersonalDiscount[]>([])


function getImage(item: PersonalDiscount) {
  switch (item.params?.code) {
    case 'starCup':
      return '/svg/vz-cup.svg'
    case 'cargoCart':
      return '/svg/vz-cargo-cart.svg'
    case 'gift':
      return '/svg/icon/vz-gift-2.svg'
    case 'box':
      return '/svg/package-2.svg'
    default:
      return item.params?.code
  }
}

onMounted(async () => {
  discountList.value = (await getPublicDiscountList()).map(item => {
    if (item.params) {
      item.params.icon = getImage(item)
    }
    return item
  })
})

defineExpose({discountList})
</script>

<style lang="scss" scoped>

</style>
