export const notEqual = (val: string, params, customMessage = ''): boolean | string => {
  const values = params.map(({ value }) => value)
  let notEqualValue = ''
  let count = 0
  if (values.length > 1) {
    values.forEach((value) => {
      if (value === val) {
        count += 1
        notEqualValue = value
      }
    })
  }
  console.log(values, val, count, count <= 1 ? true : customMessage?.replace('$1', val).replace('$2', notEqualValue) || `Значние ${val} совпадает со значением ${notEqualValue}`)
  return count <= 1 ? true : customMessage?.replace('$1', val).replace('$2', notEqualValue) || `Значние ${val} совпадает со значением ${notEqualValue}`
}
