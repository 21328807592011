import {
  Timeout,
  OrderWrappingModParameters, Location, Country
} from '~/types/types'

import {BASE_DOMAIN_MAIN} from '~/composables/useVzFetch';

// time '18:00' format
export function objTime(time: string) {
  if (!time) {
    return 0
  }
  const times = time.split(':')
  if(+times[1] >= 0) {
    return {hour: +times[0], minute: +times[1]}
  }
  return time || 0
}

export function scrollToElem(
  elemClass: string,
  options: ScrollIntoViewOptions = {
    behavior:'smooth',
    block: 'center',
    inline: 'center'
  }
) {
  nextTick( () => {
    if ( document ) {
    const elem = document.querySelector(elemClass)
    if ( elem ) {
      elem.scrollIntoView({
        behavior: options.behavior,
        block: options.block,
        inline: options.inline
      })
    }}
  })
}

export function debounce(timeout: Timeout, callBack: () => void, delay = 300): Timeout {
  if (timeout) {
    clearTimeout(timeout)
  }
    return setTimeout(async () => {
        await callBack()
    }, delay)
}


export function clearEmpties(obj?: Record<string, any> | null, deep = false): any {
  if (!obj) {
    return obj
  }
  if (Array.isArray(obj)) {
    return obj.length ? obj : null
  }

  const newObj = { ...obj }
  Object.entries(obj).forEach(([key, value]) => {
    const isObject = typeof value === 'object'
    if (deep && isObject) {
      newObj[key] = clearEmpties(newObj[key], true)
    }
    if (!newObj[key] || (isObject && !Object.values(newObj[key]).filter(v => v !== undefined).length)) {
      delete newObj[key]
    }
  })
  return newObj
}

export const deepCopyObject = <T>(obj: T): T => {
  try {
    if (!obj) {
      return obj
    }
    return JSON.parse(JSON.stringify(obj))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ error: 'Ошибка преобразования объекта в функции deepCopyObject', rawErorr: e, obj })
    return obj
  }
}

export const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
}

export function getDomain(host = '') {
  // host = host || ((process.client && window) ? window.location?.hostname : BASE_DOMAIN_MAIN as string) || ''
  host = host || ((process.client && window) ? window.location?.hostname : (useNuxtApp().ssrContext?.event.node.req.headers.host || BASE_DOMAIN_MAIN) as string) || ''
  if (host.includes('localhost')) {
    // http потом удалится, это просто для удобства удаления порта
    let url = new URL(`https://${host}`)
    url.port = ''
    host = url.hostname
  }
  const parts = host.split('.')
  return `${parts.length > 2 ? `${parts.slice(-2).join('.')}` : parts.join('.')}`
}

export function cookieOptions(host: string|undefined = undefined) {
  return {
    maxAge: 10000000,
    // sameSite: true,
    secure: true,
    domain: `.${getDomain(host)}`
  }
}

export function removeUrlParam (key: string, sourceURL: string) {
  let rtn = sourceURL.split('?')[0]
  let param
  let paramsArr: string[] = []
  const queryString = (sourceURL.includes('?')) ? sourceURL.split('?')[1] : ''
  if (queryString !== '') {
    paramsArr = queryString.split('&')
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      param = paramsArr[i].split('=')[0]
      if (param === key) {
        paramsArr.splice(i, 1)
      }
    }
    if (paramsArr.length > 0) {
      rtn = rtn + '?' + paramsArr.join('&')
    }
  }
  return rtn
}

export function getFullLinkRedirect (city: Partial<Location>, link: string, host = '', setCity = '') {
  if (!city || !link) { return '' }

  let abbr = city.abbr || city.translit || ''
  if (city.rcl_guid) {
    abbr = ''
  }
  host = host || ((process.client && window) ? window.location?.host : (useNuxtApp().ssrContext?.event.node.req.headers.host || BASE_DOMAIN_MAIN) as string) || ''
  const endDomain = link[0] === '/' ? '' : '/'
  const domain = getNewDomainLink(abbr, city.country || '', host) + endDomain
  if (link.includes('set_city')) {
    link = removeUrlParam('set_city', link)
  }

  const end = link.substr(-1) === '/' ? '' : (!link.includes('?') && !link.includes('&') ? '/' : '')
  link = link + end
  if (!host.includes('.' + city.country?.toLowerCase()) /*&& !host.includes('localhost')*/) {
    link = link + (link.includes('?') ? '&' : '?') + 'set_city=' + (setCity || abbr)
  }
  return domain + link
}

function getNewDomainLink(abbr: string, country: string, host = ''): string {
  // const domain = abbr && !['msk', 'minsk', 'ekaterinburg', 'nur-sultan', 'almaty', 'astana', 'karaganda', 'bishkek', 'erevan'].includes(abbr)
  //     ? abbr + '.'
  //     : ''
  const domain = ''
  const hostArr = host.split('.')

  /** Удаляем текущий поддомен */
  if (hostArr.length === 3) {
    hostArr.splice(0, 1)
  }
  const currentDomain = hostArr[hostArr.length - 1]

  /** Переадресация на Страны. Если это не dev, xyz, org и не local */
  if (![country?.toLowerCase(), 'dev', 'xyz', 'org'].includes(currentDomain) && !currentDomain.includes('localhost')) {
    hostArr[hostArr.length - 1] = country?.toLowerCase()
  }
  host = hostArr.join('.')

  return '//' + domain + host
}

export function getCountryByPhone(phone: string, strict = true): Country|false {
  if (phone.includes('@')) {
    return false
  }

  phone = phone.replace(/\D/g, '')
  if (phone.startsWith('375') && (phone.length === 12 || !strict)) {
    return 'BY'
  }
  if (phone.startsWith('374') && (phone.length === 11 || !strict)) {
    return 'AM'
  }
  if (phone.startsWith('996') && (phone.length === 12 || !strict)) {
    return 'KG'
  }
  if ((phone.length === 11 || !strict) && (phone.startsWith('77') || phone.startsWith('76') || phone.startsWith('87') || phone.startsWith('86'))) {
    return 'KZ'
  } else if ((phone.length === 11 || !strict) && (phone.startsWith('7') || phone.startsWith('8'))) {
    return 'RU'
  }
  return false
}

export const promiseDelay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function getModCheckFunction(type: OrderWrappingModParameters['conditions']): (valueLeft: number, valueRight: number) => boolean {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let fc = (valueLeft: number, valueRight: number): boolean => false
  switch (type) {
    case '>=':
      fc = (valueLeft: number, valueRight: number): boolean => {
        return valueLeft >= valueRight
      }
      break
    default:
  }
  return fc
}

export function setYM (id: number, name: string, domain?: string): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setTimeout(() => {
    // @ts-ignore
    if (typeof window !== 'undefined' && typeof window.ym !== 'undefined') {
      if (name === '404') {
        const ref = document.referrer
        const siteUrl = document.location.href
        const params = { NotFoundURL: { [siteUrl]: { referrer: ref } } }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-undef
        ym(id, 'reachGoal', name, params)
      } else if (domain) {
        const d = import.meta.env?.VITE_ENV_DOMAIN || getDomain()
        if (d.includes(domain)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-undef
          ym(id, 'reachGoal', name)
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-undef
        ym(id, 'reachGoal', name)
      }
    }
  }, 1000)
  console.log(`yandex-goal name=${name}`)
}

export function getDeepValue(o: any, p: string) {
  return p.split('.').reduce((a, v) => a?.[v] || '', o)
}

export function getNextFocusEl(allFocusEl, activeFocusEl) {
  try {
    const index = [...allFocusEl]?.indexOf(activeFocusEl);
    if (index >= 0) {
      return allFocusEl[index + 1] || allFocusEl[0] as HTMLElement
    }
  } catch (error) {
    console.log('Problem with focus')
  }
}

const year = new Date().getFullYear()
export const orderNumberPlaceholder = `${year.toString().slice(-2)}0******`
