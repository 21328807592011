<template>
  <vz-form v-model="isValid" ref="formRef">
    <vz-row class="vz-auth-form">
      <div class="vz-auth-form-title">
        <slot name="title">
          <h2>{{ title }}</h2>
        </slot>
      </div>

      <vz-input
        ref="inputControl"
        v-model="form.login"
        large
        :rules="[formRules.required, {...formRules.emailOrPhone, customMessage: 'Неверный логин'}]"
        placeholder="Телефон или E-mail*"
        name="email"
        @keydown.enter="submit"
        @input="formRef.validate(false)"
      />
      <input
        v-show="false"
        v-model="form.passwordValue"
        type="password"
        placeholder="Пароль"
      />

      <div v-if="otherCountryHost" class="vz-align-center mt-2">
        Для продолжения с использованием указанного номера необходимо перейти на
        <a :href="otherCountryLink">{{ otherCountryHost }}</a>.
      </div>

      <div v-else-if="error && isValid" class="fs-12 color-error mt-15">
        {{ error }}
      </div>

      <vz-button
        variant="primary"
        large
        class="flex flex-center login-button"
        :disabled="!isValid"
        :href="otherCountryLink"
        @click="submit"
      >
        {{ btnName }}
      </vz-button>
    </vz-row>
    <public-contacts class="mt-25" />
  </vz-form>
</template>

<script setup lang="ts">
import formRules from '~/common/vz-form-rules';
import {getAuthInfo} from '~/api/user';
import {getCountryByPhone, getFullLinkRedirect} from '~/common/functions';
import {useDomainCountry} from '~/composables/states';
import {useCityStore} from '~/stores/city';
import {storeToRefs} from 'pinia';
import PublicContacts from '~/components/public/public-contacts.vue';

const emit = defineEmits(['submit'])

const props = defineProps({
  title: {
    type: String,
    default: 'Вход в личный кабинет'
  },
  regOnly: {
    type: Boolean,
    default: false
  }
})

const domainCountry = useDomainCountry()
const { currentCity } = storeToRefs(useCityStore())
const route = useRoute()

const formRef = ref()
const inputControl = ref()

const isValid = ref(true)
const error = ref('')
const form = ref({
  login: '',
  passwordValue: ''
})

const otherCountryLink = computed(() => {
  const country = getCountryByPhone(form.value.login)
  if (!(country && domainCountry.value !== country && import.meta.env.NODE_ENV !== 'development')) {
    return ''
  }

  return getFullLinkRedirect(
    { ...currentCity.value, country, abbr: '', translit: '' },
    `/personal/auth/?login=${encodeURIComponent(form.value.login)}`,
    '',
    currentCity.value.abbr || currentCity.value.translit || ''
  )
})

const otherCountryHost = computed(() => {
  return otherCountryLink.value
    ? (new URL(`https:${otherCountryLink.value}`)).hostname
    : ''
})

const btnName = computed(() => {
  return otherCountryLink.value ? 'Перейти' : 'Далее'
})

async function submit() {
  await formRef.value.validate(false)
  if(props.regOnly && !route.query.discountGuid) {
    error.value = 'Необходимо выбрать скидку!'
    return
  }

  if (!isValid.value) {
    return
  }

  if (otherCountryLink.value) {
    window.location.href = otherCountryLink.value
    return
  }

  error.value = ''
  const res = await getAuthInfo(form.value.login)
  if (res.error || res.errors) {
    error.value = res.error?.message || Object.values(res.errors || {}).join(', ')
  } else if (res) {
    emit('submit', { ...res, ...form.value })
  }
}

onMounted(() => {
  inputControl.value.inputControl.select()

  if (route.query?.login) {
    form.value.login = route.query?.login as string
  }
})

watch(() => route.query.discountGuid, (val) => {
  if (val) {
    error.value = ''
  }
})
</script>

<style lang="scss" scoped>

</style>
