<template>
  <div
    ref="target"
    class="vz-tooltip"
    :class="{ 'vz-tooltip-flex': flex }"
    @mouseover="open"
    @mouseleave="startTimer"
    @touchend="onTouch"
  >
    <slot />

    <teleport to="body">
      <transition name="fade">
        <div
          v-if="show"
          class="vz-tooltip-body"
          :class="`vz-tooltip-body-${dynamicPlacement}`"
          :style="bodyStyle"
          @mouseenter="stopTimer"
          @mouseleave="startTimer"
          v-on-click-outside="onClickOutside"
        >
          <div
            class="vz-tooltip-balloon-arrow"
            @mousedown.stop
          />
          <div
            ref="tooltipBalloon"
            v-scroll="scrollHandler"
            class="vz-tooltip-balloon"
            @mousedown.stop
          >
            <slot name="balloon" />
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import {VzClass, Timeout, Placement} from '~/types/types'
import {PropType, StyleValue} from 'vue'
import {emitter} from '~/common/event-bus'

const emit = defineEmits(['update:modelValue'])

const slots = useSlots()
const props = defineProps({
  placement: {
    type: String as PropType<Placement>,
    default: 'bottom'
  },
  duration: {
    type: Number,
    default: 400
  },
  disabled: {
    type: Boolean,
    default: false
  },
  flex: {
    type: Boolean,
    default: false
  },
  manual: {
    type: Boolean,
    default: false
  },
  manualClose: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [Boolean, Number, String],
    default: false
  },
  instantHide: {
    type: Boolean,
    default: false
  }
})

const oppositePlacement: Record<Placement, Placement[]> = {
  top: ['bottom', 'top-right', 'top-left'],
  bottom: ['top', 'bottom-left', 'bottom-right'],
  right: ['right-bottom', 'left'],
  left: ['left-bottom', 'right'],
  'bottom-right': ['bottom-left', 'top-right'],
  'bottom-left': ['bottom-right', 'top-left'],
  'top-right': ['top-left', 'bottom-right'],
  'top-left': ['top-right', 'bottom-left'],
  'right-bottom': ['left-bottom'],
  'left-bottom': ['right-bottom'],
  xs: []
}
const defaultShift = 10

const target = ref<HTMLDivElement | null>(null)
const tooltipBalloon = ref<HTMLDivElement | null>(null)

let dynamicPlacement = ref(props.placement)
let show = ref(false)

let hiding = false
let timer: Timeout = null
const uid = getCurrentInstance()?.uid

let targetPositions = ref({
  top: '0px',
  left: '0px'
})

const bodyStyle = computed((): StyleValue => {
  return {
    ...targetPositions.value
  }
})

onMounted((): void => {
  emitter.on('show-tooltip', (id) => {
    if (uid !== id) {
      hide(true)
    }
  })
})

watch(dynamicPlacement, () => {
  calculatePosition()
})

watch(() => props.modelValue,
  () => {
    if (props.modelValue) {
      open()
    } else {
      hide(true)
    }
  }
)

const onTouch = (): void => {
  if (show.value || props.modelValue) {
    hide(true)
  } else {
    open()
  }
}

const startTimer = (): void => {
  if(props.instantHide) {
    hide(true)
  }

  if (timer || props.disabled || props.manual) {
    return
  }
  stopTimer()
  timer = setTimeout(hide, props.duration)
  hiding = true
}

const stopTimer = (): void => {
  if (timer) {
    clearTimeout(timer)
  }
  timer = null
  hiding = false
}

const hide = (force = false): void => {
  if (hiding || force) {
    timer = null
    hiding = false
    show.value = false
    emit('update:modelValue', false)
  }
}

const open = (): void => {
  if (props.disabled || !slots.balloon || (props.manual && !props.modelValue)) {
    return
  }

  calculatePosition()
  timer = null
  show.value = true
  hiding = false
  nextTick(() => {
    calculatePlacement()
  })
  emitter.emit('show-tooltip', uid)
}

const scrollHandler = (): void => {
  if (props.disabled) {
    return
  }

  calculatePlacement()
  calculatePosition()
}

const calculatePosition = (): void => {
  if (!target.value) {
    return
  }
  const {top, left, width, height} = target.value.getBoundingClientRect()
  let offsetX = 0
  let offsetY = 0

  if (dynamicPlacement.value.startsWith('top')) {
    offsetX = width / 2
  }
  if (dynamicPlacement.value.startsWith('bottom')) {
    offsetX = width / 2
    offsetY = height
  }
  if (dynamicPlacement.value.startsWith('right') || dynamicPlacement.value.startsWith('left')) {
    const isRight = dynamicPlacement.value.startsWith('right')
    offsetY = height / 2
    if (isRight) {
      offsetX = width
    }
    if (dynamicPlacement.value.endsWith('bottom')) {
      offsetY -= 2 * defaultShift
    }
    if (dynamicPlacement.value.endsWith('top')) {
      offsetY += 2 * defaultShift
    }
  }
  if (dynamicPlacement.value === 'xs') {
    offsetY = height / 2
  }

  targetPositions.value = {
    top: `${top + offsetY}px`,
    left: `${left + offsetX}px`
  }
}

const calculatePlacement = (): void => {
  if (!tooltipBalloon.value || !target.value) {
    return
  }

  const availablePlacements = new Set(Object.keys(oppositePlacement)) as Set<Placement>
  // положение таргета
  const {top, left, bottom, right} = target.value.getBoundingClientRect()
  const width = tooltipBalloon.value.offsetWidth
  const height = tooltipBalloon.value.offsetHeight
  const {clientWidth, clientHeight} = document.documentElement
  const centerX = (left + right) / 2

  if (left < defaultShift) {
    dynamicPlacement.value = 'right'
    return
  } else if (clientWidth - right < defaultShift) {
    dynamicPlacement.value = 'left'
    return
  }

  // Проверяем возможные положения. defaultShift - это отступ от края таргета
  if (right + width + defaultShift > clientWidth) {
    availablePlacements.forEach(pl => {
      if (pl.includes('right')) {
        availablePlacements.delete(pl)
      }
    })
  }
  if (centerX + width - defaultShift > clientWidth) {
    availablePlacements.delete('bottom-right')
    availablePlacements.delete('top-right')
  }
  if (centerX + width / 2 > clientWidth || centerX < width / 2) {
    availablePlacements.delete('bottom')
    availablePlacements.delete('top')
  }
  if (left < width + defaultShift) {
    availablePlacements.forEach(pl => {
      if (pl.includes('left')) {
        availablePlacements.delete(pl)
      }
    })
  }
  if (centerX < width + defaultShift) {
    availablePlacements.delete('bottom-left')
    availablePlacements.delete('top-left')
  }

  if (top < height + defaultShift) {
    availablePlacements.delete('top-left')
    availablePlacements.delete('top-right')
    availablePlacements.delete('top')
  }
  if (bottom + height + defaultShift > clientHeight) {
    availablePlacements.delete('bottom-left')
    availablePlacements.delete('bottom-right')
    availablePlacements.delete('bottom')
  }

  if (availablePlacements.has(props.placement) || availablePlacements.size === 0) {
    dynamicPlacement.value = props.placement
    return
  }
  // Ставим противоположную позицию, если возможно, либо xs позицию
  const intersection = oppositePlacement[props.placement].filter(oppPlacement => availablePlacements.has(oppPlacement))
  dynamicPlacement.value = intersection[0] || 'xs'
}

function onClickOutside() {
  if (props.manual && props.modelValue && !props.manualClose) {
    hide(true)
  }
}
</script>
