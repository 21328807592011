import {httpNewApi, useVzFetch} from '~/composables/useVzFetch';
import {Meta, PromoCodeSource, PromocodeUnreadCount, PromoSourceData} from '~/types/types';
import {IPromoCodeListResponse, IPromoCodeSubmitRequest, IRequestError} from '~/types/api';

export function getPromoCodeList(body: Pick<Meta, 'limit'|'page'>) {
  return useVzFetch(
      `${httpNewApi}/personal/promocode-list/get`,
      { method: 'POST', body }
  ) as Promise<IPromoCodeListResponse>
}

export function getPromoUnread() {
  return useVzFetch(
      `${httpNewApi}/personal/promocode/get-unread`,
      { method: 'POST' }
  ) as Promise<PromocodeUnreadCount>
}

export function setPromoRead(readList: number[]) {
  return useVzFetch(
      `${httpNewApi}/personal/promocode/set-read`,
      { method: 'POST', body: { readList } }
  ) as Promise<PromocodeUnreadCount>
}

export function getSourceData(body: Pick<PromoCodeSource, 'code'|'param'>) {
  return useVzFetch(
      `${httpNewApi}/personal/promocode/get-source`,
      { method: 'POST', body }
  ) as Promise<PromoSourceData>
}

export function submitPromoCode(body: IPromoCodeSubmitRequest) {
  return useVzFetch(
      `${httpNewApi}/personal/promocode/submit-request`,
      { method: 'POST', body }
  ) as Promise<{title: string, text: string, error?: IRequestError}>
}
