<template>
  <teleport to="body">
    <transition name="fadedialog">
      <div
        v-if="isShow"
        class="vz-dialog-card"
        :class="classes"
        @click="emit('click', $event)"
      >
        <vz-close-btn v-if="needClose" class="vz-close-btn-absolute" @click="close" />
        <vz-loader v-if="isShow" :status-loader="loader || modalLoader" />
        <div
          v-if="!hideHeader"
          class="vz-dialog-card-header"
          :class="headerClasses"
        >
          <span class="vz-dialog-card-header-title" :title="title">
            <slot name="title">
                {{ title }}
            </slot>
          </span>
          <vz-close-btn v-if="closable" class="close-btn" @click="close" />
        </div>
        <div class="vz-dialog-card-content" @scroll="onScroll">
          <div class="vz-dialog-card-content-wrapper">
            <slot />
            <div v-if="!hideOffset" class="vz-dialog-card-content-offset" />
          </div>
        </div>
        <div v-if="!hideFooter" class="vz-dialog-card-buttons">
          <slot name="footer">
            <vz-button
              variant="primary"
              big
              @click="onConfirm(true)"
            >
              Подтвердить
            </vz-button>
            <vz-button
              variant="dark-gray"
              big
              @click="onCancel(false)"
            >
              Отмена
            </vz-button>
          </slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup lang="ts">
import { useLayoutStore } from '~/stores/personal/layout';
import { KeyValueObject, vzDialogEmitData } from '~/types/types'
import { emitter } from "~/common/event-bus"
import {storeToRefs} from 'pinia';
import VzButton from '~/components/ui-kit/vz-button.vue';
import VzLoader from '~/components/ui-kit/vz-loader.vue';
import VzCloseBtn from '~/components/ui-kit/vz-close-btn.vue';

const emit = defineEmits(['confirm', 'cancel','show', 'showed', 'click', 'hide', 'close'])
const props = defineProps({
    small: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
    name: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    class: {
      type: String,
      default: ''
    },
    titleCenter: {
        type: Boolean,
        default: false
    },
    titleRight: {
        type: Boolean,
        default: false
    },
    closable: {
        type: Boolean,
        default: true
    },
    hideFooter: {
        type: Boolean,
        default: false
    },
    hideHeader: {
        type: Boolean,
        default: false
    },
    needClose: {
        type: Boolean,
        default: false
    },
    autoShow: {
        type: Boolean,
        default: false
    },
    hideOffset: {
        type: Boolean,
        default: false
    },
    loader: {
        type: Boolean,
        default: false
    },
    fullScreen: {
        type: Boolean,
        default: false
    },
    public: {
        type: Boolean,
        default: false
    }
})

const layoutStore = useLayoutStore()
const { setIsOverlayShow } = layoutStore
const { modalLoader } = storeToRefs(layoutStore)
const isShow = ref(false)
const scrollPosition = ref(0)
let dialogEmmitData: vzDialogEmitData|null = null

onMounted((): void => {
    if (props.autoShow) {
        show()
    }
    if (props.closable) {
        window.addEventListener('keydown', onESCKeyDown)
    }
    emitter.on('show-dialog', onShow)
    emitter.on('hide-dialog', onHide)
    emitter.on('hide-all-dialog', () => {
      onHide({ name: props.name })
    })

})

const onShow  = (data: vzDialogEmitData) => {
    if ( props.name === data.name ) {
        dialogEmmitData = data
        show()
    }
}

const onHide = async (data:vzDialogEmitData ) => {
    if ( props.name === data.name ) {
        if ( data?.callbacks?.hide ) {
            await data.callbacks.hide()
        }
        hide()
    }

}
onUnmounted((): void => {
    if (props.closable) {
        window.removeEventListener('keydown', onESCKeyDown)
    }
    if (isShow.value) {
        hide()
    }
})

const classes = computed((): KeyValueObject => {
  const attrClass = props.class as string
  return {
    'small-size': props.small,
    'big-size': props.big,
    'full-screen': props.fullScreen,
    'public-dialog' : props.public,
    [attrClass]: attrClass
  }
})

const headerClasses = computed((): KeyValueObject => {
    return {
        'title-center': props.titleCenter,
        'title-right': props.titleRight
    }
})

const onConfirm = async (val: boolean): Promise<void> => {
  if ( dialogEmmitData?.callbacks?.confirm ) {
    await dialogEmmitData.callbacks.confirm()
  }
  emit('confirm', val)
  hide()
}

const onCancel = async (val: boolean): Promise<void> => {
  if ( dialogEmmitData?.callbacks?.cancel ) {
    await dialogEmmitData.callbacks.cancel()
  }
  emit('cancel', val)
  hide()
}

const show = (): void => {
  document.body.classList.add('noscroll') // запрещаем прокрутку body, чтобы убрать внешний scroll страницы в full-screen окнах и на мобайле
  scrollPosition.value = document.documentElement.scrollTop
  isShow.value = true
  setIsOverlayShow(true)
  emit('show', dialogEmmitData?.preset)
  nextTick( async () => {
      emit('showed', dialogEmmitData?.preset)
  })
}

const hide = (): void => {
  if (!isShow.value) {
    return
  }
  document.body.classList.remove('noscroll')
  if (scrollPosition.value !== document.documentElement.scrollTop) {
    window.scrollTo({ top: scrollPosition.value, behavior: 'instant' })
  }
  isShow.value = false
  setIsOverlayShow(false)
  emit('hide')
}

const close = async (): Promise<void> => {
    if (props.closable) {
        if ( dialogEmmitData?.callbacks?.hide ) {
            await dialogEmmitData.callbacks.hide()
        }
        hide()
        emit('close')
    }
}

const onESCKeyDown = async (event: KeyboardEvent): Promise<void> => {
    if (isShow.value && event.code === 'Escape') {
        if ( dialogEmmitData?.callbacks?.hide ) {
            await dialogEmmitData.callbacks.hide()
        }
        hide()
        emit('close')
    }
}

const onScroll = (): void => {
  // При скроле сайдбара вызываем событие scroll на window, чтобы отработала директива v-scroll у дочерних элементов
  window.dispatchEvent(new CustomEvent('scroll'))
}

defineExpose({ show, hide })
</script>
