<template>
  <client-only>
    <vz-icon
      :name="iconName"
      class="vz-color-mode vz-cursor-pointer"
      size="20"
      :class="$attrs.class"
      @click="changeColorMode"
    />
  </client-only>
</template>

<script setup lang="ts">
import { useColorMode } from '@vueuse/core'
import VzIcon from '~/components/ui-kit/vz-icon.vue';

// <script> предотвращает мелькание светлой темы при первой загрузке / перезагрузке страницы
useHead({
  script: [
    {
      children: "if (localStorage.theme === 'dark') { document.documentElement.setAttribute('class', 'dark') }"
    }
  ]
})

const colorMode = useColorMode({ storageKey: 'theme', modes: { light:'', dark: 'dark' } })

const isLightMode = computed(() => {
  return colorMode.value === 'light'
})

const iconName = computed(() => {
  return isLightMode.value ? 'white-balance-sunny' : 'weather-night'
})

function changeColorMode() {
  colorMode.value = isLightMode.value ? 'dark': 'light'
}
</script>
