<template>
  <div class="page-header">
    <div class="public-container public-container-mobile">
      <slot>
        <h1 v-html="pageTitle" />
      </slot>
      <ul class="page-header-breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
        <li v-for="(link, index) of parentLinks" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="color-primary">
          <span v-if="index > 0">  / </span>
          <nuxt-link :to="link.to" itemprop="item">
            <span itemprop="name">{{ link.title }}</span>
          </nuxt-link>
          <meta itemprop="position" :content="index + 1" />
        </li>
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a class="text-decoration-none color-low" itemprop="item">
            <span>  / </span>
            <span itemprop="name">{{ pageTitleInBreadcrumbs }}</span>
          </a>
          <meta itemprop="position" :content="parentLinks.length + 1" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useSeoStore} from '~/stores/seo';
import {useRoute} from "vue-router";

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  titleInBreadcrumbs: {
    type: String,
    default: ''
  }
})

const seoStore = useSeoStore()

const seoCargoLink = {
  title: 'Грузоперевозки',
  to: '/cargo/'
}

const seoTerminalsLink = {
  title: 'Россия',
  to: '/terminals/'
}

// такая странная вложенность сделана для seo
const parentParams = [
  { name: 'news', links: [{ title: 'Новости', to: '/news/' }]},
  { name: 'actions', links: [{ title: 'Акции', to: '/actions/' }]},
  { name: 'address', links: [{ title: 'Адреса филиалов', to: '/address/' }]},
  { name: 'wrapping', links: [{ title: 'Упаковка', to: '/wrapping/' }]},
  { name: 'order/create', links: [seoCargoLink, seoTerminalsLink, { title: 'Направления', to: '/directions/' }] },
  { name: 'tariffs', links: [seoCargoLink], exactMatch: true },
  { name: 'terminals', links: [seoCargoLink], exactMatch: true },
  { name: 'order-create', links: [seoCargoLink], exactMatch: true },
  { name: 'networkshops', links: [seoCargoLink], exactMatch: true },
  { name: 'marketplaces', links: [seoCargoLink], exactMatch: true },
  { name: 'online-store', links: [seoCargoLink], exactMatch: true },
  { name: 'delivery-russia', links: [seoCargoLink], exactMatch: true },
  { name: 'address-delivery', links: [seoCargoLink], exactMatch: true },
  { name: 'directions', links: [seoCargoLink, seoTerminalsLink], exactMatch: true },
  { name: 'shippingtypes', links: [seoCargoLink, seoTerminalsLink], exactMatch: true },
  { name: 'shippingtypes', links: [seoCargoLink, seoTerminalsLink, { title: 'Типы перевозок', to: '/shippingtypes/' }]},
]

const route = useRoute();
const parentLinks = computed(() => {
  let result: { title: String, to: String }[] = [
    { title: 'Транспортная компания', to: '/' }
  ];
  parentParams.forEach((param) => {
    const regExp = new RegExp(`${param.name}/.`)
    if ((regExp.exec(route.path) && !param?.exactMatch) || (param?.exactMatch && param.name === route.name)) {
      result = result.concat(param.links)
    }
  })
  return result;
})

const pageTitle = computed((): string => {
  return props.title || seoStore.seo?.seo?.h1 || ''
})

const pageTitleInBreadcrumbs = computed((): string => {
  return props.titleInBreadcrumbs || pageTitle.value
})
</script>
