import {CargoType, DirectionName, OrderCargoModV3, OrderForm, OrderWrappingItem} from "~/types/types";
import {WrappingCode} from "~/stores/wrapping";
import {Purpose} from "~/types/order/order";
import {priceDataToOrderFormV3} from "~/common/objectsV3";
import {deepCopyObject} from "~/common/functions";

// applyTemplate stores/order
export function orderFormV2ToFormV3(order: OrderForm) {
  const orderV2 = deepCopyObject(order)
  let newOrderV3 = priceDataToOrderFormV3()

  if (orderV2.meta) {
    newOrderV3.meta = {
      data: {
        customId: orderV2.meta.customId
      },
      networkMode: orderV2.isNetwork || orderV2?.meta?.isNetwork || !!orderV2?.meta?.networkMode
    }
  }


  newOrderV3.directions = {
    dispatch: {
      type: orderV2?.directions?.dispatch?.direction,
      locationGuid: orderV2?.directions?.dispatch?.locationGuid,
      terminalGuid: orderV2?.directions?.dispatch?.terminalGuid,
      address: orderV2?.directions?.dispatch?.address,
      shippingTerm: {
        ...(orderV2?.directions?.dispatch?.calendar),
        fixTime: orderV2?.directions?.dispatch?.calendar?.fixedTime,
      },
      country: orderV2?.directions?.dispatch?.country,
      waitingTime: orderV2?.directions?.dispatch?.waitingTime,
      region: orderV2.directions?.dispatch?.region
      // dispatchTaken?: string
    },
    destination: {
      type: orderV2?.directions?.destination?.direction,
      locationGuid: orderV2?.directions?.destination?.locationGuid,
      terminalGuid: orderV2?.directions?.destination?.terminalGuid,
      address: orderV2?.directions?.destination?.address,
      shippingTerm: {
        ...(orderV2?.directions?.destination?.calendar),
        fixTime: orderV2?.directions?.destination?.calendar?.fixedTime,
      },
      isCustom: orderV2?.directions?.destination?.isCustom ?? (orderV2?.meta?.networkMode
        ? !!orderV2?.directions?.destination?.networkGuid &&
        !orderV2?.directions?.destination?.networkAddressGuid &&
        !!orderV2?.directions?.destination?.address
        : orderV2.directions?.destination?.isCustom),
      country: orderV2.directions?.destination?.country,
      guid: orderV2?.directions?.destination?.networkAddressGuid,
      networkGuid: orderV2?.directions?.destination?.networkGuid,
      waitingTime: orderV2?.directions?.destination?.waitingTime,
      // region: orderV2.directions.destination.region
      // destinationDateSelected?: string
      // destinationPeriodSelectedEnd?: string
      // destinationPeriodSelectedStart?: string
      // destinationTerminalArrivalDateTime?: string
      // destinationTerminalArrivalGuid?: string
    }
  }

// Груз
  newOrderV3.cargo.type = orderV2?.cargo?.type
  newOrderV3.cargo.category = orderV2?.cargo?.category as string
  if (orderV2?.cargo?.mode) {
    const modeV2ToV3 = {
      dimension: 'dimension',
      places: 'wizard',
      parcels: 'parcel',
      pallets: 'pallet'
    }
    newOrderV3.cargo.mode = modeV2ToV3[orderV2.cargo.mode || 'dimension'] as OrderCargoModV3

    // dimension
    if (orderV2.cargo.mode === 'dimension') {
      newOrderV3.cargo.dimension = {
        general: {
          volume: orderV2.cargo.dimensions?.general?.volume || newOrderV3.cargo.dimension.general.volume,
          weight: orderV2.cargo.dimensions?.general?.weight || newOrderV3.cargo.dimension.general.weight,
          quantity: orderV2.cargo.dimensions?.general?.place || newOrderV3.cargo.dimension.general.quantity,
        },
        detail: {
          height: orderV2.cargo.dimensions?.detail?.height || newOrderV3.cargo.dimension.detail.height,
          weight: orderV2.cargo.dimensions?.detail?.weight || newOrderV3.cargo.dimension.detail.weight,
          length: orderV2.cargo.dimensions?.detail?.length || newOrderV3.cargo.dimension.detail.length,
          width: orderV2.cargo.dimensions?.detail?.width || newOrderV3.cargo.dimension.detail.width,
        },
        wrapping: (orderV2.cargo.dimensions?.wrapping || []) as Array<WrappingCode & OrderWrappingItem>,
      }
      // parcel
    } else if (orderV2.cargo.mode === 'parcels') {
      if (orderV2.cargo.parcels?.items?.length) {
        newOrderV3.cargo.parcel = {
          general: {
            volume: orderV2.cargo.dimensions?.general?.volume || newOrderV3.cargo.dimension.general.volume,
            weight: orderV2.cargo.dimensions?.general?.weight || newOrderV3.cargo.dimension.general.weight,
            quantity: orderV2.cargo.dimensions?.general?.place || newOrderV3.cargo.dimension.general.quantity,
          },
          items: orderV2.cargo.parcels.items.map((item, index) => ({
            type: item.type,
            weight: item.weight,
            key: index,
            quantity: item.count
          }))
        }
      }
      // wizard
    } else if (orderV2.cargo.mode === 'places') {
      if (orderV2.cargo.places?.items?.length) {
        const wrappingAll: OrderWrappingItem[] = []
        newOrderV3.cargo.wizard = {
          general: {
            volume: orderV2.cargo.dimensions?.general?.volume || newOrderV3.cargo.dimension.general.volume,
            weight: orderV2.cargo.dimensions?.general?.weight || newOrderV3.cargo.dimension.general.weight,
            quantity: orderV2.cargo.dimensions?.general?.place || newOrderV3.cargo.dimension.general.quantity,
          },
          items: orderV2.cargo.places.items.map((item, index: number) => ({
            weight: item.weight,
            width: item.width,
            length: item.length,
            height: item.height,
            key: item.key,
            quantity: item.count,
            wrapping: [
              ...item.wrapping.map((wrap: any) => {
                if (['safePackage'].includes(wrap.code)) {
                  const findWrap = wrappingAll.find((wrap) => wrap.code === 'safePackage')
                  if (!findWrap) {
                    wrappingAll.push(wrap);
                  }
                } else {
                  return wrap.code
                }
              })
            ].filter(wrap => !!wrap),

            label: item.label || `Груз ${index + 1}`
          })),
          wrapping: ([...wrappingAll, ...orderV2.cargo.dimensions?.wrapping] || [])
        }
      }
      // pallet
    } else if (orderV2.cargo.mode === 'pallets') {
      if (orderV2.cargo.pallets?.items?.length) {
        newOrderV3.cargo.pallet = {
          general: {
            volume: orderV2.cargo.dimensions?.general?.volume || newOrderV3.cargo.dimension.general.volume,
            weight: orderV2.cargo.dimensions?.general?.weight || newOrderV3.cargo.dimension.general.weight,
            quantity: orderV2.cargo.dimensions?.general?.place || newOrderV3.cargo.dimension.general.quantity,
          },
          items: orderV2.cargo.pallets.items.map((item, index) => ({
            type: item.type as CargoType,
            weight: item.weight,
            width: item.width,
            length: item.length,
            height: item.height,
            key: index,
            quantity: item.count,
            wrapping: [
              ...(item.wrapping.map((code: any) => code))
            ]
          })),
          wrapping: (orderV2.cargo.dimensions?.wrapping || [])
        }
      }
    }
  }

  newOrderV3.customer.payer = orderV2.customer?.payer;
// Участники
  (['dispatch', 'destination', 'third'] as DirectionName[]).forEach((key: 'dispatch' | 'destination' | 'third') => {
    if (orderV2?.customer?.[key]) {
      const formCustomer = orderV2.customer[key]

      newOrderV3.customer[key] = {
        entityType: formCustomer.type,

        corporation: {
          networking: formCustomer.networking,
          countryCode: formCustomer.country,
          country: formCustomer.country,
          guid: formCustomer.corporation.companyName.id,
          IEC: formCustomer.corporation.IEC,
          taxId: formCustomer.corporation.taxId,
          companyName: formCustomer.corporation.companyName.value,
          email: formCustomer.corporation.email,
          contact: {
            name: formCustomer.corporation.name,
            email: formCustomer.corporation.email,
            phone: formCustomer.corporation.phone
          },
          sendCode: formCustomer.sendCode || false
        },

        individual: {
          guid: formCustomer.individual.name.id,
          countryCode: formCustomer.country,
          country: formCustomer.country,
          contact: {
            id: formCustomer.individual.name.id,
            name: formCustomer.individual.name.value,
            email: formCustomer.individual.email,
            phone: formCustomer.individual.phone
          },
          sendCode: formCustomer.sendCode || false
        },
      }
    }
  })

// Сервисы
  const fieldToSpecialRequirements = [
    'bodyHeight_2',
    'bodyHeight_2_1',
    'bodyHeight_2_2',
    'bodyHeight_2_3',
    'bodyHeight_2_4',
    'bodyHeight_2_5',
    'width210',
    'width220',
    'type',
    'ramp110',
    'ramp120',
    'ramp130',
    'fixingStraps',
    'passportRF',
    'reflectiveVest',
    'closedClothes',
    'medicalCard',
    'emptyMachine',
    'pallets',
    'rearLoading',
    'sanitaryPassport'
  ]

//   insurance: {
//     type: template.content?.services?.insurance?.active || template.content?.services?.insuranceNoDeclaredValue?.active
//       ? template.content?.services?.insuranceNoDeclaredValue ? 'noDeclaredValue' : 'cargoInsurance'
//       : template.content?.services?.insurance ? 'without' : orderForm.value.cargo.insurance.type,
//     value: +(template.content?.services?.insurance?.value ?? orderForm.value.cargo.insurance.value),
//   },
  switch (orderV2.cargo?.insurance?.type) {
    case 'without':
      newOrderV3.services.insurance.active = false
      newOrderV3.services.insuranceNoDeclaredValue.active = false
      break
    case 'cargoInsurance':
      newOrderV3.services.insurance.active = true
      newOrderV3.services.insurance.value = orderV2.cargo.insurance.value.toString()
      newOrderV3.services.insuranceNoDeclaredValue.active = false
      break
    case 'noDeclaredValue':
      newOrderV3.services.insurance.active = false
      newOrderV3.services.insuranceNoDeclaredValue.active = true
      break
    default:
      newOrderV3.services.insurance.active = null
      newOrderV3.services.insurance.value = null
      newOrderV3.services.insuranceNoDeclaredValue.active = null
      break
  }

  newOrderV3.services.retrieveAD = {
    scan: orderV2.additionalServices?.scanDocuments,
    active: orderV2.additionalServices?.returning.active,
    locationGuid: orderV2.additionalServices?.returning.locationGuid,
    terminalGuid: orderV2.additionalServices?.returning.terminalGuid,
    address: orderV2.additionalServices?.returning.address,
    type: orderV2.additionalServices?.returning.direction
  }
  newOrderV3.services.scanConsignationNote = orderV2.additionalServices?.scan
  newOrderV3.services.unboxingOnDelivery = {
    active: orderV2.additionalServices?.disassemble.active,
    value: orderV2.additionalServices?.disassemble.weight.toString()
  }

  const directions = ['dispatch', 'destination'] as Purpose[];
  directions.forEach(direction => {
    Object.entries(orderV2?.specialRequirements?.[direction] || {}).forEach(([key, value]: [key: string, value: any]) => {
      if (key === 'unloadingOperations') {
        // Другое название в V3
        key = 'needLoading'
      }
      if (key === 'type') {
        newOrderV3.services.specificLoading[direction].transportType = value
        return
      }
      if (newOrderV3.services.hasOwnProperty(key)) {
        newOrderV3.services[key][direction] = value;
      } else if (fieldToSpecialRequirements.includes(key)) {
        if (!newOrderV3.services.specificLoading?.[direction]?.list) {
          if (!newOrderV3.services.specificLoading?.[direction]) {
            newOrderV3.services.specificLoading[direction] = {}
          }
          newOrderV3.services.specificLoading[direction].list = {}
        }
        newOrderV3.services.specificLoading[direction].list[key] = value;
      }
    })
  })

  return newOrderV3;
}
