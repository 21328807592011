const listeners: Map<HTMLElement, Map<() => void, []>> = new Map()

export default {
    mounted(el, binding) {
        const handler = binding.value
        const event = (evt: Event): void => handler(evt, el)

        if (!listeners.has(el)) {
            listeners.set(el, new Map())
        }

        const functions = listeners.get(el) || new Map()

        if (!functions.has(event)) {
            functions.set(event, [])
            window.addEventListener('resize', event)
        }
    },
    beforeUnmount(el) {
        const functions = listeners.get(el) || new Map()

        for (const event of functions.keys()) {
            window.removeEventListener('resize', event)
        }
    },
    getSSRProps (binding, vnode) {
        return {}
    }
}
