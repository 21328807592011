import {useCityStore} from '~/stores/city';
import {storeToRefs} from 'pinia';
import {getSeo} from '~/api/seo';

export const useSeoStore = defineStore('seo', () => {
  const { currentCity, currentPhones, currentCityAbbr } = storeToRefs(useCityStore())
  const seo = ref<any>({})

  watch(currentCity, async () => {
    if (process.server) {
      return
    }
    const data = await getSeo({ domain: currentCityAbbr.value || 'msk', path: '/' })

    if (data) {
      seo.value = data
      currentPhones.value = Object.keys(data.phones)
    }
  })

  return { seo }
})