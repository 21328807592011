import {defineNuxtPlugin} from '#app'
import {isDevServer, isTestServer} from '~/composables/useVzFetch';
import {botDetect} from "~/api/detect";

export default defineNuxtPlugin({
  async setup(nuxtApp) {
    if (isDevServer || isTestServer) {
      return {}
    }

    const bot = await botDetect()
    if (bot) {
      return {}
    }

    function loadMetrika(m?: any, e?: any, t?: any, r?: any, i?: any, k?: any, a?: any) {
      m[i] = m[i] || function () {
        (m[i].a = m[i].a || []).push(arguments)
      };
      m[i].l = 1 * new Date();
      k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
    }

    if (window.location.hostname.includes('vozovoz.ru')) {
    loadMetrika(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')
      ym(38058485, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        ecommerce: 'dataLayer'
      });
      return {}
    }

    if (window.location.hostname.includes('vozovoz.by')) {
      loadMetrika(window, document, 'script', 'https://gtm.vozovoz.ru/ya', 'ym');
      ym(86248705, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      });

      return {}
    }

    if (window.location.hostname.includes('vozovoz.kz')) {
      loadMetrika(window, document, 'script', 'https://gtm.vozovoz.ru/ya', 'ym');
      ym(89114974, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      });
    }

    return {}
  },
})