<template>
  <div class="public-footer">
    <div class="public-container public-container-mobile">
      <div class="public-footer-top flex flex-space-between flex-align-items-center">
        <public-header-region />
        <div class="public-footer-top-description">
          Автомобильные грузоперевозки с компанией Возовоз - одной из ведущих транспортных компаний в России
        </div>
      </div>
      <div class="public-footer-main flex flex-space-between">
        <div class="public-footer-info">
          <vz-logo variant="white"/>
          <public-footer-apps/>
        </div>
        <public-footer-menu/>
        <public-footer-contacts/>
      </div>

      <public-footer-apps class="public-footer-apps-mobile"/>

      <div class="public-footer-additional flex">
        <div>
          <span itemprop="name">OOO "Возовоз"</span> © 2014 - {{ year }}
          <span class="display-none" itemprop="address">
            {{ address }}
          </span>
        </div>
        <a :href="personalDataProcessing" target="_blank" class="public-footer-additional-link personal-data-link">
          Обработка персональных данных
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VzLogo from '~/components/ui-kit/vz-logo.vue'
import PublicFooterApps from '~/components/public/footer/public-footer-apps.vue'
import PublicFooterMenu from '~/components/public/footer/public-footer-menu.vue'
import PublicHeaderRegion from '~/components/public/header/public-header-region.vue'
import PublicFooterContacts from '~/components/public/footer/public-footer-contacts.vue'
import {personalDataProcessing} from '@/api/constant-urls'
import {until} from '@vueuse/core';

const { terminalList } = storeToRefs(useTerminalStore())
const { terminalSelectByLocation } = useTerminalStore()
const { from, to, rclCityList } = storeToRefs(useCityStore())

const route = useRoute()

const address = terminalSelectByLocation(from.value.rcl_guid || from.value?.guid)?.[0]?.address || 'Санкт-Петербург, 6-й Верхний переулок дом 12 литер А, кабинет №210'

const year = computed(() => {
  return new Date().getFullYear()
})
</script>
