<template>
  <div
    class="vz-collapse-info"
    :class="collapseClass"
  >
    <div
      class="vz-collapse-info-header"
      @click="onToggleCollapse"
    >
      <slot name="header"/>
      <client-only>
        <vz-icon
          v-if="collapsible"
          class="vz-collapse-info-toggle"
          :name="chevronDirection"
          :size="18"
          :disabled="disabled"
        />
      </client-only>
    </div>
    <transition name="fade">
      <div
        v-show="show"
        class="vz-collapse-info-content">
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { VzClass } from '@/types/types'
import VzIcon from '~/components/ui-kit/vz-icon.vue';

const props = defineProps({
  title: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  noBorder: { type: Boolean, default: false },
  collapsible: { type: Boolean, default: true },
  publicStyle: { type: Boolean, default: false },
  initialShow: { type: Boolean, default: false }
})
const emit = defineEmits(['header-click'])

let show = ref(props.initialShow)

const collapseClass = computed((): VzClass => {
  return {
    disabled: props.disabled,
    'vz-collapse-info-open': show.value,
    'vz-collapse-info-public': props.publicStyle
  }
})

const chevronDirection = computed((): string => {
  return show.value ? 'chevron-up' : 'chevron-down'
})

const onToggleCollapse = (): void => {
  if (!props.disabled && props.collapsible) {
    show.value = !show.value
  }
  emit('header-click')
}

watch(() => props.collapsible, () => {
  if (!props.collapsible) {
    show.value = true
  }
}, { immediate: true })
</script>