import {emitter} from "~/common/event-bus"
import {VzDialogConfig, vzDialogEmitData} from '~/types/types'
import VzConfirmDialog from '~/components/ui-kit/vz-confirm-dialog.vue';
import {h, render} from '@vue/runtime-dom';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      showDialog: (dialogData: vzDialogEmitData) => {
        emitter.emit('show-dialog', {
          name: dialogData.name,
          callbacks: dialogData.callbacks,
          preset: dialogData.preset
        })
      },
      hideDialog: (dialogName: vzDialogEmitData) => {
        emitter.emit('hide-dialog', dialogName)
      },
      confirm: (options: VzDialogConfig) => {
        let ok
        let fail
        const popupPromise: Promise<boolean | null> = new Promise((resolve, reject) => {
          ok = resolve
          fail = reject
        })
        const popupController: any = {resolve: ok, reject: fail}
        const div = document.createElement('div')
        document.body.appendChild(div)
        const dialog = h(
          VzConfirmDialog,
          {
            ...options,
            onHide: (value: boolean | null) => {
              popupController.resolve(value)
              div.remove()
            }
          }
        )
        render(dialog, div)

        return popupPromise
      }
    }
  }
})
