import {http} from '~/composables/useVzFetch';
import {ISeo, SeoRequestParams} from '~/types/api';

export const getSeo = async (body: SeoRequestParams) => {
  const result = await $fetch(
    `${http}/seo/get`,
    {method: 'post', body}
  ) as ISeo
  if (result?.location?.coordinates && typeof result.location.coordinates === 'string') {
    result.location.coordinates = result.location.coordinates.split(',')
  }
  return result
}
