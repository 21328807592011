import { useWindowSize } from '@vueuse/core'

let keyboardVisibility = false

const b = function () {
  // if (process.client) {

    const xs400 = 450
    const xs = 640
    const sm = 1024
    const md = 1366
    const lg = 1920
    const publicxs = 760

    const breakPoints = reactive({
      isXS400: false,
      isXS: false,
      isSM: false,
      isMD: false,
      isLG: false,
      isXL: false,
      isPublicXS: false,
      width: 0,
      keyboardVisibility: false
    })

    // if (process.client) {
      const w = useWindowSize({ initialWidth: 1600 }).width
      const isBetweenPoints = (pointA: number, pointB: number): boolean => w.value > pointA && w.value <= pointB
      const XS = (): boolean => (isBetweenPoints(0, xs))
      const XS400 = (): boolean => (isBetweenPoints(0, xs400))
      const SM = (): boolean => isBetweenPoints(xs, sm)
      const MD = (): boolean => isBetweenPoints(sm, md)
      const LG = (): boolean => isBetweenPoints(md, lg)
      const XL = (): boolean => isBetweenPoints(lg, w.value)
      const publicXS = (): boolean => (isBetweenPoints(0, publicxs))

      const onResize = (): void => {
        breakPoints.isXS400 = XS400()
        breakPoints.isXS = XS()
        breakPoints.isSM = SM()
        breakPoints.isMD = MD()
        breakPoints.isLG = LG()
        breakPoints.isXL = XL()
        breakPoints.isPublicXS = publicXS()
        breakPoints.keyboardVisibility = keyboardVisibility
        breakPoints.width = useWindowSize({ initialWidth: 1600 }).width.value
      }

      onResize()
      if (process.client && window) {
        window?.addEventListener('resize', onResize)
        window?.addEventListener('load', onResize)
      }
      return breakPoints
    // }
  // }
}

const $breakpoints = b()

export default $breakpoints
