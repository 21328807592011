<template>
  <div class="public">
    <public-header />
    <div class="public-main">
      <page-header :title="`${error.statusCode}`" />
      <div class="public-container public-container-mobile error-page">
        <template v-if="error.statusCode === 404">
          <img
            src="/images/404-xs.png"
            alt="404"
            style="width: 300px;"
            class="flex mb-20"
          >
          <h2 class="error-page-title">
            У-упс, похоже, Вы заблудились.
          </h2>
        </template>
        <template v-else>
          <h2>Что-то пошло не так</h2>

          <p class="mb-30">{{ error.message }}</p>
        </template>

        <div>
          <span>Вы можете перейти на </span>
          <nuxt-link :to="{ name: 'index' }" class="white-space-nowrap">
            <span>главную страницу</span>
          </nuxt-link>
          <span class="white-space-nowrap">
                или в
                <nuxt-link :to="{ name: 'personal' }">личный кабинет</nuxt-link>
              </span>
        </div>
      </div>
    </div>
    <public-footer />
  </div>
</template>

<script setup lang="ts">
import PageHeader from '~/components/public/page-header.vue';
import PublicHeader from '~/components/public/header/public-header.vue';
import PublicFooter from '~/components/public/footer/public-footer.vue';
import { setYM } from '~/common/functions';

defineProps({
  error: Object
})

const handleError = () => clearError({ redirect: '/' })

onMounted(() => {
  setYM(38058485, '404')
})
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
}
</style>