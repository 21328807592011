<template>
  <div class="vz-auth-form-container">
    <vz-auth-login-form
      v-if="isLoginForm"
      :title="title"
      :reg-only="regOnly"
      @submit="setAuthInfo"
    />
    <vz-auth-confirm-form
      v-else
      :reg-only="regOnly"
      @back="clearAuthInfo"
    />
  </div>
</template>

<script setup lang="ts">
import VzAuthLoginForm from '~/components/forms/auth/vz-auth-login-form.vue';
import VzAuthConfirmForm from '~/components/forms/auth/vz-auth-confirm-form.vue';
import { useUserStore } from '~/stores/personal/user';
import { storeToRefs } from 'pinia';
import {AuthInfo} from "~/types/api";

const props = defineProps({
  regOnly: {
    type: Boolean,
    default: false
  }
})

const {$showToast} = useNuxtApp()
const { token, username, authInfo } = storeToRefs(useUserStore())
const { clearAuthInfo } = useUserStore()
const router = useRouter()

const isLoginForm = computed(() => {
  return !authInfo.value?.login
})

const title = computed(() => {
  return props.regOnly ? 'Регистрация в личном кабинете' : undefined
})

function setAuthInfo(data: AuthInfo) {
  if (props.regOnly && data.context !== 'userReg') {
    $showToast({ variant: 'error', title: 'Ошибка! Пользователь уже зарегистрирован', keepAlive: true })
    router.push({ name: 'personal-auth', query: { login: data.login } })
    return
  }
  authInfo.value = data
}
</script>

<style lang="scss" scoped>
</style>
