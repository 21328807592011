<template>
  <div class="public-footer-menu flex flex-space-around">
    <div v-for="el in list" class="public-footer-menu-data">
      <nuxt-link
        v-for="(item, index) in el"
        :key="index"
        :to="item.link"
        class="public-footer-menu-link"
      >
        {{ item.title }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import useMenuItems from "~/composables/useMenuItems";

const {popularItems, maintenanceItems, footerItems} = useMenuItems()

const list = [popularItems, maintenanceItems, footerItems]
</script>
