<template>
  <div v-show="statusLoader" class="vz-loader" :class="{lessTransparent, smallSize, viewOnly}">
    <div v-if="!withoutImg" class="vz-loader-wrapper" :style="`top: ${iconOffset}`">
      <div class="loader-cargo">
        <img src="/images/loader.png">
<!--        <NuxtImg src="/images/loader.png" alt="" format="webp" loading="lazy" />-->
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>
import {Timeout} from "~/types/types"

const props = defineProps({
  statusLoader: {
    type: Boolean,
    default: false,
  },
  lessTransparent: {
    default: false,
    type: Boolean,
  },
  withoutImg: {
    type: Boolean,
    default: false
  },
  smallSize: {
    type: Boolean,
    default: false
  },
  viewOnly: {
    type: Boolean,
    default: false
  },
  delay: {
    type: Number,
    default: 0
  },
  offset: {
    type: Number,
    default: 0
  },
})

let isShow = false
let debounce: Timeout = null

watch(() => props.statusLoader, () => {
    if ( debounce ) {
        clearTimeout(debounce)
    }

    setTimeout(() => {
        isShow = props.statusLoader
    }, props.delay)
})

const iconOffset = computed((): string => {
    return (+props.offset) + 'px'
})
</script>
