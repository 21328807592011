
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93uonyE0JNd6Meta } from "/var/www/2025-03-12_16_14/pages/[slug].vue?macro=true";
import { default as address_45delivery26QwjBgAdUMeta } from "/var/www/2025-03-12_16_14/pages/address-delivery.vue?macro=true";
import { default as already_45answerZ85AWoEbzEMeta } from "/var/www/2025-03-12_16_14/pages/already-answer.vue?macro=true";
import { default as belarusqAwoJyxhpXMeta } from "/var/www/2025-03-12_16_14/pages/belarus.vue?macro=true";
import { default as bonuses3ohRYsZ3VFMeta } from "/var/www/2025-03-12_16_14/pages/bonuses.vue?macro=true";
import { default as cargo_45loadingRfVFNugQXiMeta } from "/var/www/2025-03-12_16_14/pages/cargo-loading.vue?macro=true";
import { default as cargoV6zayAWRZKMeta } from "/var/www/2025-03-12_16_14/pages/cargo.vue?macro=true";
import { default as cash_45on_45deliverymLgdzJvKSSMeta } from "/var/www/2025-03-12_16_14/pages/cash-on-delivery.vue?macro=true";
import { default as chinaReAvIm5XH9Meta } from "/var/www/2025-03-12_16_14/pages/china.vue?macro=true";
import { default as claim_45filingmEKyDgKQrbMeta } from "/var/www/2025-03-12_16_14/pages/claim-filing.vue?macro=true";
import { default as constructor3xN83kfpzhMeta } from "/var/www/2025-03-12_16_14/pages/constructor.vue?macro=true";
import { default as contactsrPLRjse6wXMeta } from "/var/www/2025-03-12_16_14/pages/contacts.vue?macro=true";
import { default as delivery_45russiagGsi55HuHFMeta } from "/var/www/2025-03-12_16_14/pages/delivery-russia.vue?macro=true";
import { default as apiAhb8jkDuF7Meta } from "/var/www/2025-03-12_16_14/pages/dev/api.vue?macro=true";
import { default as cmsQgTxt88pNgMeta } from "/var/www/2025-03-12_16_14/pages/dev/cms.vue?macro=true";
import { default as iframeDhyMe9k4rYMeta } from "/var/www/2025-03-12_16_14/pages/dev/iframe.vue?macro=true";
import { default as directions12XkoNTKAeMeta } from "/var/www/2025-03-12_16_14/pages/directions.vue?macro=true";
import { default as documentsgcGunxQd9mMeta } from "/var/www/2025-03-12_16_14/pages/documents.vue?macro=true";
import { default as edoxoIQxvJNvdMeta } from "/var/www/2025-03-12_16_14/pages/edo.vue?macro=true";
import { default as fast_45delivery_45capitalZ7txsJ6SOUMeta } from "/var/www/2025-03-12_16_14/pages/fast-delivery-capital.vue?macro=true";
import { default as forwarding_45receipt63VvkzQx6NMeta } from "/var/www/2025-03-12_16_14/pages/forwarding-receipt.vue?macro=true";
import { default as franchiseWS1XdC2gWaMeta } from "/var/www/2025-03-12_16_14/pages/franchise.vue?macro=true";
import { default as free_45group_45pickupcAce6GqWNrMeta } from "/var/www/2025-03-12_16_14/pages/free-group-pickup.vue?macro=true";
import { default as free_45pickup_45cargoYEzJWZgK5TMeta } from "/var/www/2025-03-12_16_14/pages/free-pickup-cargo.vue?macro=true";
import { default as _91type_93QY2h7klXwyMeta } from "/var/www/2025-03-12_16_14/pages/gifts/[type].vue?macro=true";
import { default as indexKBu4NbVuXdMeta } from "/var/www/2025-03-12_16_14/pages/gifts/index.vue?macro=true";
import { default as giveawayqc522yhgeRMeta } from "/var/www/2025-03-12_16_14/pages/giveaway.vue?macro=true";
import { default as guide_45lkEiPZrUS0TeMeta } from "/var/www/2025-03-12_16_14/pages/guide-lk.vue?macro=true";
import { default as happybirthdayBPjNA8538PMeta } from "/var/www/2025-03-12_16_14/pages/happybirthday.vue?macro=true";
import { default as happynewyear2024L1iCMc8pXPMeta } from "/var/www/2025-03-12_16_14/pages/happynewyear2024.vue?macro=true";
import { default as helpAOy0Myoxg7Meta } from "/var/www/2025-03-12_16_14/pages/help.vue?macro=true";
import { default as calculatoratLJBJ6US1Meta } from "/var/www/2025-03-12_16_14/pages/iframe/calculator.vue?macro=true";
import { default as manageaR83QF2aP7Meta } from "/var/www/2025-03-12_16_14/pages/iframe/manage.vue?macro=true";
import { default as _91guid_93OwqSu0vz2cMeta } from "/var/www/2025-03-12_16_14/pages/iframe/order/administer/[guid].vue?macro=true";
import { default as indexKW4CMWfJc2Meta } from "/var/www/2025-03-12_16_14/pages/iframe/order/create/index.vue?macro=true";
import { default as tariffsYAUhxNcHJIMeta } from "/var/www/2025-03-12_16_14/pages/iframe/tariffs.vue?macro=true";
import { default as indexd1Ss6JdX6qMeta } from "/var/www/2025-03-12_16_14/pages/index.vue?macro=true";
import { default as informationhRK5n55abhMeta } from "/var/www/2025-03-12_16_14/pages/information.vue?macro=true";
import { default as insurance7YvgjARSznMeta } from "/var/www/2025-03-12_16_14/pages/insurance.vue?macro=true";
import { default as kazakhstanDA2eBHyem7Meta } from "/var/www/2025-03-12_16_14/pages/kazakhstan.vue?macro=true";
import { default as kirghiziaNxuIJdbscnMeta } from "/var/www/2025-03-12_16_14/pages/kirghizia.vue?macro=true";
import { default as konkursWajjTHGKzqMeta } from "/var/www/2025-03-12_16_14/pages/konkurs.vue?macro=true";
import { default as mailClient102019175GBcrjFcZYMeta } from "/var/www/2025-03-12_16_14/pages/mailClient10201917.vue?macro=true";
import { default as mapSchemeIAytqNGEccMeta } from "/var/www/2025-03-12_16_14/pages/mapScheme.vue?macro=true";
import { default as ozonEZPWCehhxnMeta } from "/var/www/2025-03-12_16_14/pages/marketplace/ozon.vue?macro=true";
import { default as wildberriesJ4JymmQLfpMeta } from "/var/www/2025-03-12_16_14/pages/marketplace/wildberries.vue?macro=true";
import { default as yandex_45marketIj6yuhOTAsMeta } from "/var/www/2025-03-12_16_14/pages/marketplace/yandex-market.vue?macro=true";
import { default as marketplacesGoyu2y4bTsMeta } from "/var/www/2025-03-12_16_14/pages/marketplaces.vue?macro=true";
import { default as merch_45for_45bonuses3eiryoxM6gMeta } from "/var/www/2025-03-12_16_14/pages/merch-for-bonuses.vue?macro=true";
import { default as mobile_45applicationN8gRFRHgzYMeta } from "/var/www/2025-03-12_16_14/pages/mobile-application.vue?macro=true";
import { default as networkshops3KttQnH3eWMeta } from "/var/www/2025-03-12_16_14/pages/networkshops.vue?macro=true";
import { default as _91id_93HaO38hz4NTMeta } from "/var/www/2025-03-12_16_14/pages/news/[id].vue?macro=true";
import { default as indexPXMf4IUmWgMeta } from "/var/www/2025-03-12_16_14/pages/news/index.vue?macro=true";
import { default as online_45storerLerJH9MA7Meta } from "/var/www/2025-03-12_16_14/pages/online-store.vue?macro=true";
import { default as _91guid_937UhmfAqk3UMeta } from "/var/www/2025-03-12_16_14/pages/order/administer/[guid].vue?macro=true";
import { default as _91locationFrom_93__91locationTo_93ETZTDAkrNHMeta } from "/var/www/2025-03-12_16_14/pages/order/create/[locationFrom]_[locationTo].vue?macro=true";
import { default as _91locations_93gD14qM8z0iMeta } from "/var/www/2025-03-12_16_14/pages/order/create/[locations].vue?macro=true";
import { default as index69SrrUqQi3Meta } from "/var/www/2025-03-12_16_14/pages/order/create/index.vue?macro=true";
import { default as lightvH9ahcqjSPMeta } from "/var/www/2025-03-12_16_14/pages/order/light.vue?macro=true";
import { default as manageIhBO7iEteMMeta } from "/var/www/2025-03-12_16_14/pages/order/manage.vue?macro=true";
import { default as _91id_93HsPnHfuBjSMeta } from "/var/www/2025-03-12_16_14/pages/order/pay/[id].vue?macro=true";
import { default as _91id_936H3K2sgO4IMeta } from "/var/www/2025-03-12_16_14/pages/order/pay/qr/[id].vue?macro=true";
import { default as payment_45methods2EGtZMOzxXMeta } from "/var/www/2025-03-12_16_14/pages/payment-methods.vue?macro=true";
import { default as bepaid_45success7b3QG75uG5Meta } from "/var/www/2025-03-12_16_14/pages/payment/bepaid-success.vue?macro=true";
import { default as card_45infos3L6DXCIT6Meta } from "/var/www/2025-03-12_16_14/pages/payment/card-info.vue?macro=true";
import { default as pay_45failLNFdxg9laeMeta } from "/var/www/2025-03-12_16_14/pages/payment/pay-fail.vue?macro=true";
import { default as qr_45infoKTdrnkBChaMeta } from "/var/www/2025-03-12_16_14/pages/payment/qr-info.vue?macro=true";
import { default as success5Q3ItRjpvyMeta } from "/var/www/2025-03-12_16_14/pages/payment/success.vue?macro=true";
import { default as accountuO1rFSEUB8Meta } from "/var/www/2025-03-12_16_14/pages/personal/account.vue?macro=true";
import { default as auth5vIONBNbs1Meta } from "/var/www/2025-03-12_16_14/pages/personal/auth.vue?macro=true";
import { default as _91tab_93qgAX4TqtS1Meta } from "/var/www/2025-03-12_16_14/pages/personal/bonus/[tab].vue?macro=true";
import { default as _91guid_93okvuSQNU6UMeta } from "/var/www/2025-03-12_16_14/pages/personal/contractors/[guid].vue?macro=true";
import { default as indexnqqRGqOdROMeta } from "/var/www/2025-03-12_16_14/pages/personal/contractors/index.vue?macro=true";
import { default as discounteDg0bhSB4DMeta } from "/var/www/2025-03-12_16_14/pages/personal/discount.vue?macro=true";
import { default as indexDTcCASIDjKMeta } from "/var/www/2025-03-12_16_14/pages/personal/group-printing/index.vue?macro=true";
import { default as importhYoOtdkiKkMeta } from "/var/www/2025-03-12_16_14/pages/personal/import.vue?macro=true";
import { default as indexZvHwKKMwmAMeta } from "/var/www/2025-03-12_16_14/pages/personal/index.vue?macro=true";
import { default as logoutQeLd11sHTRMeta } from "/var/www/2025-03-12_16_14/pages/personal/logout.vue?macro=true";
import { default as _91guid_93satu3XGw79Meta } from "/var/www/2025-03-12_16_14/pages/personal/multi-delivery/[guid].vue?macro=true";
import { default as indexT8iJSJBY0TMeta } from "/var/www/2025-03-12_16_14/pages/personal/multi-delivery/index.vue?macro=true";
import { default as _91guid_93UKlvk5YdDoMeta } from "/var/www/2025-03-12_16_14/pages/personal/multi-pickup/[guid].vue?macro=true";
import { default as indexavg0iAM58vMeta } from "/var/www/2025-03-12_16_14/pages/personal/multi-pickup/index.vue?macro=true";
import { default as _91guid_93ZdnC1zAKO1Meta } from "/var/www/2025-03-12_16_14/pages/personal/order/[guid].vue?macro=true";
import { default as createl36XhI41s2Meta } from "/var/www/2025-03-12_16_14/pages/personal/order/create.vue?macro=true";
import { default as printsOUaQ1Ukd9Meta } from "/var/www/2025-03-12_16_14/pages/personal/order/print.vue?macro=true";
import { default as _91_46_46_46id_93VD3DdQVDegMeta } from "/var/www/2025-03-12_16_14/pages/personal/order/template/[...id].vue?macro=true";
import { default as _91_46_46_46tab_93t8r4jd2hPyMeta } from "/var/www/2025-03-12_16_14/pages/personal/orders/[...tab].vue?macro=true";
import { default as templatesgyWxi5IB6lMeta } from "/var/www/2025-03-12_16_14/pages/personal/orders/templates.vue?macro=true";
import { default as promocodesjrgYKuVA7eMeta } from "/var/www/2025-03-12_16_14/pages/personal/promocodes.vue?macro=true";
import { default as privacy_45policyFlarLp7nPNMeta } from "/var/www/2025-03-12_16_14/pages/privacy-policy.vue?macro=true";
import { default as prohibited_45goodsuSGt2AR8NnMeta } from "/var/www/2025-03-12_16_14/pages/prohibited-goods.vue?macro=true";
import { default as registrationUSio061comMeta } from "/var/www/2025-03-12_16_14/pages/registration.vue?macro=true";
import { default as return_45sdP6oaXbBOJxMeta } from "/var/www/2025-03-12_16_14/pages/return-sd.vue?macro=true";
import { default as run_45scheduleH56j3JBSnGMeta } from "/var/www/2025-03-12_16_14/pages/run-schedule.vue?macro=true";
import { default as safe_45custodyHPz9M278xDMeta } from "/var/www/2025-03-12_16_14/pages/safe-custody.vue?macro=true";
import { default as servicesMdrt87xq7NMeta } from "/var/www/2025-03-12_16_14/pages/services.vue?macro=true";
import { default as auto_45partsxqgnQZIYdiMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/auto-parts.vue?macro=true";
import { default as barrelscywwRO0bPnMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/barrels.vue?macro=true";
import { default as bench4nfc91apVEMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/bench.vue?macro=true";
import { default as boatKBe3eDzlshMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/boat.vue?macro=true";
import { default as booksLMoXlPMiMAMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/books.vue?macro=true";
import { default as building_45materialslVGVe2v5hKMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/building-materials.vue?macro=true";
import { default as cable7ASjldmQekMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/cable.vue?macro=true";
import { default as chair8BsAxxdEioMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/chair.vue?macro=true";
import { default as clothesnSopsDWVnMMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/clothes.vue?macro=true";
import { default as consumer_45goodsWC4gLyPQYPMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/consumer-goods.vue?macro=true";
import { default as dishesbOQDkiPDeZMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/dishes.vue?macro=true";
import { default as engineIAjJrbkLdaMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/engine.vue?macro=true";
import { default as fragilexrmyL22XhYMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/fragile.vue?macro=true";
import { default as furnitureYtypkwBprQMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/furniture.vue?macro=true";
import { default as goods94wwkoDmzEMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/goods.vue?macro=true";
import { default as indexEpoZjlf2s2Meta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/index.vue?macro=true";
import { default as industrial_45equipmentoPtoZ99E2PMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/industrial-equipment.vue?macro=true";
import { default as lumbergKXKbRQDzIMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/lumber.vue?macro=true";
import { default as medical_45devicesfERCGloGV6Meta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/medical-devices.vue?macro=true";
import { default as metalB1WGDCtXyYMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/metal.vue?macro=true";
import { default as motorcycletransportationSu2OSkhdHSMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/motorcycletransportation.vue?macro=true";
import { default as musical_45instrumentskuU9Mk1dnIMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/musical-instruments.vue?macro=true";
import { default as newspaperssE58dLSRHdMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/newspapers.vue?macro=true";
import { default as oversizedWCuYHMt7gpMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/oversized.vue?macro=true";
import { default as personalitemstransportationw3lF0f4oKnMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/personalitemstransportation.vue?macro=true";
import { default as pianorM2ravdiyAMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/piano.vue?macro=true";
import { default as pipeseDPakIErGVMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/pipes.vue?macro=true";
import { default as quad_45bikeA4fXT5Tkh7Meta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/quad-bike.vue?macro=true";
import { default as refrigeratoraIzW97CFRPMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/refrigerator.vue?macro=true";
import { default as smallcargoRB11XjHY3PMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/smallcargo.vue?macro=true";
import { default as snowmobileC16af8kUNUMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/snowmobile.vue?macro=true";
import { default as sofadSTdcBr36QMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/sofa.vue?macro=true";
import { default as special_45equipmenthMz9hf0QQ1Meta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/special-equipment.vue?macro=true";
import { default as tools52ItRkc39hMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/tools.vue?macro=true";
import { default as trailerLKXujx3zq0Meta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/trailer.vue?macro=true";
import { default as wheelslclVwDlaVRMeta } from "/var/www/2025-03-12_16_14/pages/shippingtypes/wheels.vue?macro=true";
import { default as smsClientMacuwUzR7HMeta } from "/var/www/2025-03-12_16_14/pages/smsClient.vue?macro=true";
import { default as successfs0l5W5JVNMeta } from "/var/www/2025-03-12_16_14/pages/success.vue?macro=true";
import { default as tariffsxHIRSybczjMeta } from "/var/www/2025-03-12_16_14/pages/tariffs.vue?macro=true";
import { default as telegramnCaich5ISbMeta } from "/var/www/2025-03-12_16_14/pages/telegram.vue?macro=true";
import { default as terminals6yP0M80H0RMeta } from "/var/www/2025-03-12_16_14/pages/terminals.vue?macro=true";
import { default as directioniVOCvcQYv5Meta } from "/var/www/2025-03-12_16_14/pages/ui-kit/direction.vue?macro=true";
import { default as image06bAP0pK0HMeta } from "/var/www/2025-03-12_16_14/pages/ui-kit/image.vue?macro=true";
import { default as indexpIAGrlX9JzMeta } from "/var/www/2025-03-12_16_14/pages/ui-kit/index.vue?macro=true";
import { default as publicWEySaHKTzJMeta } from "/var/www/2025-03-12_16_14/pages/ui-kit/public.vue?macro=true";
import { default as svg9C1wdMCKTNMeta } from "/var/www/2025-03-12_16_14/pages/ui-kit/svg.vue?macro=true";
import { default as vacancytvU86iylSQMeta } from "/var/www/2025-03-12_16_14/pages/vacancy.vue?macro=true";
import { default as vzpay_45failtZl5bHsUxvMeta } from "/var/www/2025-03-12_16_14/pages/vzpay-fail.vue?macro=true";
import { default as _91hash_93FQrWTYjvEpMeta } from "/var/www/2025-03-12_16_14/pages/welcome/[hash].vue?macro=true";
import { default as bagXZen6bpLXBMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/bag.vue?macro=true";
import { default as boxQjfBPJqwfrMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/box.vue?macro=true";
import { default as bubble_45filmbmuuA7UBO0Meta } from "/var/www/2025-03-12_16_14/pages/wrapping/bubble-film.vue?macro=true";
import { default as cardboard8chG9iKHLLMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/cardboard.vue?macro=true";
import { default as extra_45packagelF2Y8D1zXNMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/extra-package.vue?macro=true";
import { default as hard_45boxwmyJyO8kV2Meta } from "/var/www/2025-03-12_16_14/pages/wrapping/hard-box.vue?macro=true";
import { default as indexJY9mulcCGXMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/index.vue?macro=true";
import { default as pallet_45boxJzsg9aEXgEMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/pallet-box.vue?macro=true";
import { default as palletisingA6NWDZlQlnMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/palletising.vue?macro=true";
import { default as safe_45packageR8vpJVgR3kMeta } from "/var/www/2025-03-12_16_14/pages/wrapping/safe-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    meta: _91slug_93uonyE0JNd6Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/[slug].vue")
  },
  {
    name: "address-delivery",
    path: "/address-delivery",
    component: () => import("/var/www/2025-03-12_16_14/pages/address-delivery.vue")
  },
  {
    name: "already-answer",
    path: "/already-answer",
    component: () => import("/var/www/2025-03-12_16_14/pages/already-answer.vue")
  },
  {
    name: "belarus",
    path: "/belarus",
    component: () => import("/var/www/2025-03-12_16_14/pages/belarus.vue")
  },
  {
    name: "bonuses",
    path: "/bonuses",
    component: () => import("/var/www/2025-03-12_16_14/pages/bonuses.vue")
  },
  {
    name: "cargo-loading",
    path: "/cargo-loading",
    component: () => import("/var/www/2025-03-12_16_14/pages/cargo-loading.vue")
  },
  {
    name: "cargo",
    path: "/cargo",
    component: () => import("/var/www/2025-03-12_16_14/pages/cargo.vue")
  },
  {
    name: "cash-on-delivery",
    path: "/cash-on-delivery",
    component: () => import("/var/www/2025-03-12_16_14/pages/cash-on-delivery.vue")
  },
  {
    name: "china",
    path: "/china",
    component: () => import("/var/www/2025-03-12_16_14/pages/china.vue")
  },
  {
    name: "claim-filing",
    path: "/claim-filing",
    component: () => import("/var/www/2025-03-12_16_14/pages/claim-filing.vue")
  },
  {
    name: "constructor",
    path: "/constructor",
    meta: constructor3xN83kfpzhMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/constructor.vue")
  },
  {
    name: "contacts",
    path: "/:tag(address|contacts)/:location?",
    meta: contactsrPLRjse6wXMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/contacts.vue")
  },
  {
    name: "delivery-russia",
    path: "/delivery-russia",
    component: () => import("/var/www/2025-03-12_16_14/pages/delivery-russia.vue")
  },
  {
    name: "dev-api",
    path: "/dev/api",
    component: () => import("/var/www/2025-03-12_16_14/pages/dev/api.vue")
  },
  {
    name: "dev-cms",
    path: "/dev/cms",
    component: () => import("/var/www/2025-03-12_16_14/pages/dev/cms.vue")
  },
  {
    name: "dev-iframe",
    path: "/dev/iframe",
    component: () => import("/var/www/2025-03-12_16_14/pages/dev/iframe.vue")
  },
  {
    name: "directions",
    path: "/directions",
    component: () => import("/var/www/2025-03-12_16_14/pages/directions.vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/var/www/2025-03-12_16_14/pages/documents.vue")
  },
  {
    name: "edo",
    path: "/edo",
    component: () => import("/var/www/2025-03-12_16_14/pages/edo.vue")
  },
  {
    name: "fast-delivery-capital",
    path: "/fast-delivery-capital",
    component: () => import("/var/www/2025-03-12_16_14/pages/fast-delivery-capital.vue")
  },
  {
    name: "forwarding-receipt",
    path: "/forwarding-receipt",
    component: () => import("/var/www/2025-03-12_16_14/pages/forwarding-receipt.vue")
  },
  {
    name: "franchise",
    path: "/franchise",
    component: () => import("/var/www/2025-03-12_16_14/pages/franchise.vue")
  },
  {
    name: "free-group-pickup",
    path: "/free-group-pickup",
    component: () => import("/var/www/2025-03-12_16_14/pages/free-group-pickup.vue")
  },
  {
    name: "free-pickup-cargo",
    path: "/free-pickup-cargo",
    component: () => import("/var/www/2025-03-12_16_14/pages/free-pickup-cargo.vue")
  },
  {
    name: "gifts-type",
    path: "/gift/:type(m|o)",
    meta: _91type_93QY2h7klXwyMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/gifts/[type].vue")
  },
  {
    name: "gifts",
    path: "/gifts",
    meta: indexKBu4NbVuXdMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/gifts/index.vue")
  },
  {
    name: "giveaway",
    path: "/giveaway",
    component: () => import("/var/www/2025-03-12_16_14/pages/giveaway.vue")
  },
  {
    name: "guide-lk",
    path: "/guide-lk",
    component: () => import("/var/www/2025-03-12_16_14/pages/guide-lk.vue")
  },
  {
    name: "happybirthday",
    path: "/happybirthday",
    meta: happybirthdayBPjNA8538PMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/happybirthday.vue")
  },
  {
    name: "happynewyear2024",
    path: "/happynewyear2024",
    meta: happynewyear2024L1iCMc8pXPMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/happynewyear2024.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/var/www/2025-03-12_16_14/pages/help.vue")
  },
  {
    name: "iframe-calculator",
    path: "/iframe/calculator",
    meta: calculatoratLJBJ6US1Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/iframe/calculator.vue")
  },
  {
    name: "iframe-manage",
    path: "/iframe/manage",
    meta: manageaR83QF2aP7Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/iframe/manage.vue")
  },
  {
    name: "iframe-order-administer-guid",
    path: "/iframe/order/administer/:guid()",
    meta: _91guid_93OwqSu0vz2cMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/iframe/order/administer/[guid].vue")
  },
  {
    name: "iframe-order-create",
    path: "/iframe/order/create",
    meta: indexKW4CMWfJc2Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/iframe/order/create/index.vue")
  },
  {
    name: "iframe-tariffs",
    path: "/iframe/tariffs",
    meta: tariffsYAUhxNcHJIMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/iframe/tariffs.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/2025-03-12_16_14/pages/index.vue")
  },
  {
    name: "information",
    path: "/information",
    component: () => import("/var/www/2025-03-12_16_14/pages/information.vue")
  },
  {
    name: "insurance",
    path: "/insurance",
    component: () => import("/var/www/2025-03-12_16_14/pages/insurance.vue")
  },
  {
    name: "kazakhstan",
    path: "/kazakhstan",
    component: () => import("/var/www/2025-03-12_16_14/pages/kazakhstan.vue")
  },
  {
    name: "kirghizia",
    path: "/kirghizia",
    component: () => import("/var/www/2025-03-12_16_14/pages/kirghizia.vue")
  },
  {
    name: "konkurs",
    path: "/konkurs",
    meta: konkursWajjTHGKzqMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/konkurs.vue")
  },
  {
    name: "mailClient10201917",
    path: "/mailClient10201917",
    meta: mailClient102019175GBcrjFcZYMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/mailClient10201917.vue")
  },
  {
    name: "mapScheme",
    path: "/mapScheme",
    meta: mapSchemeIAytqNGEccMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/mapScheme.vue")
  },
  {
    name: "marketplace-ozon",
    path: "/ozon",
    meta: ozonEZPWCehhxnMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/marketplace/ozon.vue")
  },
  {
    name: "marketplace-wildberries",
    path: "/wildberries",
    meta: wildberriesJ4JymmQLfpMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/marketplace/wildberries.vue")
  },
  {
    name: "marketplace-yandex-market",
    path: "/yandex-market",
    meta: yandex_45marketIj6yuhOTAsMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/marketplace/yandex-market.vue")
  },
  {
    name: "marketplaces",
    path: "/marketplaces",
    component: () => import("/var/www/2025-03-12_16_14/pages/marketplaces.vue")
  },
  {
    name: "merch-for-bonuses",
    path: "/merch-for-bonuses",
    component: () => import("/var/www/2025-03-12_16_14/pages/merch-for-bonuses.vue")
  },
  {
    name: "mobile-application",
    path: "/mobile-application",
    component: () => import("/var/www/2025-03-12_16_14/pages/mobile-application.vue")
  },
  {
    name: "networkshops",
    path: "/networkshops",
    component: () => import("/var/www/2025-03-12_16_14/pages/networkshops.vue")
  },
  {
    name: "news-id",
    path: "/:tag(news|actions)/:id",
    meta: _91id_93HaO38hz4NTMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/:tag(news|actions)",
    meta: indexPXMf4IUmWgMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/news/index.vue")
  },
  {
    name: "online-store",
    path: "/online-store",
    component: () => import("/var/www/2025-03-12_16_14/pages/online-store.vue")
  },
  {
    name: "order-administer-guid",
    path: "/order/administer/:guid()",
    component: () => import("/var/www/2025-03-12_16_14/pages/order/administer/[guid].vue")
  },
  {
    name: "order-create-locationFrom_locationTo",
    path: "/order/create/:locationFrom()_:locationTo()",
    component: () => import("/var/www/2025-03-12_16_14/pages/order/create/[locationFrom]_[locationTo].vue")
  },
  {
    name: "order-create-locations",
    path: "/order/create/:locations()",
    component: () => import("/var/www/2025-03-12_16_14/pages/order/create/[locations].vue")
  },
  {
    name: "order-create",
    path: "/order/create",
    meta: index69SrrUqQi3Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/order/create/index.vue")
  },
  {
    name: "order-light",
    path: "/order/light",
    component: () => import("/var/www/2025-03-12_16_14/pages/order/light.vue")
  },
  {
    name: "order-manage",
    path: "/order/manage",
    component: () => import("/var/www/2025-03-12_16_14/pages/order/manage.vue")
  },
  {
    name: "order-pay-id",
    path: "/order/pay/:id()",
    component: () => import("/var/www/2025-03-12_16_14/pages/order/pay/[id].vue")
  },
  {
    name: "order-pay-qr-id",
    path: "/order/pay/qr/:id()",
    component: () => import("/var/www/2025-03-12_16_14/pages/order/pay/qr/[id].vue")
  },
  {
    name: "payment-methods",
    path: "/payment-methods",
    component: () => import("/var/www/2025-03-12_16_14/pages/payment-methods.vue")
  },
  {
    name: "payment-bepaid-success",
    path: "/payment/bepaid-success",
    component: () => import("/var/www/2025-03-12_16_14/pages/payment/bepaid-success.vue")
  },
  {
    name: "payment-card-info",
    path: "/payment/card-info",
    component: () => import("/var/www/2025-03-12_16_14/pages/payment/card-info.vue")
  },
  {
    name: "payment-pay-fail",
    path: "/payment/pay-fail",
    component: () => import("/var/www/2025-03-12_16_14/pages/payment/pay-fail.vue")
  },
  {
    name: "payment-qr-info",
    path: "/payment/qr-info",
    component: () => import("/var/www/2025-03-12_16_14/pages/payment/qr-info.vue")
  },
  {
    name: "payment-success",
    path: "/payment/success",
    component: () => import("/var/www/2025-03-12_16_14/pages/payment/success.vue")
  },
  {
    name: "personal-account",
    path: "/personal/account",
    meta: accountuO1rFSEUB8Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/account.vue")
  },
  {
    name: "personal-auth",
    path: "/personal/auth",
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/auth.vue")
  },
  {
    name: "personal-bonus-tab",
    path: "/personal/bonus/:tab()",
    meta: _91tab_93qgAX4TqtS1Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/bonus/[tab].vue")
  },
  {
    name: "personal-contractors-guid",
    path: "/personal/contractors/:guid()",
    meta: _91guid_93okvuSQNU6UMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/contractors/[guid].vue")
  },
  {
    name: "personal-contractors",
    path: "/personal/contractors",
    meta: indexnqqRGqOdROMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/contractors/index.vue")
  },
  {
    name: "personal-discount",
    path: "/personal/discount",
    meta: discounteDg0bhSB4DMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/discount.vue")
  },
  {
    name: "personal-group-printing",
    path: "/personal/group-printing",
    meta: indexDTcCASIDjKMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/group-printing/index.vue")
  },
  {
    name: "personal-import",
    path: "/personal/import",
    meta: importhYoOtdkiKkMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/import.vue")
  },
  {
    name: "personal",
    path: "/personal",
    meta: indexZvHwKKMwmAMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/index.vue")
  },
  {
    name: "personal-logout",
    path: "/personal/logout",
    meta: logoutQeLd11sHTRMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/logout.vue")
  },
  {
    name: "personal-multi-delivery-guid",
    path: "/personal/multi-delivery/:guid",
    meta: _91guid_93satu3XGw79Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/multi-delivery/[guid].vue")
  },
  {
    name: "personal-multi-delivery",
    path: "/personal/multi-delivery",
    meta: indexT8iJSJBY0TMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/multi-delivery/index.vue")
  },
  {
    name: "personal-multi-pickup-guid",
    path: "/personal/multi-pickup/:guid",
    meta: _91guid_93UKlvk5YdDoMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/multi-pickup/[guid].vue")
  },
  {
    name: "personal-multi-pickup",
    path: "/personal/multi-pickup",
    meta: indexavg0iAM58vMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/multi-pickup/index.vue")
  },
  {
    name: "personal-order-guid",
    path: "/personal/order/:tag(pay)?/:guid",
    meta: _91guid_93ZdnC1zAKO1Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/order/[guid].vue")
  },
  {
    name: "personal-order-create",
    path: "/personal/order/create",
    meta: createl36XhI41s2Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/order/create.vue")
  },
  {
    name: "personal-order-print",
    path: "/personal/order/print",
    meta: printsOUaQ1Ukd9Meta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/order/print.vue")
  },
  {
    name: "personal-order-template-id",
    path: "/personal/order/template/:id(.*)*",
    meta: _91_46_46_46id_93VD3DdQVDegMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/order/template/[...id].vue")
  },
  {
    name: "personal-orders-tab",
    path: "/personal/orders/:tab(.*)*",
    meta: _91_46_46_46tab_93t8r4jd2hPyMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/orders/[...tab].vue")
  },
  {
    name: "personal-orders-templates",
    path: "/personal/orders/templates",
    meta: templatesgyWxi5IB6lMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/orders/templates.vue")
  },
  {
    name: "personal-promocodes",
    path: "/personal/promocodes",
    meta: promocodesjrgYKuVA7eMeta || {},
    component: () => import("/var/www/2025-03-12_16_14/pages/personal/promocodes.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/var/www/2025-03-12_16_14/pages/privacy-policy.vue")
  },
  {
    name: "prohibited-goods",
    path: "/prohibited-goods",
    component: () => import("/var/www/2025-03-12_16_14/pages/prohibited-goods.vue")
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/var/www/2025-03-12_16_14/pages/registration.vue")
  },
  {
    name: "return-sd",
    path: "/return-sd",
    component: () => import("/var/www/2025-03-12_16_14/pages/return-sd.vue")
  },
  {
    name: "run-schedule",
    path: "/run-schedule",
    component: () => import("/var/www/2025-03-12_16_14/pages/run-schedule.vue")
  },
  {
    name: "safe-custody",
    path: "/safe-custody",
    component: () => import("/var/www/2025-03-12_16_14/pages/safe-custody.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/var/www/2025-03-12_16_14/pages/services.vue")
  },
  {
    name: "shippingtypes-auto-parts",
    path: "/shippingtypes/auto-parts",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/auto-parts.vue")
  },
  {
    name: "shippingtypes-barrels",
    path: "/shippingtypes/barrels",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/barrels.vue")
  },
  {
    name: "shippingtypes-bench",
    path: "/shippingtypes/bench",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/bench.vue")
  },
  {
    name: "shippingtypes-boat",
    path: "/shippingtypes/boat",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/boat.vue")
  },
  {
    name: "shippingtypes-books",
    path: "/shippingtypes/books",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/books.vue")
  },
  {
    name: "shippingtypes-building-materials",
    path: "/shippingtypes/building-materials",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/building-materials.vue")
  },
  {
    name: "shippingtypes-cable",
    path: "/shippingtypes/cable",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/cable.vue")
  },
  {
    name: "shippingtypes-chair",
    path: "/shippingtypes/chair",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/chair.vue")
  },
  {
    name: "shippingtypes-clothes",
    path: "/shippingtypes/clothes",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/clothes.vue")
  },
  {
    name: "shippingtypes-consumer-goods",
    path: "/shippingtypes/consumer-goods",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/consumer-goods.vue")
  },
  {
    name: "shippingtypes-dishes",
    path: "/shippingtypes/dishes",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/dishes.vue")
  },
  {
    name: "shippingtypes-engine",
    path: "/shippingtypes/engine",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/engine.vue")
  },
  {
    name: "shippingtypes-fragile",
    path: "/shippingtypes/fragile",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/fragile.vue")
  },
  {
    name: "shippingtypes-furniture",
    path: "/shippingtypes/furniture",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/furniture.vue")
  },
  {
    name: "shippingtypes-goods",
    path: "/shippingtypes/goods",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/goods.vue")
  },
  {
    name: "shippingtypes",
    path: "/shippingtypes",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/index.vue")
  },
  {
    name: "shippingtypes-industrial-equipment",
    path: "/shippingtypes/industrial-equipment",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/industrial-equipment.vue")
  },
  {
    name: "shippingtypes-lumber",
    path: "/shippingtypes/lumber",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/lumber.vue")
  },
  {
    name: "shippingtypes-medical-devices",
    path: "/shippingtypes/medical-devices",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/medical-devices.vue")
  },
  {
    name: "shippingtypes-metal",
    path: "/shippingtypes/metal",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/metal.vue")
  },
  {
    name: "shippingtypes-motorcycletransportation",
    path: "/shippingtypes/motorcycletransportation",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/motorcycletransportation.vue")
  },
  {
    name: "shippingtypes-musical-instruments",
    path: "/shippingtypes/musical-instruments",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/musical-instruments.vue")
  },
  {
    name: "shippingtypes-newspapers",
    path: "/shippingtypes/newspapers",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/newspapers.vue")
  },
  {
    name: "shippingtypes-oversized",
    path: "/shippingtypes/oversized",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/oversized.vue")
  },
  {
    name: "shippingtypes-personalitemstransportation",
    path: "/shippingtypes/personalitemstransportation",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/personalitemstransportation.vue")
  },
  {
    name: "shippingtypes-piano",
    path: "/shippingtypes/piano",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/piano.vue")
  },
  {
    name: "shippingtypes-pipes",
    path: "/shippingtypes/pipes",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/pipes.vue")
  },
  {
    name: "shippingtypes-quad-bike",
    path: "/shippingtypes/quad-bike",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/quad-bike.vue")
  },
  {
    name: "shippingtypes-refrigerator",
    path: "/shippingtypes/refrigerator",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/refrigerator.vue")
  },
  {
    name: "shippingtypes-smallcargo",
    path: "/shippingtypes/smallcargo",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/smallcargo.vue")
  },
  {
    name: "shippingtypes-snowmobile",
    path: "/shippingtypes/snowmobile",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/snowmobile.vue")
  },
  {
    name: "shippingtypes-sofa",
    path: "/shippingtypes/sofa",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/sofa.vue")
  },
  {
    name: "shippingtypes-special-equipment",
    path: "/shippingtypes/special-equipment",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/special-equipment.vue")
  },
  {
    name: "shippingtypes-tools",
    path: "/shippingtypes/tools",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/tools.vue")
  },
  {
    name: "shippingtypes-trailer",
    path: "/shippingtypes/trailer",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/trailer.vue")
  },
  {
    name: "shippingtypes-wheels",
    path: "/shippingtypes/wheels",
    component: () => import("/var/www/2025-03-12_16_14/pages/shippingtypes/wheels.vue")
  },
  {
    name: "smsClient",
    path: "/smsClient",
    component: () => import("/var/www/2025-03-12_16_14/pages/smsClient.vue")
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/var/www/2025-03-12_16_14/pages/success.vue")
  },
  {
    name: "tariffs",
    path: "/tariffs",
    component: () => import("/var/www/2025-03-12_16_14/pages/tariffs.vue")
  },
  {
    name: "telegram",
    path: "/telegram",
    component: () => import("/var/www/2025-03-12_16_14/pages/telegram.vue")
  },
  {
    name: "terminals",
    path: "/terminals",
    component: () => import("/var/www/2025-03-12_16_14/pages/terminals.vue")
  },
  {
    name: "ui-kit-direction",
    path: "/ui-kit/direction",
    component: () => import("/var/www/2025-03-12_16_14/pages/ui-kit/direction.vue")
  },
  {
    name: "ui-kit-image",
    path: "/ui-kit/image",
    component: () => import("/var/www/2025-03-12_16_14/pages/ui-kit/image.vue")
  },
  {
    name: "ui-kit",
    path: "/ui-kit",
    component: () => import("/var/www/2025-03-12_16_14/pages/ui-kit/index.vue")
  },
  {
    name: "ui-kit-public",
    path: "/ui-kit/public",
    component: () => import("/var/www/2025-03-12_16_14/pages/ui-kit/public.vue")
  },
  {
    name: "ui-kit-svg",
    path: "/ui-kit/svg",
    component: () => import("/var/www/2025-03-12_16_14/pages/ui-kit/svg.vue")
  },
  {
    name: "vacancy",
    path: "/vacancy",
    component: () => import("/var/www/2025-03-12_16_14/pages/vacancy.vue")
  },
  {
    name: "vzpay-fail",
    path: "/vzpay-fail",
    component: () => import("/var/www/2025-03-12_16_14/pages/vzpay-fail.vue")
  },
  {
    name: "welcome-hash",
    path: "/welcome/:hash()",
    component: () => import("/var/www/2025-03-12_16_14/pages/welcome/[hash].vue")
  },
  {
    name: "wrapping-bag",
    path: "/wrapping/bag",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/bag.vue")
  },
  {
    name: "wrapping-box",
    path: "/wrapping/box",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/box.vue")
  },
  {
    name: "wrapping-bubble-film",
    path: "/wrapping/bubble-film",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/bubble-film.vue")
  },
  {
    name: "wrapping-cardboard",
    path: "/wrapping/cardboard",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/cardboard.vue")
  },
  {
    name: "wrapping-extra-package",
    path: "/wrapping/extra-package",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/extra-package.vue")
  },
  {
    name: "wrapping-hard-box",
    path: "/wrapping/hard-box",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/hard-box.vue")
  },
  {
    name: "wrapping",
    path: "/wrapping",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/index.vue")
  },
  {
    name: "wrapping-pallet-box",
    path: "/wrapping/pallet-box",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/pallet-box.vue")
  },
  {
    name: "wrapping-palletising",
    path: "/wrapping/palletising",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/palletising.vue")
  },
  {
    name: "wrapping-safe-package",
    path: "/wrapping/safe-package",
    component: () => import("/var/www/2025-03-12_16_14/pages/wrapping/safe-package.vue")
  }
]