<template>
  <div ref="target" class="vz-position-relative">
    <vz-button
      variant="primary"
      icon-size="26"
      icon-name="account"
      class="public-header-button-personal"
      :to="to"
      @click="onClick"
      @click.ctrl.alt.right.prevent.stop="logout"
    >
      <div class="public-header-button-personal-text text-overflow" :title="buttonTitle">
        {{ title }}
      </div>
    </vz-button>
    <client-only v-if="showModal && !isAuthPage">
      <div class="vz-auth-modal" v-on-click-outside="onClickOutside">
        <vz-loader :status-loader="loader"/>
        <vz-button-link
          icon-name="close"
          size="26"
          class="vz-auth-modal-close"
          variant="light"
          @click="onClose"
        />
        <vz-auth-form/>
      </div>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import {storeToRefs} from 'pinia';
import {useUserStore} from '~/stores/personal/user';
import {RouteLocationRaw} from 'vue-router';
import VzButton from '~/components/ui-kit/vz-button.vue';
import VzLoader from '~/components/ui-kit/vz-loader.vue';
import VzButtonLink from '~/components/ui-kit/vz-button-link.vue';
import VzAuthForm from '~/components/forms/auth/vz-auth-form.vue';
import {defineEmits} from '@vue/runtime-core';

const emit = defineEmits(['show-login'])

const props = defineProps({
  link: {
    type: Boolean,
    default: false
  }
})

const {isAuth, username, token} = storeToRefs(useUserStore())
const route = useRoute()
const showModal = ref(false)
const loader = ref(false)
const target = ref()

const title = computed(() => {
  return (isAuth.value ? username.value : 'Личный кабинет') || 'Личный кабинет'
})

const isAuthPage = computed(() => {
  return route.name === 'personal-auth' || route.name === 'registration'
})

const buttonTitle = computed(() => {
  return title.value.length > 20 ? title.value : ''
})

const to = computed((): RouteLocationRaw | undefined => {
  return isAuth.value
    ? {name: 'personal-orders-tab'}
    : props.link ? { name: 'personal-auth' } : undefined
})

function onClick(e: MouseEvent) {
  if (props.link) {
    return
  }
  if (isAuthPage.value) {
    const input = document.querySelector('.vz-auth-form .vz-input input') as any
    input?.focus()
    return
  }
  if (!isAuth.value) {
    e.preventDefault()
    showModal.value = true
    if (showModal.value) {
      emit('show-login')
    }
  }
}

function onClose() {
  showModal.value = false
}

function logout() {
  token.value = null
  username.value = null
}

function onClickOutside(e: PointerEvent | any) {
  // const path = e.composedPath ? e.composedPath() : e.path
  // const clickOutside = !!path?.find(p => p.id === '__nuxt')
  // if (clickOutside) {
  onClose()
  // }
}
</script>

<style lang="scss" scoped>
.public-header-button-personal-text {
  max-width: 200px;
}

.vz-auth-modal {
  position: absolute;
  z-index: 10000;
  min-width: 320px;
  right: 0;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  top: calc(100% + 10px);
}

.vz-auth-modal {
  cursor: default;
  padding: 30px 20px;
  width: 380px;

  .vz-auth-modal-close {
    position: absolute;
    right: 10px;
    top: 8px;
  }
}

@media screen and (max-width: $pub-xs) {
  .vz-auth-modal {
    left: 0;
  }
}
@media screen and (max-width: $xs) {
  .vz-auth-modal {
    width: 100%;
    min-width: 300px;
  }
}
</style>
