import {getSeo} from '~/api/seo';
import {useCityStore} from '~/stores/city';
import {useSeoStore} from '~/stores/seo';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.env?.VITE_IS_HISTOIRE) {
    return;
  }

  const cityStore = useCityStore()
  const seoStore = useSeoStore()

  const data = await getSeo({ domain: cityStore.currentCityAbbr || 'msk', path: to.path, query: '' })
  seoStore.seo = data

  if (data) {
    cityStore.currentPhones = Object.keys(data.phones)
  }
})
