<template>
  <vz-confirm-code
    ref="control"
    :modelValue="modelValue"
    :timeout="timeout"
    :loader="loader"
    :error="error"
    :message="message"
    :rules="[formRules.required]"
    @update:modelValue="emit('update:modelValue', $event)"
    @submit="emit('submit', $event)"
    @send="sendConfirmCode"
  />
</template>

<script setup lang="ts">
import VzConfirmCode from '~/components/ui-kit/vz-confirm-code.vue';
import {confirmCode} from "~/api/user";
import formRules from '~/common/vz-form-rules';
import {cookieOptions} from '~/common/functions';
import {useBackendStore} from '~/stores/backend';
import {storeToRefs} from 'pinia';
import dayjs from "dayjs";

const emit = defineEmits(['update:modelValue', 'update:error', 'submit'])
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  login: {
    type: String,
    default: ''
  },
  context: {
    type: String,
    default: 'userReg'
  },
  error: {
    type: String,
    default: ''
  },
})

const { $showToast } = useNuxtApp()
const loginCookie = useCookie('_vz_lgn', cookieOptions())
const authTime = useCookie('_vz_auth_time')
const { backendAvailable } = storeToRefs(useBackendStore())

const control = ref()

const message = ref('')
const loader = ref(false)
const timeout = ref<number>()

async function sendConfirmCode(): Promise<void> {
  loader.value = true
  emit('update:modelValue', '')
  emit('update:error', '')
  try {
    const res = await confirmCode(
      { recipient: props.login, context: props.context, usingHtml: true },
      backendAvailable.value
    )
    message.value = res?.message
    timeout.value = res?.timeout
  } catch (e: any) {
    console.log('catch', e, e.response?._data)
    timeout.value = e.response?._data?.timeout || 0

    if (e.response?._data?.error?.message) {
      $showToast({
        title: 'Ошибка',
        text: e.response._data.error.message,
        variant: 'error'
      })
    }
  } finally {
    loader.value = false
    authTime.value = +dayjs().add(timeout.value || 0, 'second')
  }
}

onMounted(() => {
  if (authTime.value && dayjs(authTime.value).diff(dayjs(), 'second') > 0) {
    timeout.value = dayjs(authTime.value).diff(dayjs(), 'second')
  } else {
    authTime.value = 0
  }

  control.value?.input?.[0]?.focus?.()
  if (!loader.value && !props.modelValue) {
    if (!timeout.value) {
      sendConfirmCode()
    } else if (loginCookie.value !== props.login) {
      loginCookie.value = props.login
      sendConfirmCode()
    }
  }
})
</script>

<style lang="scss">
</style>
