<template>
  <div class="vz-switcher" :class="switcherClass" ref="$el">
    <div v-if="title" class="vz-switcher-title">
      <slot name="label">
        <span>{{ title }}</span>
      </slot>
      <vz-select
        v-if="useStatus"
        v-model="selectedCountry"
        tiny
        :disabled="disabled"
        :items="icons"
        list-class="vz-switcher-country-selector-list"
        @change="onCountryChange"
      />
    </div>
    <div
      ref="switcher"
      class="vz-switcher-wrapper"
      :class="{'has-error': isShowError}"
    >
      <template v-if="tabs">
        <div
          v-for="item of items"
          class="vz-switcher-item"
          :class="[{ active: item.value === modelValue, disabled: disabled || item.disabled }, item.classItem]"
          :title="getTooltipText(item)"
          @click="onChange(item)"
        >
          <span v-html="item.title"/>
        </div>
      </template>
      <template v-else>
        <vz-tooltip v-for="item of items" :key="item.value" flex :placement="item.tooltipPlacement">
          <div
            class="vz-switcher-item"
            :class="[{active: item.value === modelValue, disabled: disabled || item.disabled }, item.classItem]"
            @click="onChange(item)"
          >
            <vz-icon
              v-if="item.icon"
              :url="item.icon.url"
              :name="item.icon.name"
              :size="item.icon.size"
              no-masked-icon
              class="flex-align-self-start"
              :variant="item.value === modelValue ? item.icon.variant || 'primary': ''"
            />
            <span
              v-html="isLoad && ['pickupPoint', 'address'].includes(item.value) ? item.title.split('(')[0] : item.title"/>
          </div>
          <template v-if="getTooltipText(item)" #balloon>
            <vz-icon
              v-if="item.tooltipIconOptions"
              v-bind="item.tooltipIconOptions"
            />
            <span v-html="getTooltipText(item)"/>
          </template>
        </vz-tooltip>
      </template>
    </div>

    <vz-invalid-message v-if="isShowError">
      {{ errorText }}
    </vz-invalid-message>
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue'
import {SwitchItem, VzClass} from '~/types/types'
import type VzTooltip from './vz-tooltip.vue'
import {phoneCountries} from '~/common/objects';
import VzSelect from '~/components/ui-kit/vz-select.vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzInvalidMessage from '~/components/ui-kit/vz-invalid-message.vue';

const emit = defineEmits([
  'country-change',
  'change',
  'update:modelValue'
])
const props = defineProps({
  modelValue: {
    type: [String, Boolean, Number],
    default: '',
  },
  items: {
    type: Array as PropType<SwitchItem[]>,
    default: () => []
  },
  title: {
    type: String,
    default: ''
  },
  disabled: {
    default: false,
    type: Boolean
  },
  useStatus: {
    default: true,
    type: Boolean
  },
  tabs: {
    default: false,
    type: Boolean
  },
  big: {
    default: false,
    type: Boolean
  },
  clearable: {
    default: false,
    type: Boolean
  },
  publicStyle: {
    default: false,
    type: Boolean
  },
  isLoad: {
    default: false,
    type: Boolean
  }
})

const {dispatchFormEvent, isShowError, doValidate, errorText} = useValidateble()
const switcher = ref<HTMLDivElement>()
const selector = ref<HTMLDivElement>()
const $el = ref()

const icons = phoneCountries.map(item => ({
  url: item.url,
  value: item.value,
  tooltip: item.name
}))

const selectedCountry = ref('RU')

const switcherClass = computed((): VzClass => {
  return {
    'vz-switcher-tabs': props.tabs,
    big: props.big || props.tabs,
    clearable: props.clearable && !props.disabled,
    'vz-switcher-public': props.publicStyle
  }
})

const selectedItem = computed((): SwitchItem => {
  return props?.items.find(val => val.value === props.modelValue) || {title: '', value: ''}
})

const getTooltipText = (item: SwitchItem): string => {
  if (item.disabled && item.disabledText) {
    return item.disabledText
  }
  return item.tooltipText || ''
}

function onChange(item: SwitchItem) {
  if (item.disabled || props.disabled) {
    return
  }
  if (selectedItem.value.value !== item.value) {
    emit('update:modelValue', item.value)
    emit('change', item)
    nextTick(() => {
      dispatchFormEvent($el.value)
    })
  } else if (props.clearable) {
    emit('update:modelValue', null)
    emit('change', null)
  }
}

const onCountryChange = (): void => {
  if (props.disabled) {
    return
  }
  emit('country-change', selectedCountry.value)
}

async function checkError(silent: boolean = false) {
  return await doValidate(
    props.modelValue,
    [{
      cb: () => {
        if (selectedItem.value.disabled && props?.items.find(item => !item.disabled)) {
          return selectedItem.value.disabledText || 'Выбранное значение недоступно'
        }
        return true
      }
    }],
    silent
  )
}

defineExpose({
  isShowError,
  checkError
})
</script>

<style lang="scss">

</style>
