import {
  alpha,
  checkInn, decimal,
  email,
  equalLength,
  innValidator,
  kppValidator,
  max, maxLength, min,
  minLength, newInnValidator, notEqual, phone, phoneCountry, required
} from './validators'

const formRules = {
  email: {
    cb: email,
    customMessage: '',
    param: null
  },
  notEqual: {
    cb: notEqual,
    customMessage: '',
    param: () => []
  },
  required: {
    cb: required,
    customMessage: '',
    param: null
  },
  alpha: {
    cb: alpha,
    customMessage: '',
    param: null
  },
  checkInn: {
    cb: checkInn,
    customMessage: '',
    param: null
  },
  decimal: {
    cb: decimal,
    customMessage: '',
    param: null
  },
  equalLength: {
    cb: equalLength,
    customMessage: '',
    param: null
  },
  inn: {
    cb: innValidator,
    customMessage: '',
    param: null
  },
  kpp: {
    cb: kppValidator,
    customMessage: '',
    param: null
  },
  max: {
    cb: max,
    customMessage: '',
    param: null
  },
  maxLength: {
    cb: maxLength,
    customMessage: '',
    param: null
  },
  min: {
    cb: min,
    customMessage: '',
    param: null
  },
  minLength: {
    cb: minLength,
    customMessage: '',
    param: null
  },
  newInnValidator: {
    cb: newInnValidator,
    customMessage: '',
    param: null
  },
  phone: {
    cb: phone,
    customMessage: '',
    param: null
  },
  phoneCountry: {
    cb: phoneCountry,
    customMessage: '',
    param: null
  },
  emailOrPhone: {
    cb: (v: any, params?: unknown, customMessage?: string) => {
      const emailValid = email(v)
      const phoneValid = phone(v.replace(/\D/g, ''))
      if (emailValid === true || phoneValid === true) {
        return true
      }
      return customMessage || 'Телефон или E-mail'
    },
    customMessage: 'Телефон или E-mail',
    param: null
  }
}
export default formRules
