import { PublicMenuItem } from '~/types/public';
import {useDomainCountry} from "~/composables/states";

const useMenuItems = () => {
  const domainCountry = useDomainCountry()

  const isHideInsurance = ['kz'].includes(domainCountry.value.toLowerCase())

  const sI1Part2 = computed(() => {
    const result = []

    if(!isHideInsurance) {
      result.push({
        title: 'Страхование',
        link: '/insurance/'
      })
    }

    result.push({
      title: 'Ответственное хранение',
      link: '/safe-custody/'
    })

    result.push({
      title: 'Еще',
      link: '/services/'
    })

    return result
  })

  const servicesItems1 = computed((): PublicMenuItem[][] => [
    [
      {
        title: 'Адресная доставка',
        link: '/address-delivery/'
      },
      {
        title: 'Погрузо-разгрузочные работы',
        link: '/cargo-loading/'
      },
      {
        title: 'Выдача грузов на пунктах Яндекс',
        link: '/yandex-market/'
      },
      {
        title: 'Доставка заказов OZON',
        link: '/ozon/'
      },
      {
        title: 'Упаковка',
        link: '/wrapping/'
      },
    ],
    sI1Part2.value
  ])

  const servicesItems2: PublicMenuItem[][] = [
    [
      {
        title: 'Доставка сборных грузов',
        link: '/delivery-russia/'
      },
      {
        title: 'Доставка в торговые сети',
        link: '/networkshops/'
      },
      {
        title: 'Доставка на маркетплейсы',
        link: '/marketplaces/'
      },
      {
        title: 'Доставка для интернет-магазинов',
        link: '/online-store/'
      },
      {
        title: 'Типы грузоперевозок',
        link: '/shippingtypes/'
      },
    ],
    [
      {
        title: 'Грузоперевозки Россия',
        link: '/terminals/'
      },
      {
        title: 'Грузоперевозки Беларусь',
        link: '/belarus/'
      },
      {
        title: 'Грузоперевозки Казахстан',
        link: '/kazakhstan/'
      },
      {
        title: 'Грузоперевозки Китай',
        link: '/china/'
      },
      {
        title: 'Еще',
        link: '/cargo/'
      },
    ]
  ]

  const infoItems: PublicMenuItem[][] = [
    [
      {
        title: 'Сроки доставки',
        link: '/run-schedule/'
      },
      {
        title: 'Способы оплаты',
        link: '/payment-methods/'
      },
      {
        title: 'Тарифы',
        link: '/tariffs/'
      },
      {
        title: 'Грузы, не принимаемые к перевозке',
        link: '/prohibited-goods/'
      },
      {
        title: 'Документы',
        link: '/documents/'
      },
    ],
    [
      {
        title: 'Франшиза',
        link: '/franchise/'
      },
      {
        title: 'Перевозчикам',
        link: 'https://vozovoz.partners/'
      },
      {
        title: 'Разработчикам',
        link: '/information/#develop'
      },
      {
        title: 'Вакансии',
        link: '/vacancy/'
      },
      {
        title: 'Еще',
        link: '/information/'
      },
    ]
  ]

  const popularItems: PublicMenuItem[] = [
    {
      title: 'Сборные перевозки',
      link: '/delivery-russia/'

    },
    {
      title: 'Доставка от двери до двери',
      link: '/address-delivery/'
      
    },
    {
      title: 'Доставка в ТС и маркетплейсы Ozon, Wildberries, Яндекс Маркет',
      link: '/networkshops/'
      
    },
    {
      title: 'Доставка крупногабаритных грузов',
      link: '/shippingtypes/oversized/'
      
    },
    {
      title: 'Доставка для интернет-магазинов',
      link: '/online-store/'
      
    },
    {
      title: 'Перевозка вещей',
      link: '/shippingtypes/personalitemstransportation/'
      
    },
  ]

  const maintenanceItems: PublicMenuItem[] = [
    {
      title: 'Услуги',
      link: '/services/'
    },
    {
      title: 'Тарифы',
      link: '/tariffs/'
    },
    {
      title: 'Филиалы',
      link: '/address/'
    },
    {
      title: 'Вакансии',
      link: '/vacancy/'
    },
    {
      title: 'Грузоперевозки по России',
      link: '/terminals/'
    },
    {
      title: 'Направления грузоперевозок',
      link: '/directions/'
    }
  ]

  const footerItems: PublicMenuItem[] = [
    {
      title: 'Информация',
      link: '/information/'
    },
    {
      title: 'Документы',
      link: '/documents/'
    },
    {
      title: 'Франшиза',
      link: '/franchise/'
    },
    {
      title: 'Перевозчикам',
      link: 'https://vozovoz.partners/'
    },
    {
      title: 'Разработчикам',
      link: '/information/#develop'
    },
    {
      title: 'Грузоперевозки',
      link: '/cargo/'
    },
  ]

  return {
    servicesItems1,
    servicesItems2,
    infoItems,
    popularItems,
    maintenanceItems,
    footerItems,
    isHideInsurance
  }
}

export default useMenuItems
