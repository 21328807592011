import {httpApiV3} from '~/composables/useVzFetch';
import {useMemoize} from '@vueuse/core';
import {PageContent, ContentPageList} from '~/types/content';
import {getDeepValue} from '~/common/functions';
import {Meta} from '~/types/types';

/** Returns lists of pages, available for `GET content` requests via URL or ID */
export const getAllPagesContent = useMemoize(
  () => {
    return $fetch(`${httpApiV3}/content/page`) as Promise<ContentPageList>
  }
)

export const getPageContent = useMemoize(
  (url: string) => {
    return $fetch<PageContent>(`${httpApiV3}/content/page`, {params: {url}})
      .catch(() => {
        return {} as PageContent
      })
  }
)

export const getTextContent = useMemoize(
  (guid: string) => {
    return $fetch(`${httpApiV3}/content/text/${guid}`) as Promise<{ content: string }>
  }
)

export const searchContent = useMemoize(
  (search: string, page: number = 1) => {
    return $fetch<{ data: Record<string, { announce: string, title: string }>, meta: Meta }>(
      `${httpApiV3}/content/search`,
      {
        params: {
          page,
          search
        }
      }
    )
  }
)

export function replaceContentText(text: string, data: any) {
  const res = text.match(/%.*?%/g)
  res?.forEach(match => {
    const path = match.replace(/%/g, '')
    text = text.replace(match, getDeepValue(data, path))
  })
  return text
}
