<template>
  <transition name="fade">
    <div class="public-header-menu-mobile">
      <div class="public-container public-container-mobile">
        <div class="public-header-menu-mobile-top flex flex-end flex-align-items-center">
          <public-header-button-personal link @click="close"/>
          <vz-icon
            size="36"
            name="close"
            @click="close"
            class="public-header-menu-mobile-close"
          />
        </div>
      </div>
      <vz-collapse-info v-for="menuItem of menuItems">
        <template #header>
          <span>{{ menuItem.title }}</span>
        </template>
        <div class="public-container flex flex-start">
          <div v-for="links in menuItem.items" class="public-header-menu-mobile-links">
            <nuxt-link
              v-for="item in links"
              :key="item.link"
              :to="item.link"
              @click="close"
            >
              {{ item.title }}
            </nuxt-link>
          </div>
        </div>
      </vz-collapse-info>
      <div class="public-container public-header-menu-mobile-address">
        <nuxt-link :to="{ name: 'directions' }" class="public-header-menu-mobile-link" @click="close">
          Направления
        </nuxt-link>
        <public-header-control dark @close="close"/>
        <public-header-search/>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import PublicHeaderButtonPersonal from '~~/components/public/header/public-header-button-personal.vue'
import PublicHeaderControl from '~/components/public/header/public-header-control.vue'
import PublicHeaderSearch from '~/components/public/header/public-header-search.vue'
import VzCollapseInfo from '~/components/ui-kit/vz-collapse-info.vue'
import useMenuItems from "~/composables/useMenuItems";
import {computed} from "vue";

const {servicesItems1, servicesItems2, infoItems} = useMenuItems()
const emit = defineEmits(['close'])

const menuItems = computed(() => [
  {
    key: 'services',
    title: 'Услуги',
    link: '/services/',
    items: servicesItems1.value,
  },
  {
    key: 'cargo',
    title: 'Грузоперевозки',
    link: '/cargo/',
    items: servicesItems2,
  },
  {
    key: 'info',
    link: '/information/',
    title: 'Информация',
    items: infoItems,
  },
])

function close(): void {
  emit('close')
}
</script>
