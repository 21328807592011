export const appleApp = 'https://apps.apple.com/ru/app/vozovoz-%D0%B3%D1%80%D1%83%D0%B7%D0%BE%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B7%D0%BA%D0%B8/id1546357679'
export const googleApp = 'https://play.google.com/store/apps/details?id=ru.vozovoz.customers'
export const androidApk = 'https://vozovoz.ru/upload/android/ru.vozovoz.customers-1.3.0.apk'
export const telegramBot = '/telegram/'
export const vkLink = 'https://vk.com/vozovoz'
export const okLink = 'https://ok.ru/group/63162687094953'
export const tgLink = 'https://t.me/vozovoz'
export const hhLink = 'https://hh.ru/employer/1536522'
export const avitoLink = 'https://www.avito.ru/user/9373a9cd99518d965bb47af7e4470447/profile?src=sharing'
export const ftpServerUrl = 'https://files.vozovoz.ru'
export const vozovozEdo = 'https://vozovoz.ru/upload/documents/%D0%94%D0%BE%D0%BF.%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%AD%D0%94%D0%9E%20%D0%BA%20%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B5%20%D0%BD%D0%B0%20%D1%81%D0%B0%D0%B9%D1%82%D0%B5.docx'
export const excelDocumentation = 'https://files.vozovoz.ru/filesforsite/importExcel_description.docx'
export const excelExampleFile = 'https://spb.vozovoz.ru/upload/documents/importExcel.xlsx'
export const privacyPolicy = 'https://vozovoz.ru/upload/docs/Политика_конфиденциальности.docx'
export const personalDataProcessing = 'https://vozovoz.ru/upload/docs/Политика_для_сайта_vozovoz.pdf'
export const requiredHardWrapping = 'https://vozovoz.ru/upload/documents/6c8d6aee166697b478f120b62b394226.pdf'
export const freeMultiPickup = 'https://vozovoz.ru/upload/article/1591693027_БесплатныйГрупповойЗабор.pdf'
export const cardPaymentInstruction = 'https://files.vozovoz.ru/filesforsite/Инструкция Оплата Картами.rtf'
export const freeWarehousing = 'https://vozovoz.ru/upload/documents/2024_06_17_Окно_ВЗВ_расписание_итоговый_список.xlsx'
