<template>
  <div class="public-footer-contacts">
    <a :href="`tel:${phone.replace(/ /g, '')}`" class="public-footer-contacts-phone" itemprop="telephone" v-html="phone"/>
    <vz-button
      link
      :to="contactsLink"
      variant="white"
      class="public-footer-contacts-button"
      big
    >
      Контакты
    </vz-button>

    <div class="flex flex-space-between public-footer-contacts-links">
      <a :href="vkLink" target="_blank">
        <vz-icon
          no-masked-icon
          url="/svg/vk.svg"
          size="24"
          rounded
        />
        vkontakte
      </a>
      <a :href="tgLink" target="_blank">
        <vz-icon
          no-masked-icon
          url="/svg/tg.svg"
          size="24"
          rounded
        />
        telegram
      </a>
      <a :href="okLink" target="_blank">
        <vz-icon
          no-masked-icon
          url="/svg/ok.svg"
          size="24"
          rounded
        />
        odnoklassniki
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useCityStore} from '~/stores/city'
import {okLink, tgLink, vkLink} from '@/api/constant-urls'
import VzButton from "~/components/ui-kit/vz-button.vue";

const {currentPhones, currentCity} = storeToRefs(useCityStore())

const contactsLink = computed(() => {
  return { name: 'contacts', params: { tag: 'address', location: currentCity.value?.translit } }
})

const phone = computed(() => {
  const data = currentPhones.value?.[0] || '7 (812) 389-21-12'

  const ind = data.indexOf(')')
  return ind > 0 ? `<span>${data.substring(0, ind + 1)}</span>${data.substring(ind + 1)}` : data
})
</script>
