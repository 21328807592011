// View Transitions API works only on /news/, /actions/, /wrapping/ pages
const NEWS_LIST = [/^\/news\/?/]
const ACTIONS_LIST = [/^\/actions\/?/]
const WRAPPING = [/^\/wrapping\/?/]

export default defineNuxtRouteMiddleware(async (to) => {
  if (
    NEWS_LIST.some((route) => route.test(to.fullPath)) || 
    ACTIONS_LIST.some((route) => route.test(to.fullPath)) ||
    WRAPPING.some((route) => route.test(to.fullPath))
  ) {
    return
  }

  to.meta.pageTransition = false
})