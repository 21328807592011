import {Country} from '~/types/types'
import {cookieOptions} from '~/common/functions';
import {Ref} from "vue";

export const useUsernameCookie = () => useCookie(cookieItems.username, cookieOptions()) as Ref<string|null>
export const useTokenCookie = () => useCookie(cookieItems.token, cookieOptions()) as Ref<string|null>

export const useHost = () => useState<string>('host', () => {
  const headers = useHeaders()
  return headers.value.host || ''
})

export const useHeaders = () => useState('headers', () => {
  return JSON.parse(JSON.stringify(useNuxtApp().ssrContext?.event?.node.req.headers)) || {}
})

export const useDomainCountry = () => useState<Country>('domain-country', () => {
  const host = useHost()

  if (host.value.includes('.kz')) {
    return 'KZ'
  }
  if (host.value.includes('.kg')) {
    return 'KG'
  }
  if (host.value.includes('.by')) {
    return 'BY'
  }
  if (host.value.includes('.am')) {
    return 'AM'
  }
  if ((host.value.includes('.ru') || host.value.includes('.localhost') || host.value.includes('.dev'))) {
    return 'RU'
  }

  return 'RU'
})