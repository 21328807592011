<template>
  <div class="public-header-control" :class="typeClass">
    <client-only>
      <vz-icon
        v-if="$breakpoints.width < 640 && !dark"
        url="/svg/vz-message-processing.svg"
        class="vz-color-mode vz-cursor-pointer svg-white"
        size="20"
        @click="openChat"
      />
    </client-only>
    <vz-color-mode
      v-if="!dark"
     />

    <client-only
      v-if="hasDiscount"
    >
      <a
        class="public-header-control-link public-header-control-discount"
        href="#"
        @click.prevent="emit('open-discount')"
      >
        <vz-icon
          :size="iconSize"
          name="percent-outline"
          class=""
        />
        <span>Скидки</span>
      </a>
    </client-only>
    <nuxt-link
      class="public-header-control-link"
      :to="{ name: 'order-create' }"
      no-prefetch
      @click="onCalcClick"
    >
      <vz-icon
        no-masked-icon
        :size="iconSize"
        url="/svg/calculator.svg"
        class="svg-white"
      />
      <span>Рассчитать</span>
    </nuxt-link>
    <nuxt-link
      class="public-header-control-link"
      :to="{ name: 'order-manage' }"
      no-prefetch
      @click="close"
    >
      <vz-icon
        no-masked-icon
        :size="iconSize"
        url="/svg/trace.svg"
      />
      <span>Отследить</span>
    </nuxt-link>
    <vz-button
      link
      :variant="variant"
      icon-name="phone"
      class="public-header-control-link pl-0"
      :icon-size="iconSize"
      :to="contactsLink"
      no-prefetch
      @click="close"
    >
      Контакты
    </vz-button>
  </div>
</template>

<script setup lang="ts">
import { useCityStore } from '~/stores/city';
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzButton from '~/components/ui-kit/vz-button.vue';
import $breakpoints from '~/composables/breakpoints'

const emit = defineEmits(['close', 'open-discount'])

const props = defineProps({
  dark: {
    type: Boolean,
    default: false
  },
  hasDiscount: {
    type: Boolean,
    default: false
  }
})

const { currentCity } = storeToRefs(useCityStore())

const contactsLink = computed(() => {
  return { name: 'contacts', params: { tag: 'address', location: currentCity.value?.translit } }
})

const typeClass = computed((): String => {
  return  props.dark ? 'public-header-control-dark': ''
})

const variant = computed((): String => {
  return  props.dark ? '': 'white'
})

const iconSize = computed((): Number => {
  return props.dark ? 36 : 16
})

function close(): void {
  emit('close')
}

function onCalcClick() {
  if (VK) {
    VK?.Retargeting.Event('click-order');
  }
  close()
}

function openChat() {
  console.log('openChat')
  window.postMessage({
    type: 'open-chat',
  }, '*')
}
</script>
