<template>
  <div class="public-header">
    <div class="public-header-additional" @mouseleave="showDiscount($event,false)">
      <div class="public-container flex flex-wrap flex-space-between flex-align-items-center">
        <public-header-region />
        <public-header-control
          :has-discount="isDiscountButtonShow"
          @close="setPublicHeaderMenuMobile(false)"
          @open-discount="openDiscount"
        />
      </div>
    </div>
    <div class="public-header-main">
      <div class="public-container public-container-mobile flex flex-space-between flex-align-items-center">
        <vz-logo variant="primary" class="mr-auto" />
        <public-header-menu
          :has-discount="isDiscountButtonShow"
          @open-discount="openDiscount"
        />
        <div />
        <div class="flex flex-align-items-center ml-auto">
<!--          <public-header-search />-->
          <public-header-button-personal @show-login="stopShowing" />
        </div>
        <vz-icon
          size="24"
          url="/svg/menu-mobile.svg"
          no-masked-icon
          class="public-header-button-mobile"
          @click="setPublicHeaderMenuMobile"
        />
      </div>
      <public-header-menu-mobile v-show="isShowMobile" @close="setPublicHeaderMenuMobile(false)" />
    </div>
    <client-only>
      <vz-public-discount-modal v-if="!isRegistration" />
      <vz-region-modal v-if="showModal.region" auto-show />
      <vz-request-document-modal v-if="showModal.documentRequest" auto-show />
      <vz-request-claim-modal v-if="showModal.claimRequest" auto-show />
    </client-only>
  </div>
</template>

<script setup lang="ts">
// import PublicHeaderSearch from '~~/components/public/header/public-header-search.vue'
import VzLogo from '@/components/ui-kit/vz-logo.vue'
import PublicHeaderRegion from '~~/components/public/header/public-header-region.vue'
import PublicHeaderMenu from '@/components/public/header/public-header-menu.vue'
import PublicHeaderButtonPersonal from '~~/components/public/header/public-header-button-personal.vue'
import PublicHeaderMenuMobile from '@/components/public/header/public-header-menu-mobile.vue'
import PublicHeaderControl from '~~/components/public/header/public-header-control.vue'
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import {defineAsyncComponent} from 'vue';
import VzPublicDiscountModal from "~/components/modals/vz-public-discount-modal.vue";
import {Timeout, vzDialogEmitData} from '~/types/types';
import {useDomainCountry} from "~/composables/states";
import {storeToRefs} from "pinia";
import {useUserStore} from "~/stores/personal/user";
import {emitter} from '~/common/event-bus';

const VzRegionModal = defineAsyncComponent(() => import('~/components/modals/vz-region-modal.vue'))
const VzRequestDocumentModal = defineAsyncComponent(() => import('~/components/modals/vz-request-document-modal.vue'))
const VzRequestClaimModal = defineAsyncComponent(() => import('~/components/modals/vz-request-claim-modal.vue'))

const { $showDialog } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const country = useDomainCountry()
const { wasLogged, isAuth } = storeToRefs(useUserStore())

const isRegistration = computed(() => {
  return router.currentRoute.value.name === 'registration'
})

let showDiscountTimeout: Timeout = null
let discountShowed = false
const isShowMobile = ref(false)
const showModal = ref({
  region: false,
  documentRequest: false,
  claimRequest: false,
})

const isDiscountButtonShow = computed(() => {
  return !wasLogged.value && route.name !== 'registration'
})

function setPublicHeaderMenuMobile(value = true): void {
  isShowMobile.value = value
}

function openDiscount() {
  emitter.emit('hide-all-dialog')
  $showDialog({ name: 'vz-public-discount-modal' })
}

function openModal({name, preset}: vzDialogEmitData) {
  if (name === 'region') {
    showModal.value.region = true
  } else if (name === 'vz-request-document-modal') {
    showModal.value.documentRequest = true
  } else if (name === 'vz-request-claim-modal') {
    showModal.value.claimRequest = true
  }
}

function showDiscount(event?: MouseEvent, byTimeout = true) {
  if (isAuth.value && !wasLogged.value) {
    wasLogged.value = '1'
  }
  if (!byTimeout && event?.y && event?.y > 0) {
    return
  }
  if (wasLogged.value || country.value !== 'RU' || discountShowed || route.name?.includes('order')) {
    return
  }

  if (showDiscountTimeout) {
    clearTimeout(showDiscountTimeout)
  }
  // if (!byTimeout) {
  // }
  discountShowed = true
  openDiscount()
}

function stopShowing() {
  if (showDiscountTimeout) {
    clearTimeout(showDiscountTimeout)
  }
}

onMounted(() => {
  showDiscountTimeout = setTimeout(showDiscount, 60 * 1000)
  emitter.on('show-dialog', openModal)
})

onBeforeUnmount(() => {
  emitter.off('show-dialog', openModal)
})
</script>
