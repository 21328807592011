import {defineStore} from 'pinia';

export const useLayoutStore = defineStore('layout', () => {
  const isOverlayShow = ref(false)
  const menuCollapsed = ref(false)
  const modalLoader = ref(false)
  const pageLoader = ref(false)
  const isEditFirstOpening = ref(false)
  const isEditAddressModal = ref(false)
  const isEditOrder = ref(false)
  const editFirstOpening = ref(false)

  function setCollapsedMenu(val: boolean) {
    menuCollapsed.value = val
  }

  function setIsOverlayShow(isShow: boolean) {
    isOverlayShow.value = isShow
  }

  function setPageloader(state: boolean): void {
    pageLoader.value = state
  }

  return {
    isEditOrder,
    isEditAddressModal,
    pageLoader,
    menuCollapsed,
    isOverlayShow,
    isEditFirstOpening,
    editFirstOpening,
    modalLoader,
    setPageloader,
    setCollapsedMenu,
    setIsOverlayShow
  }
})