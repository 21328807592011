import {defineNuxtPlugin} from '#app'
import {isDev, isDevServer, isTestServer} from '~/composables/useVzFetch';

export default defineNuxtPlugin({
  setup(nuxtApp) {
    let key = "VK-RTRG-1021356-alwGz"
    if (isDevServer || isTestServer) {
      // key = 'test'
      window.VK = null
      return {}
    }

    var vkApiScript = document.createElement('script');
    vkApiScript.type = 'text/javascript';
    vkApiScript.async = true;
    vkApiScript.src = 'https://vk.com/js/api/openapi.js?162';
    var sVkApiScript = document.getElementsByTagName('script')[0];
    sVkApiScript.parentNode?.insertBefore(vkApiScript, sVkApiScript);

    sVkApiScript.onload = function() {
      setTimeout(() => {
        if (typeof VK !== 'undefined') {
          VK.Retargeting.Init(key)
        } else {
          setTimeout(() => {
            if (VK) {
              VK?.Retargeting.Init(key)
            }
          }, 3500)
        }
      }, 400)
    }

    // Странно работает (в develop режиме не работает, в prod работает, но не в инкогнито)
    // window.onload = function () {
    //   VK.Retargeting.Init(key)
    // }

    return {}
  },
})
