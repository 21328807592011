import { ValidationCallback } from '~/types/types'

export const maxLength: ValidationCallback = (value: string, param = '', customMessage?: string) => {
  return !value || value.length <= Number(param) || (customMessage ? customMessage.replace(/\$/, param) : `Длина не должна превышать ${param} символов`)
}
export const minLength: ValidationCallback = (value: string, param = '', customMessage?: string) => {
  return !value || value.length >= Number(param) || (customMessage ? customMessage.replace(/\$/, param) : `Длина не должна быть меньше ${param} символов`)
}
export const equalLength: ValidationCallback = (value: string, param = '', customMessage?: string) => {
  return !value || value.length === Number(param) || (customMessage ? customMessage.replace(/\$/, param) : `Длина должна быть ${param} символов`)
}
