<template>
  <div v-if="false" class="public-header-search pointer flex flex-align-items-center" :class="searchClass">
    <div class="public-header-search-wrapper flex flex-align-items-center">
      <vz-icon
        no-masked-icon
        url="/svg/search.svg"
        size="18"
        class="public-header-search-loupe"
        @click="onSearchClick"
      />
      <vz-input
        v-model="searchField"
        placeholder="Поиск"
        max-length="100"
        @click="onInputClick"
        class="public-header-search-input"
        @keydown="keydown"
      />
      <vz-icon
        name="close"
        size="22"
        class="public-header-search-close"
        variant="lower"
        @click="onCloseClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {VzClass} from '~/types/types'
import $breackpoints from '~/composables/breakpoints'
import VzInput from "~/components/ui-kit/vz-input.vue";

const emit = defineEmits(['search', 'close'])
const isEditMode = ref(false)
const searchField = ref('')

onMounted(() => {
  onResize()
  window.addEventListener('resize', onResize)
})

onBeforeMount(() => {
  window.removeEventListener('resize', onResize)
})

const searchClass = computed((): VzClass => {
  return isEditMode.value ? 'edit-mode' : ''
})

const onInputClick = (): void => {
  if (!isEditMode.value) {
    toggleEditMode()
  }
}

const onSearchClick = (): void => {
  redirectToSearch()
}

const onCloseClick = (): void => {
  searchField.value = ''
  emit('close')
  toggleEditMode()
}

const toggleEditMode = (): void => {
  isEditMode.value = !isEditMode.value
}

function keydown(event: KeyboardEvent) {
  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
    redirectToSearch()
  }
}

function redirectToSearch() {
  const patch: { name: string, query?: {} } = {name: 'search'};
  if (searchField.value) {
    patch.query = {search: searchField.value};
  }

  useRouter().push(patch)
}

function onResize(): void {
  if ($breackpoints?.isPublicXS) {
    isEditMode.value = true
  }
}
</script>

