<template>
  <component
    :is="componentTag"
    class="vz-button-link"
    :class="classes"
    :disabled="disabled"
    v-bind="$attrs"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <vz-icon
      :inactive="disabled"
      :name="iconName"
      :size="size"
      :url="iconUrl"
    />
    <div v-show="hovered">
      <slot />
    </div>
  </component>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
import {PropType, resolveComponent} from 'vue'
import VzIcon from '~/components/ui-kit/vz-icon.vue'
import {useIconProps} from "~/common/ui-kit-icon";
import {ButtonLinkVariants, UIKitVariants} from "~/types/variants";

const props = defineProps({
  ...useIconProps,
  variant: {
    type: String as PropType<UIKitVariants>,
    default: ''
  },
  hoveredVariant: {
    type: String as PropType<ButtonLinkVariants>,
    default: 'primary'
  },
  size: {
    type: String,
    default: '14'
  },
  disabled: {
    type: Boolean,
    default: false
  },
})
const attr = useAttrs()

const hovered = ref(false)

const componentTag = computed(() => {
  if (attr.href) {
    return 'a'
  }
  if (attr.to) {
    return resolveComponent('NuxtLink')
  }
  return 'div'
})

const color = computed(() => {
  return hovered.value ? props.hoveredVariant : props.variant
})

const classes = computed(() => {
  return {
    [`color-${props.variant}`]: !hovered.value,
    [`color-${props.hoveredVariant}`]: hovered.value
  }
})
</script>

<style lang="scss" scoped>
.vz-button-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
