import {PropType} from 'vue'
import {InputType, ValidationRule} from "~/types/types"
import {UIKitVariants} from "~/types/variants";

export const useProps = {
  disabled: {
    type: Boolean,
    default: undefined
  },
  required: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  maxLength: {
    type: [Number, String],
    default: null
  },
  variant: {
    type: String as PropType<UIKitVariants>,
    default: ''
  },
  type: {
    type: String as PropType<InputType>,
    default: 'text'
  },
  name: {
    type: String,
    default: ''
  },
  small: {
    type: Boolean,
    default: false
  },
  big: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  labelPublicStyle: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  }
}

export const useProperty = () => ({
  _uid: getCurrentInstance()?.uid,
})
