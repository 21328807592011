import {FetchOptions} from 'ofetch';
import {useTokenCookie, useUsernameCookie} from '~/composables/states';

export const cookieItems = {
  city: '_vzd',
  token: '_vzta',
  wasLogged: '_voz',
  username: '_vzn',
  viewedEvents: '_vzvev',
  primaryRef: '_vzpref'
}

const isProd = import.meta.env.PROD
export const isDev = import.meta.env.DEV
export const isDevServer = import.meta.env.DEV || import.meta.env.VITE_ENV_DOMAIN === 'vozovoz.dev'
export const isTestServer = import.meta.env.VITE_ENV_DOMAIN === 'vozovoz.org'
const domainProd = 'vozovoz.ru'
const domainDev = isDev ? 'vozovoz.dev' : 'vozovoz.ru'

const domain = (import.meta.env?.VITE_ENV_DOMAIN as string) || (isProd ? domainProd : domainDev)
const protocol = (import.meta.env?.VITE_ENV_PROTOCOL as string) || (isProd ? 'https' : 'http')
const apiProtocol = import.meta.env?.VITE_ENV_API_PROTOCOL || 'https'

export const BASE_URL = `${protocol}://${domain}`
export const BASE_URL_HTTPS = `https://${domain}`
export const BASE_URL_API = `${apiProtocol}://api.${domain}`
export const BASE_DOMAIN = domain
export const BASE_DOMAIN_MAIN = domain
export const http = BASE_URL_API + '/v1'
export const httpNewApi = BASE_URL_API + '/v2'
export const httpApiV3 = BASE_URL_API + '/v3'

export const useVzFetch = <T>(url: string, options?: FetchOptions & { manual?: boolean }) => {
  const cookie = useTokenCookie()
  const config = useRuntimeConfig()
  const manual = options?.manual
  // console.log('!!!: ',cookie.value)
  if (cookie?.value) {
    const token = cookie.value
    options = {
      ...options,
      credentials: 'include',
      headers: {
        ...options?.headers,
        Authorization: `Bearer ${token}`
      }
    }
  }

  if (typeof options?.params === 'object') {
    options.params = serializeParams(options.params)
  }

  if (options && config?.public?.TEST && config?.public?.TEST_REQ?.includes(url)) {
    url = url.replace(BASE_URL_API, '/api/test')
    options.params = {
      ...(options.params || {}),
      testCase: config?.public?.TEST_CASE
    }
  }

  return $fetch<T>(url, {
    async onResponse({ request, response, options }) {
      if (!manual && response.ok && response?._data?.message && typeof response._data.message === 'string' && url.includes(httpApiV3)) {
        useNuxtApp().$showToast({
          title: response._data.message,
          keepAlive: true
        })
      }
    },
    async onResponseError({ request, response, options }) {
      if (response.status === 401 && process.client) {
        notAuthRedirect()
        return
      }
      const data = response?._data
      console.debug(response)
      if (url.includes(httpApiV3) && (data?.error?.message || data?.errors) && !manual) {
        useNuxtApp().$showToast({
          title: data.error?.message || Object.values(data.errors).join(','),
          variant: 'error',
          keepAlive: true
        })
      }
    },
    //
    // async onRequest({ request, options }) {
    //   console.log('[fetch request]')
    // },
    async onRequestError({ request, options, error }) {
      console.log('[fetch request error]', error)
      throw error
    },
    ...options as any,
  })
}

export function serializeParams(obj: any, prefix = "", result = {}) {
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const fullKey = prefix
      ? `${prefix}[${key}]` // isNaN(+key) ? : ''
      : key;
    const value = obj[key];

    (value !== null && typeof value === "object")
      ? serializeParams(value, fullKey, result)
      // @ts-ignore
      : result[fullKey] = `${String(value)}`
  }
  return result
}

export function notAuthRedirect(location = '/personal/auth/', options: Parameters<typeof navigateTo>[1] = undefined) {
  if (process.client) {
    const username = useUsernameCookie()
    const token = useTokenCookie()
    token.value = null
    username.value = null
  }
  return navigateTo(location, options)
}

export function getOrigin() {
  return process.client ? window.location.origin : getSeverOrigin()
}

export function getSeverOrigin(): string {
  return BASE_URL.replace(`://${BASE_DOMAIN_MAIN}`, `://${useNuxtApp().ssrContext?.event.node.req.headers.host}`)
}
