export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.env?.VITE_IS_HISTOIRE) {
    return;
  }

  if (to.path !== '/' && !to.path.endsWith('/')) {
    const { path, query, hash } = to;
    const nextPath = path + '/'
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute, { redirectCode: 301 });
  }
})
