import {
    OrderCargoMods, OrderCustomer, OrderDiagramGroup,
    OrderDirectionType,
    OrderForm,
    OrderPallet,
    OrderPalletToPrice,
    OrderParcel,
    OrderPlace,
    OrderPlaceToPrice,
    OrderService,
    OrderSpecialRequirement,
    OrderSpecialRequirementDataItem,
    OrderView,
    OrderWrappingItem, PhoneItem, SwitchItem, VzRadioGroupItem
} from '@/types/types'
import {orderFormV2ToFormV3} from '~/common/orderFormV2ToV3';
import {getCountryByPhone} from '~/common/functions';

export * from './timetable'

export enum daysNameFull {
    'воскресенье',
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота'
}

export const palletTypes = [
    { value: 'american', title: 'Американский', length: 1.2, width: 1.2, editable: false },
    { value: 'european', title: 'Европейский', length: 1.2, width: 0.8, editable: false },
    { value: 'nonStandard', title: 'Нестандартный', length: 1.2, width: 1.2, editable: true }
]

export const parcelTypes = [
    { value: 0, title: '40x20x20', volume: 0.02, length: 40, width: 20, height: 20 },
    { value: 1, title: '40x40x20', volume: 0.03, length: 40, width: 40, height: 20 },
    { value: 2, title: '40x40x40', volume: 0.06, length: 40, width: 40, height: 40 },
    { value: 3, title: '60x40x40', volume: 0.1, length: 60, width: 40, height: 40 }
]

export const specificLoadingParams = [
    'ramp110',
    'ramp120',
    'ramp130',
    'bodyHeight_2',
    'bodyHeight_2_1',
    'bodyHeight_2_2',
    'bodyHeight_2_3',
    'bodyHeight_2_4',
    'bodyHeight_2_5',
    'width210',
    'width220',
    'rearLoading',
    'pallets',
    'passportRF',
    'reflectiveVest',
    'closedClothes',
    'fixingStraps',
    'medicalCard',
    'emptyMachine',
    'sanitaryPassport'
]

export const orderTypes: SwitchItem[] = [
    {
        title: 'Индивидуальный заказ',
        value: 'individual'
    },
    {
        title: 'В торговые сети',
        value: 'networking'
    },
    {
        title: 'Мультизаявка',
        value: 'multi'
    },
]

export const contractorEntityList: SwitchItem[] = [
    {
        title: 'Физическое лицо',
        value: 'individual'
    },
    {
        title: 'Юридическое лицо',
        value: 'corporation'
    }
]

export const payerTypes: SwitchItem[] = [
    {
        title: 'Отправитель',
        value: 'dispatch'
    },
    {
        title: 'Получатель',
        value: 'destination'
    },
    {
        title: 'Третье лицо',
        value: 'third'
    },
    {
        title: 'Разделить',
        value: 'segregated'
    }
]

export const contractorTypes = [
    { title: 'Физическое лицо', value: 'individual' },
    { title: 'Юридическое лицо', value: 'corporation' }
]

export const phoneCountries: PhoneItem[] = [
    {
        value: 'RU',
        url: '/svg/vz-russia-flag.svg',
        phoneCode: '7',
        mask: '+# (###) ###-##-##',
        name: 'Россия',
        currency: '₽'
    },
    {
        value: 'BY',
        url: '/svg/vz-belarus-flag.svg',
        phoneCode: '375',
        mask: '+### (##) ###-##-##',
        name: 'Беларусь',
        about: { name: 'belarus' },
        currency: 'бел. руб'
    },
    {
        value: 'KZ',
        url: '/svg/vz-kz-flag.svg',
        phoneCode: '7',
        mask: '+# (###) ###-##-##',
        name: 'Казахстан',
        about: { name: 'kazakhstan' },
        currency: '₽'
    },
    // {
    //     value: 'KG',
    //     url: '/svg/vz-kg-flag.svg',
    //     phoneCode: '996',
    //     mask: '+### ### ######',
    //     name: 'Киргизия'
    // }
    {
        value: 'AM',
        url: '/svg/vz-am-flag.svg',
        phoneCode: '374',
        mask: '+### (##) ##-##-##',
        name: 'Армения',
        currency: '₽'
    },
]

export const paymentReceiptType: VzRadioGroupItem[] = [
    {
        title: 'На телефон (SMS)',
        value: 'check-sms'
    },
    {
        title: 'На почту',
        value: 'check-email'
    }
]

export const defaultOrder: OrderForm = {
    meta: {},
    clonedGuid: '',
    depositBonusFromDiscount: 0,
    linkedOrders: null,
    isVip: false,
    promocode: '',
    currency: 'RU',
    directions: {
        dispatch: {
            locationGuid: '',
            direction: 'address',
            address: '',
            terminalGuid: '',
            waitingTime: 30,
            calendar: {
                date: '',
                from: '',
                to: '',
                fixedTime: false,
                terminalTime: ''
            }
        },
        destination: {
            locationGuid: '',
            direction: 'address',
            address: '',
            terminalGuid: '',
            pickupPointGuid: '',
            waitingTime: 30,
            calendar: {
                date: '',
                from: '',
                to: '',
                fixedTime: false,
                terminalTime: ''
            }
        }
    },
    specialRequirements: {
        dispatch: {
            unloadingOperations: {
                active: false,
                floor: 1,
                freightElevator: false
            },
            driverComment: {
                active: false,
                value: ''
            },
            driverData: {
                active: false,
                value: ''
            },
            entryFee: {
                active: false,
                value: '',
                currency: ''
            },
            documentAddress: {
                active: false,
                value: ''
            },
            roadmap: {
                active: false,
                value: '',
                oldActive: false
            },
            bodyHeight_2: false,
            bodyHeight_2_1: false,
            bodyHeight_2_2: false,
            bodyHeight_2_3: false,
            bodyHeight_2_4: false,
            bodyHeight_2_5: false,
            ramp110: false,
            ramp120: false,
            ramp130: false,
            width210: false,
            width220: false,
            fixingStraps: false,
            passportRF: false,
            reflectiveVest: false,
            closedClothes: false,
            medicalCard: false,
            emptyMachine: false,
            pallets: false,
            rearLoading: false,
            sanitaryPassport: false,
            type: 'default'
        },
        destination: {
            unloadingOperations: {
                active: false,
                floor: 1,
                freightElevator: false
            },
            driverComment: {
                active: false,
                value: ''
            },
            driverData: {
                active: false,
                value: ''
            },
            entryFee: {
                active: false,
                value: '',
                currency: ''
            },
            documentAddress: {
                active: false,
                value: ''
            },
            roadmap: {
                active: false,
                value: '',
                oldActive: false
            },
            bodyHeight_2: false,
            bodyHeight_2_1: false,
            bodyHeight_2_2: false,
            bodyHeight_2_3: false,
            bodyHeight_2_4: false,
            bodyHeight_2_5: false,
            ramp110: false,
            ramp120: false,
            ramp130: false,
            width210: false,
            width220: false,
            fixingStraps: false,
            passportRF: false,
            reflectiveVest: false,
            closedClothes: false,
            medicalCard: false,
            emptyMachine: false,
            pallets: false,
            rearLoading: false,
            sanitaryPassport: false,
            type: 'default'
        }
    },
    cargo: {
        type: {
            correspondence: false,
            cargo: true
        },
        mode: 'dimension',
        dimensions: {
            detail: {
                height: 0.2,
                length: 0.2,
                width: 0.2,
                weight: 0.9
            },
            general: {
                place: null,
                weight: 0.9,
                volume: 0.01
            },
            wrapping: []
        },
        places: {
            items: [
                {
                    label: 'Груз 1',
                    length: 0.2,
                    width: 0.2,
                    height: 0.2,
                    weight: 0.9,
                    count: 1,
                    key: 0,
                    wrapping: []
                }
            ]
        },
        parcels: {
            items: [
                {
                    label: 'Груз 1',
                    type: 0,
                    weight: 0.9,
                    count: 1,
                    key: 0
                }
            ]
        },
        pallets: {
            items: [
                {
                    label: 'Груз 1',
                    type: 'american',
                    length: 1.2,
                    width: 1.2,
                    height: 0.2,
                    weight: 0.9,
                    count: 1,
                    key: 0,
                    wrapping: []
                }
            ]
        },
        category: null,
        insurance: {
            type: 'noDeclaredValue',
            value: 0
        },
        transportationId: '', // customId
        marketplaceGuid: null
    },
    additionalServices: {
        scanDocuments: false,
        scan: false,
        disassemble: {
            active: false,
            weight: 0
        },
        returning: {
            active: false,
            address: '',
            direction: 'address',
            locationGuid: 'e90f1820-0128-11e5-80c7-00155d903d03',
            terminalGuid: ''
        }
    },
    customer: {
        payer: 'destination',
        projectGuid: null,
        dispatch: {
            country: 'RU',
            canEditCOD: false,
            type: 'individual',
            networking: false,
            corporation: {
                contactList: [],
                IEC: '',
                taxId: '',
                companyName: {
                    id: '',
                    value: ''
                },
                email: '',
                name: '',
                phone: []
            },
            individual: {
                email: '',
                name: {
                    id: '',
                    value: ''
                },
                phone: []
            },
            sendCode: true
        },
        destination: {
            country: 'RU',
            canEditCOD: false,
            type: 'individual',
            networking: false,
            corporation: {
                contactList: [],
                IEC: '',
                taxId: '',
                companyName: {
                    id: '',
                    value: ''
                },
                email: '',
                name: '',
                phone: []
            },
            individual: {
                email: '',
                name: {
                    id: '',
                    value: ''
                },
                phone: []
            },
            sendCode: true
        },
        third: {
            country: 'RU',
            canEditCOD: false,
            type: 'individual',
            networking: false,
            corporation: {
                contactList: [],
                IEC: '',
                taxId: '',
                companyName: {
                    id: '',
                    value: ''
                },
                email: '',
                name: '',
                phone: []
            },
            individual: {
                email: '',
                name: {
                    id: '',
                    value: ''
                },
                phone: []
            }
        },
        segregated: {
            default: 'destination',
            list: {}
        }
    }
}

export const defaultOrderV3 = orderFormV2ToFormV3(defaultOrder)

export const fakeLocationText = 'Для выбранного вами направления расчёт стоимости временно недоступен.'

export const disabledKazakhstanText = `Перевозки из/в Республику Казахстан временно приостановлены. О возобновлении перевозок обязательно сообщим в новостях компании`

export const manualCalculationText = 'Для выбранного Вами направления расчет стоимости происходит вручную. Оставьте контакты для связи, мы с Вами свяжемся'

const editablePricePayload = (order: OrderForm): Record<string, unknown> => {
    const destinationPeriodSelectedStart = order.directions.destination.direction === 'address'
        ? order.directions.destination.calendar.from
        : order.directions.destination.calendar.terminalTime

    return {
        cloned: order.clonedGuid ? order.clonedGuid : false,
        currency: order.currency,
        customId: order.cargo.transportationId,
        destinationDateSelected: order?.meta?.destinationDateSelected || order.directions.destination.calendar.date,
        destinationPeriodSelectedEnd: order.directions.destination.calendar.to || order?.meta?.destinationPeriodSelectedEnd,
        destinationPeriodSelectedStart,
        destinationTerminalArrivalDateTime: order?.meta?.destinationTerminalArrivalDateTime || '',
        destinationTerminalArrivalGuid: order?.meta?.arrivalTerminalId || '',
        dispatchTaken: order?.meta?.taken || '',
        id: order?.meta?.guid,
        personal: true,
        phone: order?.meta?.phoneNumber || '',
        promocode: order?.meta?.promoCode || '',
        state: order?.meta?.state?.toString() || '0',
        test: false,
        utmList: [],
        vip: order?.meta?.vip
    }
}

const customerName = (customer: OrderCustomer): Record<string, unknown> => {
    if (customer.type === 'corporation') {
        return {
            value: customer.corporation.name
        }
    }
    return customer.individual.name
}

export const convertPriceServices = (data: any): OrderService[] => {
    try {
        const services: OrderService[] = []

        data.services?.forEach((service: any) => {
            if (service.cost) {
                if (service.cost.details) {
                    service.cost.details.forEach((detail: any) => {
                        services.push({
                            cost: detail.cost?.total,
                            guid: detail.id,
                            payer: 'destination',
                            title: detail.name,
                            discount: detail.cost?.discount,
                            disabled: Boolean(detail.depends_on),
                            dependence: detail.depends_on || [],
                            base: detail.cost?.base,
                            description: detail.description || ''
                        })
                    })
                }
            }
        })
        return services
    } catch (e) {
        console.log(e)
    }
    return []
}

export const convertScheduller = (rawData: any): OrderDiagramGroup[] => {
    if (!rawData) {
        return []
    }
    const keys = Object.keys(rawData)
    const groups: OrderDiagramGroup[] = []
    keys.forEach((group) => {
        groups.push({
            groupTime: group,
            items: rawData[group].map((item: any, index: any) => {
                return {
                    date: item.date,
                    locationName: item.location.name,
                    state: item.state,
                    type: item.type,
                    groups: index === 0 ? keys : undefined,
                    map: item.map
                }
            })
        })
    })
    return groups
}

const dimensionAndNumericWrappingToPrice = (orderform: OrderForm): any => {
    const mode = orderform.cargo.mode
    const isDimensionMode = (mode === 'dimension')
    let objWrapping: OrderWrappingItem[] = []
    let wrapping

    if (mode === 'parcels') {
        return {}
    } else if (isDimensionMode) {
        objWrapping = orderform.cargo.dimensions.wrapping
    } else {
        objWrapping = orderform.cargo[mode].items[0]?.wrapping
    }

    if (objWrapping) {
        const bag1 = objWrapping.find(i => i.code === 'bag1')
        const bag2 = objWrapping.find(i => i.code === 'bag2')
        const box1 = objWrapping.find(i => i.code === 'box1')
        const box2 = objWrapping.find(i => i.code === 'box2')
        const box3 = objWrapping.find(i => i.code === 'box3')
        const box4 = objWrapping.find(i => i.code === 'box4')
        const safePackage = objWrapping.find(i => i.code === 'safePackage')
        wrapping = {
            palletCollar: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletCollar')),
                name: 'Паллетный борт',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletCustomer: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletCustomer')),
                name: 'Постановка на паллет',
                blocked: true,
                disabled: false,
                value: 1,
                mod: {
                    condition: {
                        cargo: {
                            dimension: {
                                detail: {
                                    weight: {
                                        '>=': 250
                                    }
                                }
                            }
                        }
                    },
                    message: 'Воспользуйтесь упаковкой «Постановка на паллет», если Ваш груз не располагается на паллете. Для грузов с весом одного места больше 250 кг данная услуга обязательна!'
                }
            },
            hardPackageVolumeUOD: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'hardPackageVolumeUOD')),
                name: 'Защитная жёсткая упаковка с разбором',
                blocked: false,
                disabled: false,
                value: 1
            },
            hardPackageVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'hardPackageVolume')),
                name: 'Защитная жёсткая упаковка без разбора',
                blocked: false,
                disabled: false,
                value: 1
            },
            hardPackageVolume_WP: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'hardPackageVolume_WP')),
                name: 'Защитная жёсткая упаковка (+ фото) без разбора',
                blocked: false,
                disabled: false,
                value: 1
            },
            hardPackageVolumeUOD_WP: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'hardPackageVolumeUOD_WP')),
                name: 'Защитная жёсткая упаковка (+ фото) с разбором',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingExtraPackageVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingExtraPackageVolume')),
                name: 'Паллетирование Прозрачный стрейч',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingExtraBlackPackageVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingExtraBlackPackageVolume')),
                name: 'Паллетирование Чёрный стрейч',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingBubbleFilmVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingBubbleFilmVolume')),
                name: 'Паллетирование Воздушно-пузырьковая плёнка',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingCardboardVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingCardboardVolume')),
                name: 'Паллетирование Картон',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingExtraPackageVolumeOD: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingExtraPackageVolumeOD')),
                name: 'Паллетирование на терминале выдачи Прозрачный стрейч',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingExtraBlackPackageVolumeOD: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingExtraBlackPackageVolumeOD')),
                name: 'Паллетирование на терминале выдачи Чёрный стрейч',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingBubbleFilmVolumeOD: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingBubbleFilmVolumeOD')),
                name: 'Паллетирование на терминале выдачи Воздушно-пузырьковая плёнка',
                blocked: false,
                disabled: false,
                value: 1
            },
            palletizingCardboardVolumeOD: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'palletizingCardboardVolumeOD')),
                name: 'Паллетирование на терминале выдачи Картон',
                blocked: false,
                disabled: false,
                value: 1
            },
            extraPackageVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'extraPackageVolume')),
                name: 'Дополнительная упаковка Прозрачная плёнка',
                blocked: false,
                disabled: false,
                value: 1
            },
            extraBlackPackageVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'extraBlackPackageVolume')),
                name: 'Дополнительная упаковка Чёрная плёнка',
                blocked: false,
                disabled: false,
                value: 1
            },
            bubbleFilmVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'bubbleFilmVolume')),
                name: 'Дополнительная упаковка Воздушно-пузырьковая пленка',
                blocked: false,
                disabled: false,
                value: 1
            },
            cardboardVolume: {
                used: Boolean(isDimensionMode && objWrapping.find(i => i.code === 'cardboardVolume')),
                name: 'Дополнительная упаковка Картон',
                blocked: false,
                disabled: false,
                value: 1
            },
            box1: {
                used: Boolean(box1),
                name: 'Коробка 40×20×20 см',
                blocked: false,
                disabled: false,
                value: box1 ? Number(box1?.value) : 1
            },
            box2: {
                used: Boolean(box2),
                name: 'Коробка 40×40×20 см',
                blocked: false,
                disabled: false,
                value: box2 ? Number(box2?.value) : 1
            },
            box3: {
                used: Boolean(box3),
                name: 'Коробка 40×40×40 см',
                blocked: false,
                disabled: false,
                value: box3 ? Number(box3?.value) : 1
            },
            box4: {
                used: Boolean(box4),
                name: 'Коробка 60×40×40 см',
                blocked: false,
                disabled: false,
                value: box4 ? Number(box4?.value) : 1
            },
            bag1: {
                used: Boolean(bag1),
                name: 'Мешок 55×105 см',
                blocked: false,
                disabled: false,
                value: bag1 ? Number(bag1?.value) : 1
            },
            bag2: {
                used: Boolean(bag2),
                name: 'Мешок 70×120 см',
                blocked: false,
                disabled: false,
                value: bag2 ? Number(bag2?.value) : 1
            },
            safePackage: {
                used: Boolean(safePackage),
                name: 'Упаковка в сейф-пакет',
                blocked: false,
                disabled: false,
                value: safePackage ? Number(safePackage?.value) : 1
            }
        }
    }
    return objWrapping && wrapping ? wrapping : {}
}

const dimensionWrappingToForm = (data: any): any => {
    return Object.keys(data).map((key) => { return { code: key, value: data[key] } })
}

const itemsWrappingToPrice = (data: OrderWrappingItem[]): string[] => {
    const numericWrapping = ['bag1', 'bag2', 'box1', 'box2', 'box3', 'box4', 'safePackage']
    const wrapping: string[] = []
    Object.values(data).forEach((i) => {
        if (!numericWrapping.includes(i.code)) {
            wrapping.push(i.code)
        }
    })
    return wrapping
}

const itemsWrappingToForm = (data: string[], numericWrapping: any): OrderWrappingItem[] => {
    const wrapping: OrderWrappingItem[] = []
    Object.values(data || []).forEach((i) => {
        wrapping.push({ code: i, value: 0 })
    })
    // штучную упаковку, если она есть, добавляем всем местам/паллетам
    if (numericWrapping) {
        Object.keys(numericWrapping).forEach((i) => {
            wrapping.push({ code: i, value: numericWrapping[i] })
        })
    }
    return wrapping
}

const placesItemsToPrice = (items: OrderPlace[]): OrderPlaceToPrice[] => {
    const data: OrderPlaceToPrice[] = []
    let itemData: OrderPlaceToPrice = {} as OrderPlaceToPrice
    items.forEach((item) => {
        const { length, width, height, count, weight, wrapping, label } = item
        itemData = {
            length,
            width,
            height,
            quantity: count,
            weight,
            label,
            wrapping: itemsWrappingToPrice(wrapping)
        }
        data.push(itemData)
    })
    return data
}

export const generalVolumeToPrice = (orderForm: OrderForm): number => {
    let volume = 0
    const minVolume = 0.01
    const mode = orderForm.cargo.mode

    if (mode === 'dimension') {
        volume = orderForm.cargo.dimensions.general.volume
    } else if (mode === 'pallets' || mode === 'places') {
        volume = Number((orderForm.cargo[mode].items as OrderPallet[] & OrderPlace[]).reduce((preVal, item) => {
            preVal += (item.length * item.width * item.height * item.count)
            return preVal
        }, 0).toFixed(2))
    } else {
        volume = Number((orderForm.cargo[mode].items as OrderParcel[]).reduce((preVal, item) => {
            preVal += parcelTypes[item.type].volume * parseInt(item.count.toString())
            return preVal
        }, 0).toFixed(2))
    }
    volume = (volume < minVolume) ? minVolume : volume
    return +volume
}

export const generalWeightToPrice = (orderForm: OrderForm): number => {
    let weight = 0
    const mode = orderForm.cargo.mode

    if (mode === 'dimension') {
        weight = orderForm.cargo.dimensions.general.weight
    } else {
        weight = Number((orderForm.cargo[mode].items as (OrderParcel[] & OrderPallet[] & OrderPlace[])).reduce(function (preVal, item) {
            preVal += parseFloat(item.weight.toString()) * parseInt(item.count.toString())
            return preVal
        }, 0).toFixed(1))
    }
    return +weight
}

export const generalCountToPrice = (orderForm: OrderForm): number => {
    let count = 0
    const mode = orderForm.cargo.mode

    if (mode === 'dimension') {
        count = orderForm.cargo.dimensions.general.place
    } else {
        count = (orderForm.cargo[mode].items as (OrderParcel[] & OrderPallet[] & OrderPlace[])).reduce((preVal, item) => {
            preVal += parseInt(item.count.toString())
            return preVal
        }, 0)
    }
    return count
}

const placesItemsToForm = (items: OrderPlaceToPrice[], numericWrapping: any): OrderPlace[] => {
    const data: OrderPlace[] = []
    let itemData: OrderPlace = {} as OrderPlace
    items.forEach((item, index) => {
        const { length, width, height, quantity, weight, label } = item
        itemData = {
            length,
            width,
            height,
            weight,
            count: quantity,
            label,
            key: index,
            wrapping: itemsWrappingToForm(item.wrapping, numericWrapping)
        }
        data.push(itemData)
    })
    return data
}

const palletsItemsToPrice = (items: OrderPallet[]): OrderPalletToPrice[] => {
    const data: OrderPalletToPrice[] = []
    let itemData: OrderPalletToPrice = {} as OrderPalletToPrice
    items.forEach((item) => {
        const { length, width, height, count, weight, type, wrapping, label } = item
        itemData = {
            length,
            width,
            height,
            quantity: count,
            weight,
            label,
            type,
            wrapping: itemsWrappingToPrice(wrapping)
        }
        data.push(itemData)
    })
    return data
}

const palletsItemsToForm = (items: OrderPalletToPrice[], numericWrapping: any): OrderPallet[] => {
    const data: OrderPallet[] = []
    let itemData: OrderPallet = {} as OrderPallet
    items.forEach((item, index) => {
        const { length, width, height, quantity, weight, type, label } = item
        itemData = {
            length,
            width,
            height,
            count: quantity,
            weight,
            type,
            label,
            key: index,
            wrapping: itemsWrappingToForm(item.wrapping, numericWrapping)
        }
        data.push(itemData)
    })
    return data
}

function specificLoadingToPrice(specificLoading: OrderSpecialRequirement) {
    const data: string[] = []
    Object.entries(specificLoading).forEach(([key, value]) => {
        if (value === true) {
            data.push(key)
        }
    })
    if (specificLoading.type !== 'default') {
        data.push(specificLoading.type)
    }
    return data as Array<keyof OrderSpecialRequirement>
}

const hasRoadmapToPrice = (roadmap: OrderSpecialRequirementDataItem & { oldActive: boolean } ): any => {
    if (!roadmap?.oldActive) {
        return !!roadmap?.value?.length
    }
    return roadmap.active
}

const specificLoadingTypeFromOrder = (data: string[]): string => {
    const types = ['lateralLoad', 'topLoad', 'tailLift', 'manipulator', 'openMachine', 'removableCurtains']
    const result = types.filter(i => data.includes(i))
    return result && result.length > 0 ? result[0] : 'default'
}

export const pricePayload = (orderform: OrderForm, terminalOnly?: { dispatch?: boolean, destination?: boolean }): any => {
    const specificLoadingDestination = specificLoadingToPrice(orderform.specialRequirements.destination)
    const specificLoadingDispatch = specificLoadingToPrice(orderform.specialRequirements.dispatch)
    const hasRoadmapDispatch = hasRoadmapToPrice(orderform.specialRequirements.dispatch.roadmap)
    const hasRoadmapDestination = hasRoadmapToPrice(orderform.specialRequirements.destination.roadmap)

    let obj: any = {
        cloned: orderform.clonedGuid ? orderform.clonedGuid : false,
        depositBonusFromDiscount: orderform.depositBonusFromDiscount,
        additional: {
            scannedConsignationNote: orderform.additionalServices.scan,
            retrieveAD: {
                used: orderform.additionalServices.returning.active,
                scanned: orderform.additionalServices.scanDocuments,
                location: {
                    id: orderform.additionalServices.returning.locationGuid
                },
                point: {
                    type: orderform.additionalServices.returning.direction,
                    address: {
                        id: orderform.additionalServices.returning.address,
                        value: orderform.additionalServices.returning.address
                    },
                    terminal: {
                        id: orderform.additionalServices.returning.terminalGuid
                    }
                }
            },
            specificLoading: {
                used: (specificLoadingDispatch?.length > 0) || (specificLoadingDestination?.length > 0),
                destination: specificLoadingDestination,
                dispatch: specificLoadingDispatch
            },
            unboxingOD: {
                used: orderform.additionalServices.disassemble.active,
                weight: Number(orderform.additionalServices.disassemble.weight)
            }
        },
        cargo: {
            mode: (orderform.cargo.mode === 'places' || orderform.cargo.mode === 'dimension') ? 'dimension' : orderform.cargo.mode.slice(0, orderform.cargo.mode.length - 1),
            wizardUsed: orderform.cargo.mode === 'places',
            promocode: orderform.promocode,
            // customId: orderform.cargo.transportationId,
            type: {
                cargo: orderform.cargo.type.cargo,
                correspondence: orderform.cargo.type.correspondence
            },
            category: {
                id: orderform.cargo.category
            },
            insurance: {
                insurance: orderform.cargo.insurance.value,
                used: orderform.cargo.insurance.type === 'cargoInsurance'
            },
            insurance_ndv: {
                insurance_ndv: orderform.cargo.insurance.type === 'noDeclaredValue'
            },
            dimension: {
                general: {
                    place: generalCountToPrice(orderform),
                    volume: generalVolumeToPrice(orderform),
                    weight: generalWeightToPrice(orderform)
                },
                detail: {
                    height: orderform.cargo.dimensions.detail.height,
                    length: orderform.cargo.dimensions.detail.length,
                    weight: orderform.cargo.dimensions.detail.weight,
                    width: orderform.cargo.dimensions.detail.width
                }
            },
            wizard: orderform.cargo.mode === 'places' ? placesItemsToPrice(orderform.cargo.places.items) : [],
            pallet: {
                general: {
                    place: orderform.cargo.mode === 'pallets' ? generalCountToPrice(orderform) : 1,
                    volume: orderform.cargo.mode === 'pallets' ? generalVolumeToPrice(orderform) : 0.19,
                    weight: orderform.cargo.mode === 'pallets' ? generalWeightToPrice(orderform) : 0.9
                },
                wizard: orderform.cargo.mode === 'pallets' ? palletsItemsToPrice(orderform.cargo.pallets.items) : []
            },
            parcel: orderform.cargo.mode === 'parcels' ? orderform.cargo.parcels.items : [],
            wrapping: dimensionAndNumericWrappingToPrice(orderform)
        },
        customer: {
            payer: orderform.customer.payer,
            destination: {
                type: orderform.customer.destination.type,
                networking: orderform.customer?.destination?.networking || false,
                [orderform.customer.destination.type]: {
                    ...orderform.customer.destination[orderform.customer.destination.type],
                    sendCode: orderform.customer.destination.sendCode,
                    name: customerName(orderform.customer.destination)
                }
            },
            dispatch: {
                type: orderform.customer.dispatch.type,
                [orderform.customer.dispatch.type]: {
                    ...orderform.customer.dispatch[orderform.customer.dispatch.type],
                    sendCode: orderform.customer.dispatch.sendCode,
                    name: customerName(orderform.customer.dispatch)
                }
            },
            third: {
                type: orderform.customer.third.type,
                [orderform.customer.third.type]: {
                    ...orderform.customer.third[orderform.customer.third.type],
                    sendCode: orderform.customer.third.sendCode,
                    name: customerName(orderform.customer.third)
                }
            },
            segregated: orderform.customer.segregated
        },
        gateway: {
            dispatch: {
                location: {
                    id: orderform.directions.dispatch.locationGuid
                },
                point: {
                    type: orderform.directions.dispatch.direction,
                    address: {
                        driverComment: orderform.specialRequirements.dispatch.driverComment.active ? orderform.specialRequirements.dispatch.driverComment.value : '',
                        driverDataEmail: orderform.specialRequirements.dispatch.driverData.active ? orderform.specialRequirements.dispatch.driverData.value : '',
                        driverPass: orderform.specialRequirements.dispatch.driverData.pass,
                        entryFee: orderform.specialRequirements.dispatch.entryFee.active && orderform.specialRequirements.dispatch.entryFee.value ? {
                            value: orderform.specialRequirements.dispatch.entryFee.value,
                            currency: orderform.specialRequirements.dispatch.entryFee.currency,
                        } : null,
                        hasRoadmap: hasRoadmapDispatch,
                        roadmapFile: orderform.specialRequirements.dispatch.roadmap.value,
                        waitingTime: orderform.directions.dispatch.waitingTime,
                        address: [orderform.directions.dispatch.address, orderform.specialRequirements.dispatch.documentAddress.active ? orderform.specialRequirements.dispatch.documentAddress.value : ''],
                        region: orderform.directions.dispatch.region,
                        networking: false,
                        'hint-kladr': {
                            byHint: false,
                            byHintNoKladr: false
                        },
                        needLoading: {
                            fixTime: false,
                            floor: orderform.specialRequirements.dispatch.unloadingOperations.floor,
                            lift: orderform.specialRequirements.dispatch.unloadingOperations.freightElevator,
                            used: orderform.specialRequirements.dispatch.unloadingOperations.active
                        },
                        shippingTerm: {
                            date_submit: orderform.directions.dispatch.calendar.date,
                            fixTime: orderform.directions.dispatch.calendar.fixedTime,
                            time: {
                                end: orderform.directions.dispatch.calendar.to,
                                start: orderform.directions.dispatch.calendar.from
                            }
                        }
                    },
                    terminal: {
                        terminal: {
                            id: orderform.directions.dispatch.terminalGuid
                        },
                        shippingTerm: {
                            date_submit: orderform.directions.dispatch.calendar.date,
                            time: orderform.directions.dispatch.calendar.terminalTime
                        }
                    }
                },
                terminalOnly: terminalOnly?.dispatch
            },
            destination: {
                location: {
                    id: orderform.directions.destination.locationGuid
                },
                point: {
                    type: orderform.directions.destination.direction,
                    address: {
                        driverComment: orderform.specialRequirements.destination.driverComment.active ? orderform.specialRequirements.destination.driverComment.value : '',
                        driverDataEmail: orderform.specialRequirements.destination.driverData.active ? orderform.specialRequirements.destination.driverData.value : '',
                        driverPass: orderform.specialRequirements.destination.driverData.pass,
                        entryFee: orderform.specialRequirements.destination.entryFee.active && orderform.specialRequirements.destination.entryFee.value ? {
                            value: orderform.specialRequirements.destination.entryFee.value,
                            currency: orderform.specialRequirements.destination.entryFee.currency,
                        } : null,
                        hasRoadmap: hasRoadmapDestination,
                        roadmapFile: orderform.specialRequirements.destination.roadmap.value,
                        waitingTime: orderform.directions.destination.waitingTime,
                        address: [orderform.directions.destination.address, orderform.specialRequirements.destination.documentAddress.active ? orderform.specialRequirements.destination.documentAddress.value : ''],
                        region: orderform.directions.destination.region,
                        networking: orderform.customer?.destination?.networking
                            ? orderform.directions.destination.networkAddressGuid || orderform.directions.destination.address
                            : undefined,
                        'hint-kladr': {
                            byHint: false,
                            byHintNoKladr: false
                        },
                        needLoading: {
                            fixTime: false,
                            floor: orderform.specialRequirements.destination.unloadingOperations.floor,
                            lift: orderform.specialRequirements.destination.unloadingOperations.freightElevator,
                            used: orderform.specialRequirements.destination.unloadingOperations.active
                        },
                        shippingTerm: {
                            date_submit: orderform.directions.destination.calendar?.date,
                            fixTime: orderform.directions.destination.calendar?.fixedTime,
                            time: {
                                end: orderform.directions.destination.calendar?.to,
                                start: orderform.directions.destination.calendar?.from
                            }
                        }
                    },
                    terminal: {
                        terminal: {
                            id: orderform.directions.destination.terminalGuid
                        },
                        shippingTerm: {
                            date_submit: orderform.directions.destination.calendar?.date,
                            time: orderform.directions.destination.calendar?.terminalTime
                        }
                    },
                    pickupPoint: {
                        pickupPoint: {
                            id: orderform.directions.destination.pickupPointGuid
                        },
                        shippingTerm: {
                            date_submit: orderform.directions.destination.calendar?.date,
                            time: orderform.directions.destination.calendar?.terminalTime
                        }
                    }
                },
                terminalOnly: terminalOnly?.destination
            }
        },
        vip: orderform.isVip,
        utmList: [],
        promocode: orderform.promocode,
        personal: true,
        currency: orderform.currency,
        customId: orderform.cargo.transportationId,
        marketplaceGuid: orderform.cargo.marketplaceGuid,
        projectGuid: orderform.customer.projectGuid,
        isNetwork: orderform.isNetwork ? orderform.directions.destination.networkGuid : undefined,
        test: false,
        fullService: orderform.fullService,
        context: orderform.context,
        checkCargoBeforeShipping: orderform.meta.checkCargoBeforeShipping,
        additionInfo: orderform.meta.additionInfo,
        unboxingOnDelivery: orderform.meta.unboxingOnDelivery
    };

    // Удаляем узлы address/terminal/pickupPoint если они не используются
    function removeExtraDirectionData(direction: OrderDirectionType, source: any) {
        for (let key in source) {
            if (key !== 'type' && key !== direction) {
                delete source[key]
            }
        }
    }
    removeExtraDirectionData(orderform.directions.dispatch.direction, obj.gateway.dispatch.point)
    removeExtraDirectionData(orderform.directions.destination.direction, obj.gateway.destination.point)
    removeExtraDirectionData(orderform.additionalServices.returning.direction, obj.additional.retrieveAD.point)

    if (!orderform.fullService) {
        delete obj.fullService
    }

    if (orderform?.meta?.guid) {
        obj = Object.assign({}, obj, editablePricePayload(orderform))
    }

    return obj
}

export const priceDataToOrderForm = (priceData: any) => {
    const {
        additional,
        gateway: { destination, dispatch },
        cargo,
        cost,
        customer,
        meta
    } = priceData

    const cargoMode = (cargo?.mode === 'dimension' ? (cargo?.wizardUsed ? 'places' : 'dimension') : `${cargo?.mode}s`) as OrderCargoMods
    const currencyList = cost?.currency?.list || cost?.currency

    const orderForm: OrderForm = {
        linkedOrders: meta.linkedOrderList,
        meta: {
            ...priceData.meta,
            destinationDateSelected: destination?.point?.terminal?.shippingTerm?.date_submit || destination?.point?.address?.shippingTerm?.date_submit,
            destinationPeriodSelectedEnd: destination?.point?.terminal?.shippingTerm.time?.end || destination?.point?.address?.shippingTerm.time?.end || '',
            destinationPeriodSelectedStart: destination?.point?.terminal?.shippingTerm.time?.start || destination?.point?.address?.shippingTerm.time?.start || '',
            isNetwork: meta.isNetwork,
            customId: meta.customId,
            promoCode: meta.promoCode,
            additionInfo: meta.additionInfo,
            vip: meta.vip
        } || null,
        directions: {
            destination: {
                address: destination?.point?.address?.address?.[0] || '',
                calendar: {
                    date: destination?.point?.[destination.point.type]?.shippingTerm?.date_submit,
                    fixedTime: destination?.point?.address?.shippingTerm?.fixTime || false,
                    from: destination?.point?.address?.shippingTerm?.time?.start || '',
                    terminalTime: destination?.point?.[destination.point.type]?.shippingTerm?.time?.start
                        || destination?.point?.[destination.point.type]?.shippingTerm?.time || '',
                    to: destination?.point?.address?.shippingTerm.time?.end || ''
                },
                direction: destination.point.type || '',
                locationGuid: destination.location.id || '',
                terminalGuid: destination?.point?.terminal?.terminal?.id || '',
                pickupPointGuid: destination?.point?.pickupPoint?.pickupPoint?.id || '',
                waitingTime: destination?.point?.address?.waitingTime || 30,
                networkAddressGuid: destination?.point?.address?.guid,
                networkGuid: destination?.point?.address?.networkGuid
            },
            dispatch: {
                address: dispatch?.point?.address?.address?.[0] || '',
                calendar: {
                    date: dispatch?.point?.[dispatch.point.type]?.shippingTerm?.date_submit,
                    fixedTime: dispatch?.point?.address?.shippingTerm?.fixTime || false,
                    from: dispatch?.point?.address?.shippingTerm?.time.start || '',
                    terminalTime: dispatch?.point?.terminal?.shippingTerm?.time?.start || dispatch?.point?.terminal?.shippingTerm?.time || '',
                    to: dispatch?.point?.address?.shippingTerm?.time?.end || ''
                },
                direction: dispatch?.point?.type || '',
                locationGuid: dispatch?.location?.id || '',
                terminalGuid: dispatch?.point?.terminal?.terminal?.id || '',
                waitingTime: dispatch?.point?.address?.waitingTime || 30
            }
        },
        customer: {
            payer: customer.payer,
            projectGuid: meta.projectGuid || null,
            segregated: {
                default: 'destination',
                list: {}
            },
            dispatch: {
                country: customer?.dispatch?.country || 'RU',
                canEditCOD: false,
                corporation: {
                    IEC: customer?.dispatch?.corporation?.IEC || '',
                    companyName: {
                        id: customer?.dispatch?.corporation?.companyName.id || '',
                        value: customer?.dispatch?.corporation?.companyName.value || ''
                    },
                    email: customer?.dispatch?.corporation?.email || '',
                    name: customer?.dispatch?.corporation?.name || '',
                    phone: customer?.dispatch?.corporation?.phone || [],
                    taxId: customer?.dispatch?.corporation?.taxId || '',
                    hasEdo: customer?.dispatch?.corporation?.hasEWorkflow
                },
                individual: {
                    email: customer?.dispatch?.individual?.email || '',
                    name: {
                        id: customer?.dispatch?.individual?.name.id || '',
                        value: customer?.dispatch?.individual?.name.value || ''
                    },
                    phone: customer?.dispatch?.individual?.phone || []
                },
                networking: customer?.dispatch?.networking || false,
                sendCode: customer?.dispatch?.sendCode || false,
                type: customer?.dispatch?.type || 'individual'
            },
            destination: {
                country: customer?.destination?.country || 'RU',
                canEditCOD: false,
                corporation: {
                    IEC: customer?.destination?.corporation?.IEC || '',
                    companyName: {
                        id: customer?.destination?.corporation?.companyName.id,
                        value: customer?.destination?.corporation?.companyName.value || ''
                    },
                    email: customer?.destination?.corporation?.email || '',
                    name: customer?.destination?.corporation?.name.value || customer?.destination?.corporation?.name,
                    phone: customer?.destination?.corporation?.phone || [],
                    taxId: customer?.destination?.corporation?.taxId || '',
                    hasEdo: customer?.destination?.corporation?.hasEWorkflow
                },
                individual: {
                    email: customer?.destination?.individual?.email || '',
                    name: {
                        id: customer?.destination?.individual?.id || '',
                        value: customer?.destination?.individual?.name?.value || ''
                    },
                    phone: customer?.destination?.individual?.phone || []
                },
                sendCode: customer?.destination?.sendCode || false,
                networking: customer?.destination?.networking || false,
                type: customer?.destination?.type || 'corporation'
            },
            third: {
                country: customer?.third?.country || 'RU',
                canEditCOD: false,
                corporation: {
                    IEC: customer?.third?.corporation?.IEC || '',
                    companyName: {
                        id: customer?.third?.corporation?.companyName.id,
                        value: customer?.third?.corporation?.companyName.value || ''
                    },
                    email: customer?.third?.corporation?.email || '',
                    name: customer?.third?.corporation?.name || '',
                    phone: customer?.third?.corporation?.phone || [],
                    taxId: customer?.third?.corporation?.taxId || ''
                },
                individual: {
                    email: customer?.third?.individual?.email || '',
                    name: {
                        id: customer?.third?.individual?.name.id || '',
                        value: customer?.third?.individual?.name.value || ''
                    },
                    phone: customer?.third?.individual?.phone || []
                },
                sendCode: customer?.third?.sendCode || false,
                networking: customer?.third?.networking || false,
                type: customer?.third?.type || 'corporation'
            }
        },
        specialRequirements: {
            dispatch: {
                unloadingOperations: {
                    active: dispatch?.point?.address?.needLoading?.used || false,
                    floor: dispatch?.point?.address?.needLoading?.floor || 1,
                    freightElevator: dispatch?.point?.address?.needLoading?.lift || false
                },
                entryFee: {
                    active: dispatch?.point?.address?.entryFee?.value || false,
                    value: dispatch?.point?.address?.entryFee?.value || '',
                    currency: dispatch?.point?.address?.entryFee?.currency || ''
                },
                driverComment: {
                    active: !!dispatch?.point?.address?.driverComment,
                    value: dispatch?.point?.address?.driverComment || ''
                },
                driverData: {
                    active: !!dispatch?.point?.address?.driverDataEmail,
                    value: dispatch?.point?.address?.driverDataEmail || '',
                    pass: dispatch?.point?.address?.driverPass,
                    passNote: dispatch?.point?.address?.driverPassNote,
                },
                roadmap: {
                    active: dispatch?.point?.address?.hasRoadmap,
                    value: '',
                    oldActive: dispatch?.point?.address?.hasRoadmap
                },
                documentAddress: {
                    active: !!dispatch?.point?.address?.address?.[1],
                    value: dispatch?.point?.address?.address?.[1] || ''
                },
                ...(Object.fromEntries(specificLoadingParams.map(v => [v, Boolean(additional.specificLoading.dispatch.includes(v))]))),
                type: specificLoadingTypeFromOrder(additional.specificLoading.dispatch)
            },
            destination: {
                unloadingOperations: {
                    active: destination?.point?.address?.needLoading?.used || false,
                    floor: destination?.point?.address?.needLoading?.floor || 1,
                    freightElevator: destination?.point?.address?.needLoading?.lift || false
                },

                entryFee: {
                    active: destination?.point?.address?.entryFee?.value || false,
                    value: destination?.point?.address?.entryFee?.value || '',
                    currency: destination?.point?.address?.entryFee?.currency || ''
                },
                driverComment: {
                    active: !!destination?.point?.address?.driverComment,
                    value: destination?.point?.address?.driverComment || ''
                },
                driverData: {
                    active: !!destination?.point?.address?.driverDataEmail,
                    value: destination?.point?.address?.driverDataEmail || '',
                    pass: destination?.point?.address?.driverPass,
                    passNote: destination?.point?.address?.driverPassNote,
                },
                roadmap: {
                    active: destination?.point?.address?.hasRoadmap,
                    value: '',
                    oldActive: destination?.point?.address?.hasRoadmap
                },
                documentAddress: {
                    active: !!destination?.point?.address?.address?.[1],
                    value: destination?.point?.address?.address?.[1] || ''
                },
                ...(Object.fromEntries(specificLoadingParams.map(v => [v, Boolean(additional.specificLoading.destination.includes(v))]))),
                type: specificLoadingTypeFromOrder(additional.specificLoading.destination)
            }
        },
        cargo: {
            marketplaceGuid: meta.marketplace?.guid || null,
            category: cargo?.category?.id || null,
            mode: cargoMode,
            type: cargo?.type as { correspondence: boolean, cargo: boolean },
            insurance: {
                type: cargo?.insurance?.used ? 'cargoInsurance' : (cargo?.insurance_ndv?.insurance_ndv ? 'noDeclaredValue' : 'without'),
                value: cargo?.insurance?.insurance
            },
            transportationId: cargo?.customId,
            pallets: {
                items: cargoMode === 'pallets' ? palletsItemsToForm(cargo?.wizard || cargo.pallet?.data || [], cargo?.wrapping) : []
            },
            parcels: {
                items: cargoMode === 'parcels' ? cargo?.parcel?.data || cargo.parcel : []
            },
            places: {
                items: cargoMode === 'places' ? placesItemsToForm(cargo?.wizard || [], cargo?.wrapping) : []
            },
            dimensions: {
                detail: {
                    length: cargo?.dimension?.detail?.length,
                    height: cargo?.dimension?.detail?.height,
                    weight: cargo?.dimension?.detail?.weight,
                    width: cargo?.dimension?.detail?.width
                },
                general: {
                    place: cargo?.dimension?.general?.quantity,
                    volume: cargo?.dimension?.general?.volume,
                    weight: cargo?.dimension?.general?.weight
                },
                wrapping: cargoMode === 'dimension' ? dimensionWrappingToForm(cargo?.wrapping) : []
            }
        },
        additionalServices: {
            scanDocuments: additional?.retrieveAD?.scanned || false,
            scan: additional?.scannedConsignationNote?.used || false,
            disassemble: {
                active: additional?.unboxingOD?.used,
                weight: additional?.unboxingOD?.weight
            },
            returning: {
                active: additional?.retrieveAD?.used || false,
                address: additional?.retrieveAD?.point?.[additional?.retrieveAD?.point?.type]?.address || '',
                direction: additional?.retrieveAD?.point?.type || 'address',
                locationGuid: additional?.retrieveAD?.location?.id || false,
                terminalGuid: additional?.retrieveAD?.point?.[additional?.retrieveAD?.point?.type]?.id || ''
            }
        },
        currency: Object.keys(currencyList).find(key => !!currencyList[key]) || 'RU',
        defaultCurrency: cost?.currency?.default,
        isVip: meta?.vip,
        promocode: meta?.promoCode,
        number: meta?.number,
        guid: meta?.guid,
        isNetwork: meta?.isNetwork
    }

    orderForm.shippingDiagram = convertScheduller(priceData.shippingDiagram)
    orderForm.services = convertPriceServices({ services: [priceData] })

    const segregatedList: any = {}
    orderForm.services.forEach((service: OrderService) => {
        segregatedList[service.guid] = service.payer
    })
    orderForm.customer.segregated.list = segregatedList
    if (priceData.cost) {
        orderForm.totalPrice = priceData.cost
    }

    return orderForm
}

export const priceDataToOrderView = (priceData: any) => {
    const {
        cargo,
        meta
    } = priceData

    const orderView = priceDataToOrderForm(priceData) as OrderView
    orderView.canBePaid = priceData?.canBePaid
    orderView.cargo.categoryName = cargo?.category?.title || ''
    orderView.favorList = meta?.favorList
    orderView.directions.dispatch.country = priceData.gateway.dispatch.location?.country
    orderView.directions.destination.country = priceData.gateway.destination.location?.country
    orderView.directions.dispatch.locationName = priceData.gateway.dispatch.location?.title || ''
    orderView.directions.destination.locationName = priceData.gateway.destination.location?.title || ''
    orderView.directions.dispatch.terminalName = priceData.gateway.dispatch.point?.terminal?.terminal?.shortName || ''
    orderView.directions.destination.terminalName = priceData.gateway.destination.point?.terminal?.terminal?.shortName || ''
    orderView.directions.destination.pickupPointName = priceData.gateway.destination.point?.pickupPoint?.pickupPoint?.shortName || ''

    return orderView
}

export function getCurrencyText(currency: string | undefined, short = false): string {
    switch (currency) {
        case 'KZ':
            return short ? '₸' : 'тенге'
        case 'AM':
            return short ? '֏' : 'драм'
        case 'KG':
            return short ? 'C' : 'сом'
        case 'BY':
            return short ? 'Br' : 'бел. руб'
        case 'RU':
            return short ? '₽' : 'руб'
        default:
            return '₽'
    }
}

export function getCountryFlagIcon(country: string): string {
    switch (country) {
        case 'KZ':
            return '/svg/vz-kz-flag.svg'
        case 'AM':
            return '/svg/vz-am-flag.svg'
        case 'KG':
            return '/svg/vz-kg-flag.svg'
        case 'BY':
            return '/svg/vz-belarus-flag.svg'
        default:
            return '/svg/vz-russia-flag.svg'
    }
}

export function getMaskedPhone(phone: string) {
    const country = getCountryByPhone(phone)
    const mask = phoneCountries.find(item => item.value === country)?.mask
    if (!mask) {
        return phone
    }

    let i = 0;
    const v = phone.toString();
    return mask.replace(/#/g, _ => v[i++]);
}
