import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import ru from 'dayjs/locale/ru';

dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);
dayjs.extend(duration);
dayjs.extend(dayOfYear);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.locale(ru)

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      dayjsTest: dayjs
    }
  }
});
