<template>
  <div class="vz-input">
    <slot name="label">
      <label v-if="label" class="vz-input-label" :for="id" :class="labelClass" v-text="label" />
      <slot v-if="clearable && modelValue" name="after-label">
        <vz-icon
          class="vz-new-autocomplete-clear vz-cursor-pointer"
          name="close"
          variant="white-gray-ws"
          :size="13"
          rounded
          @click.stop="emit('clear')"
        />
      </slot>
      <slot name="additional-label" />
    </slot>
    <div class="vz-input-wrapper">
      <input
        ref="inputControl"
        class="vz-input-control"
        :value="modelValue"
        :id="id"
        :autocomplete="inputName"
        :name="inputName"
        :type="controlType"
        :class="controlClass"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :placeholder="props.placeholder"
        :autofill="inputName"
        @blur="onBlur"
        @input="onInput"
        @focus="onFocus"
        @dblclick="onDblClick"
        @paste="onPaste"
      />
      <div v-if="iconAttr" class="vz-input-icon" :class="{ 'icon-left': iconLeft }">
        <vz-icon v-bind="iconAttr" :color="iconColor" :variant="iconVariant" :size="iconSize" @click="onIconClick" />
      </div>
    </div>
    <vz-invalid-message v-if="isShowError" :block="errorBlockStyle">
      {{ errorText }}
    </vz-invalid-message>
  </div>
</template>

<script setup lang="ts">
import { useProps } from "~/common/ui-kit-common"
import {useIconProps} from "~/common/ui-kit-icon";
import { KeyValueObject } from "~/types/types.js";
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzInvalidMessage from '~/components/ui-kit/vz-invalid-message.vue';

const emit = defineEmits([
  'blur',
  'focus',
  'paste',
  'dblClick',
  'update:modelValue',
  'input:icon-click',
  'clear'
])

const props = defineProps({
  ...useProps,
  ...useIconProps,
  modelValue: {
    type: [String, Number],
    default: ''
  },
  clearable: {
    type: Boolean,
    default: false
  },
  errorBlockStyle: {
    type: Boolean,
    default: false
  },
  inputClass: {
    type: String,
    default: ''
  },
  maxValue: {
    type: String,
    default: ''
  },
  minValue: {
    type: String,
    default: ''
  },
  modelModifiers: {
    default: () => ({ trim: false, trimStart: false })
  },
  iconLeft: {
    default: false,
    type: Boolean
  },
})

const isFocus = ref(false)
const isAnimation = ref(false)

const hidden = ref(true)
const inputControl = ref<HTMLInputElement>()
const { isShowError, errorText, doValidate } = useValidateble()
const instance = getCurrentInstance()

const labelClass = computed((): KeyValueObject => {
  return {
    required: props.required,
    'public-style': props.labelPublicStyle,
    'color-inactive': props.disabled
  }
})

onMounted( () => {
  if (inputControl.value?.value && !props.modelValue) {
    emit('update:modelValue', inputControl.value?.value)
  }
})

const id = computed((): string => {
  return `vz-input-${instance?.uid}`
})

const isPassword = computed((): boolean => {
  return props.type === 'password'
})

const controlType = computed((): string => {
  if (isPassword.value && !hidden.value) {
    return 'text'
  }
  return !['password', 'text'].includes(props.type) ? 'text' : props.type
})

const iconAttr = computed(() => {
  if (props.iconName || props.iconUrl) {
    return {
      name: props.iconName,
      url: props.iconUrl,
      noMaskedIcon: !!props.iconUrl,
    }
  }
  if (isPassword.value && props.modelValue) {
    return {
      name: hidden.value ? 'eye-off' : 'eye'
    }
  }
  return ''
})

const inputName = computed((): string => {
  const date = new Date()
  return isFocus ? Math.random() + date.getMilliseconds().toString() : props.name
})

const controlClass = computed((): { [key: string]: string | boolean } => {
  let classes: any = {
    big: props.big,
    small: props.small,
    large: props.large,
    'has-error': isShowError.value,
    'iconical': iconAttr.value,
    'iconical-left': iconAttr.value && props.iconLeft,
    'animation': isAnimation.value
  }
  if (typeof props.inputClass === 'string' && props.inputClass !== '') {
    classes[props.inputClass] = true
  } else if (typeof props.inputClass === 'object') {
    classes = Object.assign({}, classes, props.inputClass)
  }
  return classes
})

const onBlur = async (event: Event): Promise<void> => {
  isFocus.value = false
  emit('blur', event)
  await nextTick()
  await checkError(false, !!document.querySelector('input:focus'))
}

async function checkError(silent: boolean = false, hasAtLeastOneInputFocus: boolean = true) {
  const resultValidate = await doValidate(props.modelValue, props.rules, silent)

  // если есть ошибка и на странице нет активных инпутов
  if(resultValidate && isShowError.value && !hasAtLeastOneInputFocus) {
    errorShowAnimation()
  }

  return resultValidate
}

const onFocus = (event: Event): void => {
  isFocus.value = true
  emit('focus', event)
}

const onPaste = async (e: ClipboardEvent): Promise<void> => {
  await nextTick()
  await checkError()
  emit('paste', e)
  let value = (e.target as any)?.value
  if (props.maxLength) {
    value = value.substring(0, props.maxLength)
  }
  emit('update:modelValue', value)
}

const onIconClick = (): void => {
  if (isPassword.value) {
    hidden.value = !hidden.value
  }

  setTimeout(() => {
    if (inputControl.value) {
      inputControl.value?.focus()
    }
  }, 30)

  emit('input:icon-click')
}

async function onInput(e: any): Promise<void> {
  let value = e.target.value
  if (props.modelModifiers.trimStart && value) {
    value = value.trimStart()
  }
  if (props.modelModifiers.trim && value) {
    value = value.trim()
  }
  if ( props.type === 'number' ) {
    value = value.replace(',', '.')
    // убирает любые сиволы кроме цифр (\D) или запоминает вначале строки '45.'(цифры + .)
    value = value.replace(/^(\d[^\.]*\.)|\D/g, '$1')
  }
  if (props.maxLength) {
    value = value.substring(0, props.maxLength)
  }
  if (props.maxValue && +value > +props.maxValue) {
    value = props.maxValue;
  }
  if (props.minValue && +value < +props.minValue) {
    value = props.minValue;
  }
  e.target.value = value
  emit('update:modelValue', value)
  await nextTick()
  await checkError()
}

function onDblClick(event: MouseEvent): void {
  emit('dblClick', event)
}

function errorShowAnimation() {
  isAnimation.value = true;

  setTimeout(() => {
    isAnimation.value = false;
  }, 300)
}

watch(() => props.rules, () => {
  checkError(!isShowError.value)
})

defineExpose({
  isShowError,
  checkError,
  inputControl,
  onIconClick,
})
</script>
