import {useUserStore} from '~/stores/personal/user';
import {notAuthRedirect} from '~/composables/useVzFetch';

const ALLOWED = [/^\/personal\/auth\/?/]
const AUTH_REQUIRED = [/^\/personal\/?/, /^\/constructor\/?/]

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (
      !AUTH_REQUIRED.some((route) => route.test(to.fullPath)) ||
      ALLOWED.some((route) => route.test(to.fullPath))
  ) {
    return
  }

  const { token } = useUserStore()
  if (!token) {
    return notAuthRedirect({ path: '/personal/auth/', query: { redirected_from: encodeURIComponent(from.fullPath) }})
  }
})