<template>
  <div class="vz-confirm-code">
    <vz-loader :status-loader="loader"/>

    <div
      v-if="message && !error"
      class="vz-confirm-code-message"
      style="word-break: break-word"
      v-html="message"
    />

    <div v-if="error" class="fs-16 color-error mt-30 mb-10">
      {{ error }}
    </div>

    <div class="vz-confirm-code-input mt-20">
      <input
        v-for="i in length"
        ref="input"
        inputmode="numeric"
        name="sms"
        :autocomplete="i === 1 ? 'one-time-code' : undefined"
        :class="{'has-error': error || isShowError}"
        :value="arrayValue[i - 1]"
        @focus="onFocus($event, i - 1)"
        @input="onInput($event, i - 1)"
        @paste="onPaste($event, i - 1)"
        @keydown.delete="onDelete($event, i - 1)"
      />
    </div>

    <div
      class="fs-12 mt-15 flex flex-align-items-center"
      :class="{'vz-cursor-pointer hovered-underline': !localTimeout, 'color-lower': localTimeout}"
      @click="sendConfirmCode"
    >
      {{ repeatConfirmCodeText }}
      <vz-icon
        v-if="localTimeout === 0"
        name="refresh"
        class="ml-5"
      />
    </div>

    <vz-invalid-message v-if="isShowError" class="vz-position-sticky">
      {{ errorText }}
    </vz-invalid-message>

    <public-contacts class="mt-15"/>
  </div>
</template>

<script setup lang="ts">
import {Timeout, ValidationRule} from '~/types/types';
import {PropType} from 'vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import PublicContacts from '~/components/public/public-contacts.vue';

const emit = defineEmits(['update:modelValue', 'submit', 'send'])
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: ''
  },
  message: {
    type: String,
    default: ''
  },
  length: {
    type: Number,
    default: 4
  },
  timeout: {
    type: Number,
    default: null
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => ([])
  },
  loader: {
    type: Boolean,
    default: false
  }
})

let timer: Timeout = null

const input = ref<HTMLInputElement[]>([])
const localTimeout = ref<number>()

const {isShowError, errorText, doValidate} = useValidateble()

async function checkError(silent: boolean = false): Promise<boolean> {
  return await doValidate(props.modelValue, props.rules, silent)
}

const arrayValue = computed(() => {
  return props.modelValue?.split('')
})

const repeatConfirmCodeText = computed(() => {
  if (localTimeout.value) {
    return `Отправить код повторно через ${localTimeout.value} секунд`
  }
  if (localTimeout.value === null) {
    return 'Отправить код'
  }
  return 'Отправить код повторно'
})

function onInput(e: any, i: number) {
  const value = (e.target.value?.length === 1 ? e.target.value : e.data)
    ?.replace(/\D/g, '')

  e.target.value = value
  changeChar(value, i)
  nextTick(() => {
    if (arrayValue.value.length < props.length) {
      onFocus(e, i + 1)
    } else {
      emit('submit')
      e?.target?.blur?.()
    }
  })
}

function onFocus(e: any, i: number): void {
  if (input.value[i]?.value) {
    input.value[i].select()
    return
  }
  if (i > 0 && !arrayValue.value[i - 1]) {
    return onFocus(e, i - 1)
  }
  if (i > arrayValue.value.length) {
    e.target.select()
  } else if (arrayValue.value.length < props.length) {
    input.value[arrayValue.value.length].focus()
  }
}

async function onPaste(e: any, i: number) {
  // Stop data actually being pasted into div
  e.stopPropagation()
  e.preventDefault()
  // Get pasted data via clipboard API
  const clipboardData = e.clipboardData || (window as any).clipboardData
  const pastedData = clipboardData.getData('Text')?.replace(/\D/g, '')

  if (pastedData.length == props.length) {
    emit('update:modelValue', pastedData)
    await nextTick()
    e?.target?.blur?.()
    emit('submit')
  } else if (pastedData.length === 1) {
    changeChar(pastedData, i)
    await nextTick()
    if (i < props.length) {
      onFocus(e, i + 1)
    } else {
      emit('submit')
      e?.target?.blur?.()
    }
  }
}

function onDelete(e: any, i: number) {
  if (arrayValue.value[i]?.toString()) {
    e.preventDefault()
    changeChar(undefined, i)
  } else {
    onFocus(e, i - 1)
  }
}

function changeChar(value: string | undefined, i: number) {
  const newArrayValue = {...arrayValue.value, [i]: value}
  emit('update:modelValue', Object.values(newArrayValue).join(''))
  checkError()
}

function sendConfirmCode(): void {
  if (localTimeout.value) {
    return
  }
  emit('send')
  nextTick(() => {
    input.value[arrayValue.value.length].focus()
  })
}

watch(() => props.timeout,
  () => {
    localTimeout.value = props.timeout
    if (!localTimeout.value) {
      return
    }
    if (timer) {
      clearInterval(timer)
    }

    timer = setInterval(() => {
      localTimeout.value = (localTimeout.value || 0) - 1
      if (localTimeout.value <= 0 && timer) {
        clearTimeout(timer)
      }
    }, 1000)
  },
  {immediate: true}
)

onMounted(() => {
  input.value[arrayValue.value.length].focus()

  if ("credentials" in navigator) {
    navigator.credentials?.get({
      otp: {transport: ['sms']}
    })
      .then(async (otp) => {
        console.log('otp then', otp)
        emit('update:modelValue', otp?.code)
        await nextTick()
        emit('submit')
      }).catch((e) => {
      console.log('otp catch', e)
    })
  } else {
    console.log('otp credentials не разрешен')
  }
})

defineExpose({
  isShowError,
  checkError
})
</script>

<style lang="scss" scoped>
.vz-confirm-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &-message {
    font-size: 16px;
    color: $font-lower;
    text-align: center;
    margin-top: 20px;
  }

  &-input {
    display: flex;
    justify-content: center;
    font-size: 16px;

    input {
      background-color: $component-background;
      border: 1px solid $border-gray;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      padding: 14px;
      line-height: 19px;
      color: $font-dark;

      &.has-error {
        border: 1px solid $error;
      }

      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}
</style>
