import { Location } from '~/types/types'
import { Ref } from 'vue'
import {getAddressByGuid, getLocations} from '~/api/city';
import {cookieItems} from '~/composables/useVzFetch';
import {cookieOptions} from '~/common/functions';

export const useCityStore = defineStore('city', () => {
  const rclCityList = ref([]) as Ref<Location[]>
  const from = ref({}) as Ref<Location>
  const to = ref({}) as Ref<Location>

  const currentCity = ref({}) as Ref<Location>
  const currentCityAbbr = useCookie(cookieItems.city, cookieOptions())
  const currentPhones = ref([] as string[])

  function setFrom(location: Location) {
    from.value = location
  }

  function setTo(location: Location) {
    to.value = location
  }

  function getAllRclCities(): Promise<void> {
    return getLocations().then(data => {
        if (data) {
          rclCityList.value = data
          if (!from.value?.guid && data.length) {
            from.value = rclCityList.value.find(c => [c.abbr, c.translit].includes(currentCityAbbr.value)) || {} as Location
            to.value = rclCityList.value[0].guid === from.value.guid ? rclCityList.value[1] : rclCityList.value[0]
          }
          if (currentCityAbbr.value) {
            const find = rclCityList.value.find(c => [c.abbr, c.translit].includes(currentCityAbbr.value))
            if (find) {
              currentCity.value = find
            }
          }
        }
      }).catch(e => {
      console.log(e)
      // очищаем useMemoize если ошибка, не может сам понять что кэшить
      getLocations.clear()
    })
  }

  async function locationByGuid(guid?: string) {
    const find = rclCityList.value.find(city => city.guid === guid)
    if (find) {
      return find
    }
    return (await getAddressByGuid(guid || '')).location
  }

  // Делаем запрос при инициализации
  if (!import.meta.env?.VITE_IS_HISTOIRE) {
    getAllRclCities().then()
  }

  return {
    from,
    to,
    rclCityList,
    currentCity,
    currentPhones,
    currentCityAbbr,
    getAllRclCities,
    locationByGuid,
    setFrom,
    setTo,
  }
})
