<template>
  <component
    v-bind="linkAttrs"
    class="vz-button"
    :is="componentTag"
    :class="classes"
    :disabled="disabled || undefined"
    :title="title"
    :target="target"
    :type="type"
  >
    <vz-icon
      v-if="hasIconAndPositionLeft"
      :key="iconName || iconUrl"
      :inactive="disabled"
      :name="iconName"
      :size="iconSize"
      :color="color"
      :url="iconUrl"
      :title="title"
    />
    <span
      v-if="hasSlot"
      class="vz-button-title"
      :class="{ 'icon-right': hasIcon }"
    >
            <slot></slot>
        </span>
    <vz-icon
      v-if="hasIconAndPositionRight"
      :key="iconName || iconUrl"
      :inactive="disabled"
      :name="iconName"
      :size="iconSize"
      :color="color"
      :url="iconUrl"
      :title="title"
    />
  </component>
</template>

<script setup lang="ts">
import {ComputedRef, PropType, resolveComponent} from 'vue'
import {KeyValueObject} from '~/types/types'
import VzIcon from '~/components/ui-kit/vz-icon.vue'
import {RouteLocationRaw} from 'vue-router'
import {UIKitVariants} from '~/types/variants';
import {useIconProps} from "~/common/ui-kit-icon";

const props = defineProps({
  ...useIconProps,
  disabled: {
    type: Boolean,
    default: undefined
  },
  variant: {
    type: String as PropType<UIKitVariants>,
    default: ''
  },
  big: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  },
  href: {
    default: '',
    type: String
  },
  title: {
    default: '',
    type: String
  },
  target: {
    default: '',
    type: String
  },
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: null,
  },
  type: {
    type: String,
    default: 'button',
  },
  outline: {
    default: false,
    type: Boolean
  },
  rounded: {
    default: false,
    type: Boolean
  },
  link: {
    default: false,
    type: Boolean
  },
  external: {
    default: false,
    type: Boolean
  },
  iconRight: {
    default: false,
    type: Boolean
  },
  onlyIcon: {
    default: false,
    type: Boolean
  }
})
const $slots = useSlots()

const componentTag = computed(() => {
  if (props.href) {
    return 'a'
  }
  if (props.to) {
    return resolveComponent('NuxtLink')
  }
  return 'button'
})

const linkAttrs = computed((): KeyValueObject => {
  if (props.href) {
    return {href: props.href}
  }
  if (props.to) {
    return {to: props.to, external: props.external}
  }
  return {}
})

const hasSlot = computed((): boolean => {
  if (props.onlyIcon) {
    return false
  }
  if ($slots.default?.()) {
    return $slots.default().length > 0
  }
  return false
})

const hasIcon = computed((): ComputedRef<boolean> | boolean => {
  if (props.iconRight === null || props.iconName === '' || props.iconUrl === '') {
    return false
  }
  return hasIconAndPositionLeft
})

const hasIconAndPositionLeft = computed((): boolean => {
  return (props.iconName !== '' || props.iconUrl !== '') && !props.iconRight
})

const hasIconAndPositionRight = computed((): boolean => {
  return (props.iconName !== '' || props.iconUrl !== '') && props.iconRight
})

const color = computed((): string => {
  return props.iconColor ? props.iconColor : `color-${props.variant}`
})

const classes = computed((): { [x: string]: boolean } => {
  return {
    [props.variant]: true,
    link: props.link,
    round: props.rounded,
    'without-title': !hasSlot,
    outline: props.outline,
    big: props.big,
    large: props.large
  }
})
</script>
