<template>
  <vz-form v-model="isValid" ref="formRef" class="vz-position-relative w-100">
    <vz-loader :status-loader="loader"/>
    <vz-row class="flex-column">
      <div class="vz-auth-form-title">
        <slot name="title">
          Подтверждение входа
        </slot>
      </div>

      <vz-switcher
        v-if="authInfo.password"
        class="auth-form-switcher"
        v-model="authType"
        big
        :items="authTypeList"
      />

      <template v-if="isPassword">
        <vz-input
          ref="passwordRef"
          v-model="password"
          big
          class="vz-auth-form-input mt-30"
          type="password"
          placeholder="Пароль*"
          name="password"
          @keydown.enter="submit"
        />

        <a class="mt-15 color-inactive fs-14 w-100 vz-cursor-pointer text-right" @click="onForgotPassword">
          Забыли пароль?
        </a>

        <div class="flex vz-auth-confirm-form-back buttons">
          <vz-button
            icon-name="arrow-left"
            icon-size="20"
            large
            variant="public-primary"
            @click="$emit('back')"
          >
            Назад
          </vz-button>
          <vz-button
            large
            variant="primary"
            @click="submit"
          >
            Войти
          </vz-button>
        </div>
      </template>

      <vz-auth-confirm-code
        v-else
        v-model="confirmCode"
        :login="authInfo.login"
        :context="authInfo.context"
        :error="authError"
        class="w-100"
        @submit="submit"
        @update:error="authError = $event"
      />
    </vz-row>
    <vz-button
      v-if="!isPassword"
      class="vz-auth-confirm-form-back"
      icon-name="arrow-left"
      icon-size="20"
      large
      variant="public-primary"
      @click="$emit('back')"
    >
      Назад
    </vz-button>
  </vz-form>
</template>

<script setup lang="ts">
import formRules from '~/common/vz-form-rules';
import {AuthData, AuthType} from '~/types/api';
import {SwitchItem} from '~/types/types';
import VzRow from '~/components/ui-kit/vz-row.vue';
import VzForm from '~/components/ui-kit/vz-form.vue';
import VzSwitcher from '~/components/ui-kit/vz-switcher.vue';
import VzAuthConfirmCode from '~/components/forms/auth/vz-auth-confirm-code.vue';
import VzLoader from '~/components/ui-kit/vz-loader.vue';
import {useUserStore} from '~/stores/personal/user';
import {storeToRefs} from 'pinia';
import {RP_TOKEN_KEY} from '~/types/order/order';
import {setYM} from '~/common/functions';
import {auth} from '~/api/user'
import VzButton from "~/components/ui-kit/vz-button.vue";

const emit = defineEmits(['submit'])

const props = defineProps({
  regOnly: {
    type: Boolean,
    default: false
  }
})

const {authInfo, token} = storeToRefs(useUserStore())
const route = useRoute()

const formRef = ref()
const passwordRef = ref()
const password = ref('')
const confirmCode = ref('')
const authError = ref('')
const authType = ref<AuthType>('password')
const isValid = ref(true)
const loader = ref(false)

const forgotPassword = computed(() => {
  return !!authInfo.value.forgotPassword
})

const isPassword = computed(() => {
  return authType.value === 'password' && !forgotPassword.value
})

const authTypeList = computed((): SwitchItem[] => {
  return [
    {
      value: 'confirmCode',
      disabled: forgotPassword.value,
      title: (formRules.email.cb(authInfo.value.login) === true) ? 'Код из Email' : 'Код из SMS'
    },
    {
      value: 'password',
      disabled: !authInfo.value.password,
      title: 'Пароль'
    },
  ]
})

const linkToRedirect = computed(() => {
  let link = '/personal/orders/'
  if (!!route.query?.redirected_from) {
    const redirectedFrom = decodeURIComponent(route.query.redirected_from + route.hash)
    const data = useRouter().resolve(redirectedFrom)
    if (data && data.name && useRouter().hasRoute(data.name)) {
      link = redirectedFrom
    }
  }
  return link
})

async function submit() {
  const authType: AuthType = isPassword.value ? 'password' : 'confirmCode'
  const data: AuthData = {
    authType,
    login: authInfo.value.login || '',
    [authType]: isPassword.value ? password.value : confirmCode.value,
    resetPassword: authInfo.value.forgotPassword,
    discountGuid: props.regOnly ? route.query.discountGuid : undefined
  }

  loader.value = true
  const {sessionKey, rpToken, error, errors} = await auth(data)
  loader.value = false

  authError.value = error?.message || ''
  if (errors) {
    authError.value = Object.values(errors).join(', ')
  }
  if (authError.value) {
    confirmCode.value = ''
    return
  }

  if (sessionKey) {
    token.value = sessionKey

    window.postMessage({
      type: 'user-login',
    }, '*')

    document.dispatchEvent(
      new CustomEvent('ChatClient',
        {
          detail: {eventName: 'user-login'}
        }),
    );

    if (authInfo.value.context === 'userReg') {
      setYM(38058485, 'registracia')
    }
    // this.username = data.login
    if (forgotPassword.value && rpToken) {
      sessionStorage.setItem(RP_TOKEN_KEY, rpToken)
      navigateTo('/personal/account/')
      return
    }

    navigateTo(linkToRedirect.value)
  }
}

function onForgotPassword() {
  authInfo.value.forgotPassword = true
}

watch(() => authInfo.value.authType, () => {
  authType.value = authInfo.value.authType
}, {immediate: true})

watch(authType, async () => {
  authError.value = ''
  if (!authInfo.value.password && authType.value === 'password') {
    authType.value = 'confirmCode'
  } else if (authInfo.value.passwordValue) {
    password.value = authInfo.value.passwordValue
  }
  await nextTick()
  if (passwordRef.value) {
    passwordRef.value.inputControl.select()
  }
}, {immediate: true})
</script>

<style lang="scss" scoped>
.vz-auth-confirm-form-back {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
}

.auth-form-switcher {
  height: 48px;
  font-size: 16px;
}

.buttons {
  gap: 20px;

  @media screen and (max-width: $pa-xxs) {
    flex-direction: column;
  }

  button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
