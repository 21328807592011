export const useBackendStore = defineStore('backend', () => {
  const backendAvailable = ref('')

  function setBackendAvailable(date: string) {
    backendAvailable.value = date
  }

  return {
    backendAvailable, 
    setBackendAvailable
  }
})
