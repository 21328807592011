import {OrderV3, TemplateOrder} from '~/types/order/orderV3';
import {DirectionName, OrderForm, OrderSpecialRequirement} from '~/types/types';
import {deepCopyObject} from '~/common/functions';
import {Purpose} from '~/types/order/order';

export const priceDataToOrderFormV3 = (priceData: Partial<OrderV3>|null = null): OrderV3 => {
  if (!priceData) {
    priceData = {}
  }
  const orderFormV3 = {
    meta: {
      data: priceData.meta?.data || {},
      networkMode: priceData.meta?.networkMode ?? false
    },
    cargo: {
      mode: priceData.cargo?.mode,
      type: priceData.cargo?.type || {},
      dimension: {
        general: priceData.cargo?.dimension?.general || {},
        wrapping: priceData.cargo?.dimension?.wrapping || [],
        detail: priceData.cargo?.dimension?.detail || {},
      },
      pallet: {
        general: priceData.cargo?.pallet?.general || {},
        wrapping: priceData.cargo?.pallet?.wrapping || [],
        items: priceData.cargo?.pallet?.items || [],
      },
      parcel: {
        general: priceData.cargo?.parcel?.general || {},
        items: priceData.cargo?.parcel?.items || [],
      },
      wizard: {
        general: priceData.cargo?.wizard?.general || {},
        wrapping: priceData.cargo?.wizard?.wrapping || [],
        items: priceData.cargo?.wizard?.items || [],
      },
      category: priceData.cargo?.category || null
    },
    directions: {
      dispatch: {
        shippingTerm: {},
        ...(priceData.directions?.dispatch || {}),
      },
      destination: {
        shippingTerm: {},
        ...(priceData.directions?.destination || {}),
        isCustom: !!priceData.directions?.destination?.address && !priceData.directions?.destination?.guid
      },
    },
    customer: {
      dispatch: {
        corporation: {
          ...(priceData.customer?.dispatch?.corporation || {}),
          contact: {...(priceData.customer?.dispatch?.corporation?.contact || {})}
        },
        individual: {
          ...(priceData.customer?.dispatch?.individual || {}),
          contact: {...(priceData.customer?.dispatch?.individual?.contact || {})}
        },
        entityType: priceData.customer?.dispatch?.entityType
      },
      destination: {
        corporation: {
          ...(priceData.customer?.destination?.corporation || {}),
          contact: {...(priceData.customer?.destination?.corporation?.contact || {})}
        },
        individual: {
          ...(priceData.customer?.destination?.individual || {}),
          contact: {...(priceData.customer?.destination?.individual?.contact || {})}
        },
        entityType: priceData.customer?.destination?.entityType
      },
      third: {
        corporation: {
          ...(priceData.customer?.third?.corporation || {}),
          contact: {...(priceData.customer?.third?.corporation?.contact || {})}
        },
        individual: {
          ...(priceData.customer?.third?.individual || {}),
          contact: {...(priceData.customer?.third?.individual?.contact || {})}
        },
        entityType: priceData.customer?.third?.entityType
      },
      segregated: priceData.customer?.segregated || { },
      payer: priceData.customer?.payer
    },
    services: {
      ...priceData.services,
      // unboxingOnDelivery: priceData.services?.unboxingOnDelivery || {},
      // retrieveAD: priceData.services?.retrieveAD || {}
      specificLoading: { dispatch: priceData.services?.specificLoading?.dispatch || {}, destination: priceData.services?.specificLoading?.destination || {} },
      documentAddress: { dispatch: priceData.services?.documentAddress?.dispatch || {}, destination: priceData.services?.documentAddress?.destination || {} },
      driverComment: { dispatch: priceData.services?.driverComment?.dispatch || {}, destination: priceData.services?.driverComment?.destination || {} },
      driverData: { dispatch: priceData.services?.driverData?.dispatch || {}, destination: priceData.services?.driverData?.destination || {} },
      driverPass: { dispatch: priceData.services?.driverPass?.dispatch || {}, destination: priceData.services?.driverPass?.destination || {} },
      insurance: priceData.services?.insurance || {},
      insuranceNoDeclaredValue: priceData.services?.insuranceNoDeclaredValue || {},
      needLoading: { dispatch: priceData.services?.needLoading?.dispatch || {}, destination: priceData.services?.needLoading?.destination || {} },
    },
  } as OrderV3

  return orderFormV3
}

export const clearTemplate: TemplateOrder = {
  id: 0,
  name: 'Пустой заказ',
  content: {
    directions: {
      dispatch: {
        locationGuid: '',
        type: 'address',
        address: '',
        terminalGuid: '',
        waitingTime: 30,
        shippingTerm: {
          date: '',
          from: '',
          to: '',
          fixTime: false,
          terminalTime: ''
        },
      },
      destination: {
        locationGuid: '',
        type: 'address',
        address: '',
        terminalGuid: '',
        waitingTime: 30,
        shippingTerm: {
          date: '',
          from: '',
          to: '',
          fixTime: false,
          terminalTime: ''
        },
        isCustom: false,
      }
    },
    services: {
      vip: false,
      insuranceNoDeclaredValue: {
        active: true,
        value: null
      },
      scanConsignationNote: false,
      unboxingOnDelivery: {
        active: false,
        value: null
      },
      insurance: {
        active: false,
        value: null
      },
      documentAddress: {
        dispatch: {
          active: false,
          value: null
        },
        destination: {
          active: false,
          value: null
        },
      },
      driverComment: {
        dispatch: {
          active: false,
          value: null
        },
        destination: {
          active: false,
          value: null
        },
      },
      driverData: {
        dispatch: {
          active: false,
          value: null,
        },
        destination: {
          active: false,
          value: null,
        }
      },
      needLoading: {
        dispatch: {
          active: false,
          floor: null,
          floorLimit: null,
          freightElevator: false
        },
        destination: {
          active: false,
          floor: null,
          floorLimit: null,
          freightElevator: false
        },
      },
      specificLoading: {
        dispatch: {
          transportType: 'default',
          active: false,
          list: {
            bodyHeight_2: false,
            bodyHeight_2_1: false,
            bodyHeight_2_2: false,
            bodyHeight_2_3: false,
            bodyHeight_2_4: false,
            bodyHeight_2_5: false,
            ramp110: false,
            ramp120: false,
            ramp130: false,
            fixingStraps: false,
            passportRF: false,
            reflectiveVest: false,
            closedClothes: false,
            medicalCard: false,
            emptyMachine: false,
            pallets: false,
            rearLoading: false,
            sanitaryPassport: false,
          }
        },
        destination: {
          transportType: 'default',
          active: false,
          list: {
            bodyHeight_2: false,
            bodyHeight_2_1: false,
            bodyHeight_2_2: false,
            bodyHeight_2_3: false,
            bodyHeight_2_4: false,
            bodyHeight_2_5: false,
            ramp110: false,
            ramp120: false,
            ramp130: false,
            fixingStraps: false,
            passportRF: false,
            reflectiveVest: false,
            closedClothes: false,
            medicalCard: false,
            emptyMachine: false,
            pallets: false,
            rearLoading: false,
            sanitaryPassport: false,
          }
        },
      },
      retrieveAD: {
        active: false,
        address: '',
        type: 'address',
        locationGuid: '',
        terminalGuid: '',
        scan: false
      }
    },
    cargo: {
      type: {
        cargo: true,
        correspondence: false
      },
      mode: 'dimension',
      dimension: {
        general: {
          weight: 0.9,
          quantity: 1,
          volume: 0.01
        },
        detail: {
          height: 0.2,
          weight: 0.9,
          width: 0.2,
          length: 0.2
        },
        wrapping: []
      },
      pallet: {} as any,
      parcel: {} as any,
      wizard: {} as any,
      category: ''
    },
    customer: {
      dispatch: {
        individual: { contact: { email: '', name: '', phone: [], id: '' } },
        entityType: 'individual'
      },
      destination: {
        individual: { contact: { email: '', name: '', phone: [], id: '' } },
        entityType: 'individual'
      },
      third: {
        individual: { contact: { email: '', name: '', phone: [], id: '' } },
        entityType: 'individual'
      },
      payer: 'destination'
    } as any,
    meta: {
      networkMode: false,
    } as any
  },
}

export function mergeOrderV3WithOrder(orderForm: OrderForm, orderV3?: Partial<OrderV3>): OrderForm {
  let newValue: OrderForm = deepCopyObject(orderForm)

  newValue = {
    ...orderForm,
    directions: {
      dispatch: {
        direction: orderV3?.directions?.dispatch?.type ?? orderForm.directions.dispatch.direction,
        locationGuid: orderV3?.directions?.dispatch?.locationGuid ?? orderForm.directions.dispatch.locationGuid,
        terminalGuid: orderV3?.directions?.dispatch?.terminalGuid ?? orderForm.directions.dispatch.terminalGuid,
        address: orderV3?.directions?.dispatch?.address ?? orderForm.directions.dispatch.address,
        calendar: {
          ...(orderV3?.directions?.dispatch?.shippingTerm ?? orderForm.directions.dispatch.calendar),
          fixedTime: orderV3?.directions?.dispatch?.shippingTerm?.fixTime ?? orderForm.directions.dispatch.calendar.fixedTime,
        },
        country: orderForm.directions.dispatch.country,
        waitingTime: orderV3?.directions?.dispatch?.waitingTime ?? orderForm.directions.dispatch.waitingTime,
        region: orderForm.directions.dispatch.region
      },
      destination: {
        direction: orderV3?.directions?.destination?.type ?? orderForm.directions.destination.direction,
        locationGuid: orderV3?.directions?.destination?.locationGuid ?? orderForm.directions.destination.locationGuid,
        terminalGuid: orderV3?.directions?.destination?.terminalGuid ?? orderForm.directions.destination.terminalGuid,
        address: orderV3?.directions?.destination?.address ?? orderForm.directions.destination.address,
        calendar: {
          ...(orderV3?.directions?.destination?.shippingTerm ?? orderForm.directions.destination.calendar),
          fixedTime: orderV3?.directions?.destination?.shippingTerm?.fixTime ?? orderForm.directions.destination.calendar.fixedTime,
        },
        isCustom: orderV3?.directions?.destination?.isCustom ?? (orderV3?.meta?.networkMode
            ? !!orderV3?.directions?.destination?.networkGuid &&
            !orderV3?.directions?.destination?.guid &&
            !!orderV3?.directions?.destination?.address
            : orderForm.directions.destination.isCustom),
        country: orderForm.directions.destination.country,
        networkAddressGuid: orderV3?.directions?.destination?.guid ?? orderForm.directions.destination.networkAddressGuid,
        networkGuid: orderV3?.directions?.destination?.networkGuid ?? orderForm.directions.destination.networkGuid,
        waitingTime: orderV3?.directions?.destination?.waitingTime ?? orderForm.directions.destination.waitingTime,
        region: orderForm.directions.destination.region
      },
    },
    cargo: {
      ...orderForm.cargo,
      transportationId: orderV3?.meta?.data?.customId ?? orderForm.cargo.transportationId,
      category: orderV3?.cargo?.category ?? orderForm.cargo.category,
      insurance: {
        type: orderV3?.services?.insurance?.active || orderV3?.services?.insuranceNoDeclaredValue?.active
            ? orderV3?.services?.insuranceNoDeclaredValue ? 'noDeclaredValue' : 'cargoInsurance'
            : orderV3?.services?.insurance ? 'without' : orderForm.cargo.insurance.type,
        value: +(orderV3?.services?.insurance?.value ?? orderForm.cargo.insurance.value),
      },
    },
    additionalServices: {
      scanDocuments: orderV3?.services?.retrieveAD?.scan ?? orderForm.additionalServices.scanDocuments,
      scan: orderV3?.services?.scanConsignationNote ?? orderForm.additionalServices.scan,
      disassemble: {
        active: orderV3?.services?.unboxingOnDelivery?.active ?? orderForm.additionalServices.disassemble.active,
        weight: +(orderV3?.services?.unboxingOnDelivery?.value ?? orderForm.additionalServices.disassemble.weight),
      },
      returning: {
        active: orderV3?.services?.retrieveAD?.active ?? orderForm.additionalServices.returning.active,
        locationGuid: orderV3?.services?.retrieveAD?.locationGuid ?? orderForm.additionalServices.returning.locationGuid,
        terminalGuid: orderV3?.services?.retrieveAD?.terminalGuid ?? orderForm.additionalServices.returning.terminalGuid,
        address: orderV3?.services?.retrieveAD?.address ?? orderForm.additionalServices.returning.address,
        direction: orderV3?.services?.retrieveAD?.type ?? orderForm.additionalServices.returning.direction,
      },
    },
    customer: {
      ...orderForm.customer,
      payer: orderV3?.customer?.payer ?? orderForm.customer.payer,
    }
  }
  // Груз
  newValue.cargo.type = orderV3?.cargo?.type || newValue.cargo.type
  if (orderV3?.cargo?.mode) {
    if (orderV3.cargo.mode === 'wizard') {
      newValue.cargo.mode = 'places'
    } else {
      newValue.cargo.mode = (orderV3.cargo.mode + 's') as any
    }
    if (orderV3.cargo.mode === 'dimension') {
      newValue.cargo.mode = 'dimension'
      const wrapping = orderV3.cargo.dimension?.wrapping
          ? orderV3.cargo.dimension.wrapping.map(item => ({ code: item.code || item, value: item.value || 1 }))
          : newValue.cargo.dimensions.wrapping

      newValue.cargo.dimensions = {
        general: {
          volume: orderV3.cargo.dimension?.general?.volume || newValue.cargo.dimensions.general.volume,
          weight: orderV3.cargo.dimension?.general?.weight || newValue.cargo.dimensions.general.weight,
          place: orderV3.cargo.dimension?.general?.quantity || newValue.cargo.dimensions.general.place,
        },
        detail: {
          height: orderV3.cargo.dimension?.detail?.height || newValue.cargo.dimensions.detail.height,
          weight: orderV3.cargo.dimension?.detail?.weight || newValue.cargo.dimensions.detail.weight,
          length: orderV3.cargo.dimension?.detail?.length || newValue.cargo.dimensions.detail.length,
          width: orderV3.cargo.dimension?.detail?.width || newValue.cargo.dimensions.detail.width,
        },
        wrapping: wrapping || [],
      }
    } else if (orderV3.cargo.mode === 'parcel') {
      if (orderV3.cargo.parcel?.items?.length) {
        newValue.cargo.parcels = {
          items: orderV3.cargo.parcel.items.map((item, index) => ({
            type: item.type,
            weight: item.weight,
            label: item.label,
            key: index,
            count: item.quantity
          }))
        }
      }
    } else if (orderV3.cargo.mode === 'wizard') {
      if (orderV3.cargo.wizard?.items?.length) {
        newValue.cargo.places = {
          items: orderV3.cargo.wizard.items.map((item, index) => ({
            weight: item.weight,
            width: item.width,
            length: item.length,
            height: item.height,
            label: item.label,
            key: index,
            count: item.quantity,
            wrapping: [
              ...(orderV3?.cargo?.wizard?.wrapping || []),
              ...item.wrapping.map(code => ({ code, value: 1 }))
            ]
          }))
        }
      }
    } else if (orderV3.cargo.mode === 'pallet') {
      if (orderV3.cargo.pallet?.items?.length) {
        newValue.cargo.pallets = {
          items: orderV3.cargo.pallet.items.map((item, index) => ({
            type: item.type,
            weight: item.weight,
            width: item.width,
            length: item.length,
            height: item.height,
            label: item.label,
            key: index,
            count: item.quantity,
            wrapping: [
              ...(orderV3?.cargo?.pallet?.wrapping || []),
              ...item.wrapping.map(code => ({ code, value: 1 }))
            ]
          }))
        }
      }
    }
  }

  if (newValue.additionalServices.disassemble.active && !newValue.additionalServices.disassemble.weight) {
    newValue.additionalServices.disassemble.weight = newValue.cargo.dimensions.general.volume
  }

  // Участники
  (['dispatch', 'destination', 'third'] as DirectionName[]).forEach(key => {
    if (orderV3?.customer?.[key]) {
      const customer = orderV3.customer[key]
      const formCustomer = newValue.customer[key]
      const type = customer.entityType

      newValue.customer[key] = {
        type: type ?? formCustomer.type,
        sendCode: customer[type]?.sendCode ?? formCustomer.sendCode,
        individual: {
          email: customer.individual?.contact?.email ?? formCustomer.individual.email,
          phone: customer.individual?.contact?.phone ?? formCustomer.individual.phone,
          name: {
            id: customer.individual?.contact?.id ?? formCustomer.individual.name.id,
            value: customer.individual?.contact?.name ?? formCustomer.individual.name.value
          },
        },
        corporation: {
          email: customer.corporation?.contact?.email ?? formCustomer.corporation.email,
          phone: customer.corporation?.contact?.phone ?? formCustomer.corporation.phone,
          name: customer.corporation?.contact?.name ?? formCustomer.corporation.name,
          companyName: {
            id: customer.corporation?.guid ?? formCustomer.corporation.companyName.id,
            value: customer.corporation?.companyName ?? formCustomer.corporation.companyName.value
          },
          taxId: customer.corporation?.taxId ?? formCustomer.corporation.taxId,
          IEC: customer.corporation?.IEC ?? formCustomer.corporation.IEC,
        },
        country: customer[type].countryCode ?? formCustomer.country,
      } as any
    }
  })

  // Сервисы
  Object.entries(orderV3?.services || {}).forEach(([key, value]: [key: string, value: any]) => {
    let formKey = key as keyof OrderSpecialRequirement // as keyof OrderV3['services']
    if (key === 'needLoading') {
      formKey = 'unloadingOperations'
    }
    (['dispatch', 'destination'] as Purpose[]).forEach(direction => {
      if (value.hasOwnProperty(direction)) {
        if (newValue.specialRequirements[direction].hasOwnProperty(formKey)) {
          const newSpecialRequirements: any = newValue.specialRequirements[direction][formKey]

          newSpecialRequirements.active = value[direction].active ?? newSpecialRequirements.active
          newSpecialRequirements.value = value[direction].value ?? newSpecialRequirements.value
          newSpecialRequirements.floor = value[direction].floor ?? newSpecialRequirements.floor
          newSpecialRequirements.freightElevator = value[direction].freightElevator ?? newSpecialRequirements.freightElevator
        } else if (key === 'specificLoading') {
          newValue.specialRequirements[direction] = {
            ...newValue.specialRequirements[direction],
            type: value[direction]?.transportType ?? newValue.specialRequirements[direction].type,
            ...(value[direction]?.list || {})
          }
        }
      }
    })
  })

  return newValue
}