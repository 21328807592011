import revive_payload_client_4sVQNw7RlN from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/2025-03-12_16_14/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/2025-03-12_16_14/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt3_plugin_BdfRvWAAOY from "/var/www/2025-03-12_16_14/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_Jozdw60ZsE from "/var/www/2025-03-12_16_14/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import chat_client_hZsCHTo8lJ from "/var/www/2025-03-12_16_14/plugins/chat.client.ts";
import chunk_error_client_VGTGVjBfpU from "/var/www/2025-03-12_16_14/plugins/chunk-error.client.ts";
import counter_ZANrREnLZQ from "/var/www/2025-03-12_16_14/plugins/counter.ts";
import dayjsTest_phnKdEFwt6 from "/var/www/2025-03-12_16_14/plugins/dayjsTest.ts";
import dialog_uimL6oljqr from "/var/www/2025-03-12_16_14/plugins/dialog.ts";
import directives_8CcCirWtnE from "/var/www/2025-03-12_16_14/plugins/directives.ts";
import gtag_client_Zw8EQXNVTz from "/var/www/2025-03-12_16_14/plugins/gtag.client.ts";
import sentry_client_shVUlIjFLk from "/var/www/2025-03-12_16_14/plugins/sentry.client.ts";
import toast_text_L7pttklpMi from "/var/www/2025-03-12_16_14/plugins/toast-text.ts";
import toast_ysMjUcU7eJ from "/var/www/2025-03-12_16_14/plugins/toast.ts";
import vk_client_PUbypKZsNX from "/var/www/2025-03-12_16_14/plugins/vk.client.ts";
import ymetrika_client_C7OTkaEQuu from "/var/www/2025-03-12_16_14/plugins/ymetrika.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt3_plugin_BdfRvWAAOY,
  plugin_Jozdw60ZsE,
  chat_client_hZsCHTo8lJ,
  chunk_error_client_VGTGVjBfpU,
  counter_ZANrREnLZQ,
  dayjsTest_phnKdEFwt6,
  dialog_uimL6oljqr,
  directives_8CcCirWtnE,
  gtag_client_Zw8EQXNVTz,
  sentry_client_shVUlIjFLk,
  toast_text_L7pttklpMi,
  toast_ysMjUcU7eJ,
  vk_client_PUbypKZsNX,
  ymetrika_client_C7OTkaEQuu
]