<template>
  <vz-dialog
    name="confirm-dialog"
    small
    :title="title"
    auto-show
    @close="onHide(null)"
  >
    <div v-if="typeof text === 'string'" class="vz-align-center fz-16" v-html="text"/>
    <!--    todo-->
    <component v-else :is="() => text"/>
    <template #footer>
      <div class="confirm-dialog-footer">
        <vz-button v-if="buttonText" variant="primary" big @click="confirm(true)" v-text="buttonText"/>
        <vz-button v-if="cancelText" big @click="confirm(false)" v-text="cancelText"/>
      </div>
    </template>
  </vz-dialog>
</template>

<script setup lang="ts">
import VzDialog from '~/components/ui-kit/vz-dialog.vue';
import VzButton from '~/components/ui-kit/vz-button.vue';

const emit = defineEmits(['hide'])

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  cancelText: {
    type: String,
    default: ''
  },
  buttonText: {
    type: String,
    default: ''
  },
  onHide: {
    type: Function,
    default: () => {
    }
  },
})

function confirm(value: boolean) {
  props.onHide(value)
  useNuxtApp().$hideDialog({name: 'confirm-dialog'});
}
</script>

<style lang="scss" scoped>
.confirm-dialog-footer {
  display: flex;
  width: 100%;
  align-items: center;

  @media screen and (max-width: $xs) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    .vz-button {
      margin: 0;
    }
  }
}
</style>
