<template>
  <div
    class="vz-icon mdi"
    :class="iconColor"
    :style="styles"
    :title="title"
    @click="onClick"
  >
    <component
      :is="imgComponent"
      class="vz-icon-masked"
      :src="srcUrl"
      :style="maskedIconStyle"
      :class="iconClass"
      loading="lazy"
      :itemprop="shemaItemprop"
      alt=""
    />
    <slot/>
  </div>
</template>

<script setup lang="ts">
import {PropType, StyleValue} from 'vue';
import {IconVariants} from '~/types/variants';

const emit = defineEmits(['click'])
const props = defineProps({
  variant: {
    type: String as PropType<IconVariants>,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: undefined
  },
  big: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: ''
  },
  color: {
    default: '',
    type: String
  },
  url: {
    default: '',
    type: String
  },
  padding: {
    default: '',
    type: String
  },
  title: {
    default: '',
    type: String
  },
  size: {
    default: 20,
    type: [String, Number]
  },
  rounded: {
    type: Boolean,
    default: false
  },
  bordered: {
    type: Boolean,
    default: false
  },
  shemaItemprop: {
    type: String,
    default: undefined
  },
})

const srcUrl = computed((): string => {
  return props.url || ''
})

const imgComponent = computed(() => {
  return srcUrl.value ? 'img' : 'div'
})

const isHEXcolor = computed((): boolean => {
  return props.color.indexOf('#') === 0
})

const iconColor = computed((): { [x: string]: boolean | undefined } => {
  return {
    [`mdi-${props.name}`]: true,
    'rounded-icon': props.rounded,
    bordered: props.bordered,
    [props.variant]: !!props.name,
    big: props.big,
    disabled: props.disabled
  }
})

const iconClass = computed(() => {
  return {
    [`bg-${props.variant}`]: props.name && props.variant,
    [`svg-${props.variant}`]: props.url && props.variant
  }
})
const maskedIconStyle = computed((): StyleValue => {
  if (!props.url) {
    return ''
  }
  const styles = {
    width: `${props.size}px`,
    height: `${props.size}px`,
    'mask-image': `url(${props.url})`,
    '-webkit-mask-image': `url(${props.url})`,
    'background-color': props.color,
    '-webkit-mask-size': '100% 100%',
    '-webkit-mask-repeat': 'no-repeat'
  }


  return srcUrl.value
    ? {
      width: `${props.size}px`,
      height: `${props.size}px`
    }
    : styles
})

const styles = computed((): { [x: string]: string } => {
  return {
    'font-size': `${props.size}px`,
    'line-height': `${props.size}px`,
    color: isHEXcolor ? `${props.color}` : '',
    padding: props.padding
  }
})

const onClick = (e: MouseEvent): void => {
  if (props.disabled) {
    return
  }
  emit('click', e)
}
</script>
