import { ValidationCallback } from "~~/types/types"
import {getCountryByPhone} from '~/common/functions';
import {useDomainCountry} from '~/composables/states';

export const phone: ValidationCallback = (val: string, param?: any, customMessage?:string) => {
  const length = val.length
  if (length !== 11 && length !== 12 && length !== 13) {
    return customMessage || 'Неверный формат телефона'
  }
  return /^(8|7|\+7)\d{3}\d{3}\d{2}\d{2}|^(375|\+375)\d{2}\d{3}\d{2}\d{2}|^(374|\+374)\d{2}\d{2}\d{2}\d{2}|^(996|\+996)\d{3}\d{6}/i.test(val)
      || customMessage || 'Неверный формат телефона'
}

export const phoneCountry: ValidationCallback = (val: string, param?: boolean, customMessage?:string) => {
  const country = getCountryByPhone(val, param)
  const domainCountry = useDomainCountry()
  if (country !== domainCountry.value) {
    return customMessage || 'Телефон входит в диапазон другой страны'
  }
  return true
}
