import {httpApiV3, useVzFetch} from '~/composables/useVzFetch'

export async function check1CAvailability() {
  return await $fetch(
      `${httpApiV3}/ic`,
      { method: 'post' }
  ) as Promise<{ currentdate?: string, error?: any }>
}

export function uploadFile(body: FormData) {
  return useVzFetch(
      `${httpApiV3}/file`,
      { method: 'post', body }
  ) as Promise<{ url: string }>
}

export function removeFile(body: { type: string, url: string }) {
  return useVzFetch(
      `${httpApiV3}/file`,
      { method: 'DELETE', body }
  ) as Promise<any>
}

export function getCodId(orderNumber?: string|number) {
  return useVzFetch<{id: number}>(
      `${httpApiV3}/personal/_tmp/cod`,
      { method: 'post', body: { orderNumber } }
  )
}

export function setCodStatus(id: string|number, state: boolean) {
  return useVzFetch<void>(
      `${httpApiV3}/personal/_tmp/cod/${id}`,
      { method: 'put', body: { state } }
  )
}