import {PropType} from "vue";
import {IconVariants} from "~/types/variants";

export const useIconProps = {
  iconName: {
    type: String,
    default: ''
  },
  iconUrl: {
    type: String,
    default: ''
  },
  iconColor: {
    type: String,
    default: ''
  },
  iconVariant: {
    type: String as PropType<IconVariants>,
    default: ''
  },
  iconSize: {
    type: [Number, String],
    default: 16
  }
}
