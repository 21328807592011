import {defineNuxtPlugin} from '#app'
import {isDev, isDevServer, isTestServer} from '~/composables/useVzFetch';

export default defineNuxtPlugin({
  setup(nuxtApp) {
    const id = 'G-L9GLDWLL67'
    if (isDevServer || isTestServer) {
      return {}
    }

    function load(src: string) {
      var po = document.createElement('script');
      po.type = 'text/javascript';
      po.async = true;
      po.src = src;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(po, s);

      return po
    }

    const script = load(`https://www.googletagmanager.com/gtag/js?id=${id}`)

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      // @ts-ignore
      gtag('js', new Date());

      // @ts-ignore
      gtag('config', id);
    }

    // src="https://www.googletagmanager.com/gtag/js?id=G-L9GLDWLL67"></script>
    // <script>
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){dataLayer.push(arguments);}
    // gtag('js', new Date());
    //
    // gtag('config', 'G-L9GLDWLL67');

    return {}
  },
})