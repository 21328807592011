import {httpApiV3, useVzFetch} from '~/composables/useVzFetch';
import {BonusState, BonusTransaction, Task} from '~/types/components/personal/task';
import {Meta} from '~/types/types';
import {VZResponse} from '~/types/api';

export function getTaskList(params?: Partial<Meta>) {
  return useVzFetch(`${httpApiV3}/personal/user/task`, { params }) as Promise<{ meta: Meta } & { data: Task[] }>
}

export function getTransactionList(params?: Partial<Meta>, signal: AbortSignal|undefined = undefined) {
  return useVzFetch(
      `${httpApiV3}/personal/user/bonus/history`,
      { params: { byDate: 1, ...params }, signal }
  ) as Promise<{
    meta: Meta,
    data: Record<BonusTransaction['createdAt'], BonusTransaction>
  }>
}

export function getBonusState() {
  return useVzFetch(`${httpApiV3}/personal/user/bonus/state`, { params: { includeBurnout: 1 } }) as Promise<BonusState>
}

export function getTaskReview(taskId: number) {
  return useVzFetch(`${httpApiV3}/personal/user/task/review-on-website/${taskId}`) as Promise<any>
}

export function setTaskReview(taskId: number, orderNumber: number) {
  return useVzFetch(
      `${httpApiV3}/personal/user/task/review-on-website/${taskId}`,
      { method: 'PUT', body: { orderNumber }, manual: true }
  ) as Promise<VZResponse<{}>>
}

export function deleteTaskReview(taskId: number) {
  return useVzFetch(
      `${httpApiV3}/personal/user/task/review-on-website/${taskId}`,
      { method: 'DELETE' }
  ) as Promise<VZResponse<{}>>
}
