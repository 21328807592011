<template>
  <div>
    <vz-button
      link
      variant="white"
      icon-name="map-marker"
      class="public-header-region"
      icon-size="16"
      @click="openRegionModal"
    >
      <span>{{ chosenCityName }}</span>
    </vz-button>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCityStore } from '~/stores/city'
import {useHeaders} from '~/composables/states'
import VzButton from '~/components/ui-kit/vz-button.vue'

const { $showDialog } = useNuxtApp()
const headers = useHeaders()
const { currentCity } = storeToRefs(useCityStore())

const chosenCityName = computed(() => {
  return currentCity.value.name
})

function openRegionModal(): void {
  $showDialog({ name: 'region' })
}

</script>

<style lang="scss" scoped>
</style>
