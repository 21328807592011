import { ValidationCallback } from '~/types/types'

export const decimal: ValidationCallback = (val: any, param?: any, customMessage?: string | undefined): boolean | string => {
  return !val || /^([0-9-]*)$/giu.test(val) || customMessage || 'Разрешены только цифры'
}

export const min: ValidationCallback = (val: any, param?: any, customMessage?: string | undefined): boolean | string => {
  if (!val && val !== 0) {
    return true
  }
  return parseFloat(val) >= param || customMessage || `Значение должно быть больше или равно ${param}`
}

export const max: ValidationCallback = (val: any, param?: any, customMessage?: string | undefined): boolean | string => {
  if (!val && val !== 0) {
    return true
  }
  return parseFloat(val) <= param || customMessage || `Значение должно быть меньше или равно ${param}`
}
