import type { Breadcrumb, CaptureContext, Primitive, User } from '@sentry/types'
import type { Router } from 'vue-router'
import { defineNuxtPlugin } from '#app'
import {isDev, isDevServer, isTestServer} from '~/composables/useVzFetch';
import {botDetect} from "~/api/detect";

export default defineNuxtPlugin({
  async setup (nuxtApp) {
    let skip = typeof window === 'undefined' || isDev

    if (!skip) {
      const bot = await botDetect()
      if (bot) {
        skip = true
      }
    }

    if (skip) {
      return {
        provide: {
          sentrySetContext: (
              _name: string,
              _context: {
                [key: string]: any
              } | null,
          ) => {},
          sentrySetUser: (_user: User | null) => {},
          sentrySetTag: (_key: string, _value: Primitive) => {},
          sentryAddBreadcrumb: (_breadcrumb: Breadcrumb) => {},
          sentryCaptureException: (_exception: any, _captureContext?: CaptureContext) => {},
        },
      }
    }

    const {
      HttpClient: HttpClientIntegration,
      ReportingObserver: ReportingObserverIntegration
    } = await import('@sentry/integrations')
    const Sentry = await import('@sentry/vue')
    const { withScope } = await import('@sentry/vue')

    Sentry.init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      dsn: nuxtApp.$config.public.SENTRY_DSN,
      release: nuxtApp.$config.public.SENTRY_RELEASE,
      environment: isDevServer || isTestServer ? 'develop' : 'production',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router, {
            routeLabel: 'path',
          }),
        }),
        new Sentry.Replay({
          networkDetailAllowUrls: [`https//${nuxtApp.$config.public.HOST_NAME}`],
        }),
        new HttpClientIntegration(),
        new ReportingObserverIntegration(),
      ],
      // tracePropagationTargets: [nuxtApp.$config.public.SENTRY_TRACE_PROPAGATION_TARGET],
      tracePropagationTargets: [/^\/https:\/\/vozovoz\.\//],
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // Из-за этого не работает на проде
      // tracesSampleRate: 0.2,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // Из-за этого не работает на проде
      // replaysSessionSampleRate: 0.1,
      // replaysOnErrorSampleRate: 1,
      logErrors: true,
      ignoreErrors: ['Unload event listeners are deprecated and will be removed.']
    })

    nuxtApp.vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        console.log('error 0')
        scope.setExtra('context', context)
        Sentry.captureException(err)
      })
    }

    nuxtApp.hook('app:error', (err) => {
      console.log('error 1')
      Sentry.captureException(err)
    })

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    }
  },
})