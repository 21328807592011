import { ValidationRule } from "~~/types/types"
import {toRef} from '@vueuse/shared';

export const useValidateble = () => {
    const instance = getCurrentInstance()
    const bindFormField: Function | undefined = inject('bindFormField', () => {})
    const unbindFormField: Function | undefined = inject('unbindFormField', () => {})

    if (process.server) {
        return {
            isShowError: toRef(false),
            errorText: toRef(false),
            hasError: toRef(false),
            doValidate: () => {},
            dispatchFormEvent: () => {}
        }
    }
    const hasError = ref(false)
    let errorText = ref<boolean|string>('')
    let isSilent = ref(false)

    const isShowError = computed( () => {
        return hasError.value && !isSilent.value
    })

    async function doValidate(value: any, rules: ValidationRule[], silent: boolean = false): Promise<boolean> {
        errorText.value = ''
        isSilent.value = silent
        // hasError.value = false
        if ( Array.isArray(rules) ) {
            if (!rules.length) {
                hasError.value = false
            }
            for (let r of rules) {
                errorText.value = r.cb(value, r.param, r.customMessage)
                hasError.value = errorText.value === true ? false : true
                if ( silent ) {
                    errorText.value = ''
                }
                if ( hasError.value ) {
                    return true
                }
                errorText.value = ''
            }
        }
        return hasError.value
    }

    function dispatchFormEvent(controlElement: HTMLElement|null) {
        controlElement?.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
    }

    onMounted(() => {
        if (bindFormField) {
            bindFormField(instance?.uid, instance)
        }
    })
    onUnmounted( () => {
        if (unbindFormField) {
            unbindFormField(instance?.uid)
        }
    })

    return {
        isShowError,
        errorText,
        hasError,
        doValidate,
        dispatchFormEvent
    }
}
