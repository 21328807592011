import {ToastConfigureObject} from '~/types/types';
import { render, h, VNode} from '@vue/runtime-dom';
import VzToast from '~/components/ui-kit/vz-toast.vue';
import {emitter} from '~/common/event-bus';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:start', () => {
    emitter.emit('hide-toast')
  })

  function hide(component: VNode) {
    const el = component?.el as HTMLElement|any
    if (el) {
      el.classList.remove('show')
      setTimeout(() => {
        if (el.parentNode) {
          render(null, el.parentNode)
        }
      }, 300)
    }
  }

  return {
    provide: {
      showToast: (options: ToastConfigureObject) => {
        if (process.server) {
          return
        }
        const containerId = `vz-toast-container-${options.position || 'bottom'}`
        const div = document.createElement('div')
        let container = document.getElementById(containerId)

        if (!container) {
          container = document.createElement('div')
          container.setAttribute('id', containerId)
          container.setAttribute('class', `vz-toast-container vz-scroll ${containerId}`)
          document.body.appendChild(container)
        }
        container.appendChild(div)

        const toast = h(
            VzToast,
            {
              ...options,
              onHide: () => {
                options.closeCB?.()
                hide(toast)
              }
            },
            () => []
        )
        render(toast, div)

        setTimeout(() => {
          options.closeCB?.()
          hide(toast)
        }, options.autocloseDelay || 5000)

        return toast
      },
      hideToast: (toast: VNode) => hide(toast)
    }
  }
})
