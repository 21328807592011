<template>
  <div class="vz-toast show" :class="`${position} ${variant}`">
    <div class="vz-toast-title">
      <div v-if="!hasContent" class="vz-toast-wrapper-icon">
        <span
          class="vz-toast-wrapper-icon-mdi mdi"
          :class="`mdi-${ iconName }`"
          :style="{ fontSize: `${iconSize}px`, color: iconColor }"
        />
      </div>
      <span class="vz-toast-title-text">{{ title }}</span>
    </div>
    <div class="vz-toast-close" @click="onHide">
      <span class="mdi mdi-close" />
    </div>

    <div v-if="hasContent" class="vz-toast-wrapper">
      <div class="vz-toast-wrapper-icon">
      <span
        class="vz-toast-wrapper-icon-mdi mdi"
        :class="`mdi-${ iconName }`"
        :style="{ fontSize: `${iconSize}px`, color: iconColor }"
      />
      </div>
      <div class="vz-toast-wrapper-content">
        <span v-if="isStringContent" v-html="text"/>
        <component v-else :is="() => text" />
      </div>
    </div>
    <div v-if="buttonText || cancelButtonText" class="vz-toast-button-wrapper">
      <vz-button
        v-if="cancelButtonText"
        big
        class="mx-15"
        @click="onCancel"
      >
        {{ cancelButtonText }}
      </vz-button>
      <vz-button
        v-if="buttonText"
        variant="primary"
        big
        class="mx-15"
        @click="onConfirm"
      >
        {{ buttonText }}
      </vz-button>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {emitter} from '~/common/event-bus';
import {PropType} from 'vue';
import {UIKitVariants} from '~/types/variants';

const emit = defineEmits(['hide'])

const props = defineProps({
  position: {
    type: String,
    default: 'bottom'
  },
  variant: {
    type: String as PropType<UIKitVariants>,
    default: 'success'
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: 'alert-circle-outline'
  },
  iconSize: {
    type: [String, Number],
    default: 36
  },
  iconColor: {
    type: String,
    default: ''
  },
  onHide: {
    type: Function,
    default: () => {}
  },
  buttonText: {
    type: String,
    default: '',
  },
  cancelButtonText: {
    type: String,
    default: '',
  },
  cancelCB: {
    type: Function,
    default: () => {}
  },
  buttonCB: {
    type: Function,
    default: () => {}
  },
  keepAlive: {
    type: Boolean,
    default: false
  }
})

const isShow = ref(false)

const hasContent = computed(() => {
  return !!props.text
})

const isStringContent = computed(() => {
  return typeof props.text === 'string'
})

function onConfirm() {
  props.buttonCB()
  props.onHide()
}

function onCancel() {
  props.cancelCB()
  props.onHide()
}

function hide() {
  if (!props.keepAlive) {
    emit('hide')
  }
}

onMounted(() => {
  isShow.value = true
  emitter.on('hide-toast', hide)
})

onUnmounted( () => {
  emitter.off('hide-toast', hide)
})
</script>