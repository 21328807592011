import {IOrderScheduleItem, ISetAdditional, ISetCargo} from '~/types/order/price';
import {TCustomer} from '~/types/order/customer';
import {TGateway} from '~/types/order/gateway';
import {ordersButton} from '~/types/order/favor';
import {orderStatuses} from '~/types/order/status';
import {Country, PriceCurrency} from '~/types/types';

export const ORDER_CREATED_KEY = 'order-created'
export const LAST_ORDER_DRAFT_KEY = 'last-order-draft'
export const RP_TOKEN_KEY = 'rpToken'

export type ResponseMultiPickup = [
  {
    base: number
    total: number
    currency: PriceCurrency
  }
]

export type orderRecordData = {
  additional: ISetAdditional
  cargo: ISetCargo
  reject?: any
  customer: {
    /**
     * Получатель
     */
    destination: TCustomer
    /**
     * Отправитель
     */
    dispatch: TCustomer
    /**
     * Третье лицо
     */
    third: TCustomer
    /**
     * Плательщик ???
     */
    payer: 'destination' | 'dispatch' | 'third'
  }
  gateway: {
    /**
     * Место назначения
     */
    destination: TGateway,
    /**
     * Место отправления
     */
    dispatch: TGateway,
  }
  cost: {
    total: number
    base: number
    discount: number
    personal: number
    action: string
    currency: PriceCurrency
    depositBonusFromDiscount: number
    details: any
    bonus?: {
      available: number
      total: number
    }
  }
  shippingDiagram: {
    [key: string]: IOrderScheduleItem[]
  }
  meta: {
    codListTotal: number
    cost: number
    created: string
    customId: string
    marketplace: null
    availableForMultipickup?: null | number
    codList: {
      list: any[],
      shop: {
        date: string,
        order: string
      }
    }
    /** Отображаемые кнопки */
    favor: ordersButton[]
    favorList: Record<ordersButton, boolean|undefined|null>
    given: null
    guid: string
    isNetwork?: boolean
    isPaid: boolean
    linkedOrders: {[key: string]: any}
    linkedOrderList?: {[key: string]: any}
    lockState: {
      destination: boolean,
      destinationCustomer: boolean
      dispatch: boolean,
      payer: boolean
    }
    needPrint: null
    number: string
    promoCode: string
    /** 0 - CANCELED, 1 - REQUEST, 5 - ACCEPTED, 10 - ARRIVED, 20 - FINISHED */
    state: number
    stateLabel: orderStatuses
    status: {label: null, message: null}
    taken: string
    phoneNumber: string
    arrivalTerminalId: string
    destinationTerminalArrivalDateTime: string
    userType: string
    vip: boolean
    cargoLocked?: boolean
    cargoTransferLocked?: boolean
    id?: string
    unboxingOnDelivery?: {
      terminal: boolean,
      address: boolean
    }
  }
  isCanceled: boolean
  canBePaid: boolean
}

export type IShippingSchema = {
  date: string
  location: {
    id: string
    name: string
  }
  map: number[]
  state: string
  type: 'terminal' | 'address'
  time: string
}

export interface period {
end: string,
intervalHour: number,
note?: string,
start: string,
stepMinute: number
}

export type draftOrder = any // Pick<OrderPrice, 'meta'|'gateway'|'cargo'|'additional'|'customer'|'cost'|'dimConstraint'>

export type draftOrderData = {
  id: string|number
  auto: boolean
  version: string
  /** @example 2022-05-05 15:33:37 */
  createdAt: string
  /** @example 2022-05-05 15:33:37 */
  updatedAt?: string
  content?: draftOrder
  /** Передаём цену при создании/редактировании */
  cost?: number
}

export type timetable = {
  from: string;
  to: string;
  isWorking: boolean;
}

export interface InfoBlock {
  saturday: timetable;
  sunday: timetable;
  workday: timetable;
}

export interface addressRecord {
  data: {
    'district_fias': null | string;
    fias: string;
    house: null | string;
    'region_fias': string;
    street: string;
    unfamiliar?: boolean
  }
  region: string;
  value: string;
}

export type Purpose = 'dispatch' | 'destination'

export interface addressRecord {
  data: {
    'district_fias': null | string;
    fias: string;
    house: null | string;
    'region_fias': string;
    street: string;
    unfamiliar?: boolean
  }
  region: string;
  value: string;
}

export type transportType = 'default' |
        'lateralLoad' |
        'topLoad' |
        'tailLift' |
        'manipulator' |
        'openMachine' |
        'removableCurtains'

export interface ISpecialRequirements {
  driverComment: boolean
  driverCommentValue: string
  ramp110: boolean
  ramp120: boolean
  ramp130: boolean
  'bodyHeight_2': boolean
  'bodyHeight_2_1': boolean
  'bodyHeight_2_2': boolean
  'bodyHeight_2_3': boolean
  'bodyHeight_2_4': boolean
  'bodyHeight_2_5': boolean
  width210: boolean
  width220: boolean
  driverData: boolean
  driverDataValue: string
  hasEntryFee: string | number
  roadmap: boolean
  documentAddress: boolean
  documentAddressValue: addressRecord['value']
  sanitaryPassport: boolean
  rearLoading: boolean
  pallets: boolean
  passportRF: boolean
  medicalCard?: boolean | undefined
  reflectiveVest: boolean
  closedClothes: boolean
  fixingStraps: boolean
  emptyMachine: boolean
  unloadingOperations: boolean,
  unloadingOperationsValue: {
    floor: number
    freightElevator: boolean
  },
  type: transportType,
  emptyMachineCost: number,
  typeLateralLoadCost: number,
  typeTopLoadCost: number,
  typeTailLiftCost: number,
  typeManipulatorCost: number,
  typeOpenMachineCost: number,
  typeRemovableCurtainsCost: number
}


export type InventoryItem = {
  guid: string
  name: string
  tnVed: string
  /** валидный/доступный к отвозу */
  shippable: boolean
  /** Наименование единицы измерения имущества (measurement unit) */
  mUnitName: string
  declineReasonText?: string
}

export type LoadingManifest = Pick<InventoryItem, 'tnVed'|'mUnitName'> & {
  inventoryGuid: string
  inventoryName: string
  /** Состояние имущества: true – новое, иное значение – б\у */
  isNew: boolean
  /** Измерение имущества */
  measurement: number
  /** 	Стоимость имущества */
  price: number
  currency: Country
}
