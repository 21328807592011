const sumtaxId = (checkArr, arr): number => {
  return checkArr.map((num, index) => num * arr[index]).reduce((a, b) => a + b, 0)
}

export const newCheckInn = (value: string): boolean => {
  if (!value) {
    return true
  }
  const v = value.toString()
  // Belorussia (length === 9)
  if (isNaN(Number(v)) || v.length > 12 || v.length === 11) {
    return false
  }

  if (v.length === 0 || (v.length > 9 && v.length !== 10 && v.length !== 12)) {
    return true
  }

  const checkL = [2, 4, 10, 3, 5, 9, 4, 6, 8]
  const checkP = [
    [7, 2, 4, 10, 3, 5, 9, 4, 6, 8],
    [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
  ]

  if (v.length === 10) {
    const sum = sumtaxId(checkL, v)
    const div = sum % 11
    return div % 10 === Number(v[9])
  }

  if (v.length === 12) {
    const sum = [sumtaxId(checkP[0], v), sumtaxId(checkP[1], v)]
    const div = sum.map(s => s % 11)
    return div[0] % 10 === Number(v[10]) && div[1] % 10 === Number(v[11])
  }
  return false
}

export const newInnValidator = (value: string): boolean | string => {
  const text = value.length === 9 ? 'УНП' : 'ИНН'
  return newCheckInn(value) || `Неверно введен ${text}`
}
