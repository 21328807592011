import {DaDataRecord, Directions, Location, Terminal, TerminalDetail} from '~/types/types'
import {http, httpApiV3, useVzFetch} from '~/composables/useVzFetch';
import {isGuid} from '~/common/validators/guid';
import {useMemoize} from '@vueuse/core'
import {ISchedulePostData, VZResponse} from '~~/types/api';
import {CommercialOfferForm} from '~~/types/forms';

export const getLocations = useMemoize(
    (search: string | undefined = undefined, options: any = {}) => {
      if (options?.search) {
        options.search = search
      }
      return useVzFetch(
          `${http}/location/get`,
          {method: 'post', body: {search, ...options}}
      ) as Promise<Location[]>
    }
)

export function searchAddressesByLocation(
    search: string,
    city: Location,
    options = {} as { check?: boolean, suggestionOff?: boolean }
) {
  return useVzFetch(
      `${http}/location/da-data`,
      {
        method: 'post',
        body: {
          search,
          fias: city?.fias,
          kladr_id: !isGuid(city?.fias) ? undefined : city?.kladr_id,
          region_fias: city?.region_fias,
          // pname нужен если это иностранный город (fias - не guid) или краевой город (есть rcl_guid)
          pname: (!isGuid(city?.fias) || city?.rcl_guid) ? city?.name : undefined,
          count: options.check ? 1 : undefined,
          suggestionOff: options.suggestionOff ? 1 : undefined,
        }
      }
  ) as Promise<DaDataRecord[]>
}

export function getLocationByFiasGuid(fiasGuid: string): Promise<Location[]> {
  return $fetch(
      `${http}/location/get-by-fias`,
      { method: 'post', body: { fias: fiasGuid } }
  )
}

export const getAddressByGuid = useMemoize(
  (guid: string, path = undefined): Promise<{ location: Location, phones: Record<string, any> }> => {
    return $fetch(
        `${http}/location/get`,
        {method: 'post', body: {guid, path}}
    )
  }
)

export const getRestrictedAddress = useMemoize(
  (guid: string): Promise<{ location: Location, phones: Record<string, any> }> => {
    return $fetch(
        `${httpApiV3}/location/restricted-address/${guid}`,
        {method: 'GET'}
    )
  }
)

export function getLocationDaDataGeo(body: any): Promise<DaDataRecord[]> {
  return $fetch(
      `${http}/location/da-data-geo`,
      { method: 'post', body }
  )
}

export function getCityByIp(customIp: string | string[]) {
  return $fetch<Location>(
      `${httpApiV3}/location/ip`,
      { method: 'get' }
  )
}

export function getCityByTranslit(body: any): Promise<VZResponse<Directions>> {
  return $fetch(
    `${http}/location/directions`,
    { method: 'post', body }
  )
}

export const getAllTerminals = useMemoize(async () => {
      let data = await $fetch<Terminal[]>(`${http}/terminal/all`, {method: 'post'})
      if (data) {
        data = (data as any).sort((a: any, b: any) => {
          if (parseInt(a.sort_index, 10) < parseInt(b.sort_index, 10)) {
            return 1
          }
          if (parseInt(a.sort_index, 10) > parseInt(b.sort_index, 10)) {
            return -1
          }
          return 0
        })
      }
      return data
    }
)

export const getTerminal = useMemoize((guid: string) => {
      return $fetch<TerminalDetail>(
          `${http}/terminal`,
          {method: 'post', body: {guid}}
      )
    }
)

export function getSchedule(scheduleData: ISchedulePostData) {
  return $fetch<any>(
      `${http}/schedule/get`,
      { method: 'post', body: scheduleData }
  )
}

export function getScheduleDelivery(scheduleData: ISchedulePostData) {
  return $fetch<any>(
      `${http}/schedule/get-delivery/`,
      { method: 'post', body: scheduleData }
  )
}

export function sendOffer(form: CommercialOfferForm) {
  return $fetch(
      `${http}/offer/send`,
      { method: 'post', body: form }
  ) as Promise<VZResponse<{}>>
}

export function sendOfferRequest(form: CommercialOfferForm) {
  return $fetch(
      `${http}/offer/request`,
      { method: 'post', body: form }
  ) as Promise<VZResponse<{}>>
}

export function getLocationsText(body): Promise<any> {
  return useVzFetch(
      `${http}/seo/direction-text/`,
      { method: 'post', body, credentials: 'include' }
  ) as Promise<any>
}
