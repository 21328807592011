import {Terminal, TerminalPurpose} from '~/types/types';
import {defineStore} from 'pinia';
import {getAllTerminals} from '~/api/city';

export const useTerminalStore = defineStore('terminal', () => {
  const terminalList = ref<Terminal[]>([])

  // actions
  function terminalSelectByLocation(locationGuid: string, purpose: TerminalPurpose | '' = '') {
    return terminalList.value
      .filter(t => t.location_guid === locationGuid)
      .filter(t => !purpose || !t.limits.length || !t.limits.includes(purpose))
      .map(t => ({
      ...t,
      title: t.name,
      value: t.guid
    }))
  }

  async function initializeTerminalList() {
    terminalList.value = await getAllTerminals()
  }

  function terminalByGuid(guid: string) {
    return terminalList.value.find(t => t.guid === guid)
  }

  // Делаем запрос при инициализации
  initializeTerminalList().then()

  return {terminalList, terminalSelectByLocation, terminalByGuid}
})
