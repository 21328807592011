import {defineNuxtPlugin} from '#app'
import {isDev, isDevServer} from '~/composables/useVzFetch';

export default defineNuxtPlugin({
  setup(nuxtApp) {
    const host = window.location.host;
    const devSrc = 'https://chat.vozovoz.org/iframe_new/vz_chat_client.js?t=' + Date.now() + '&host=vozovoz.org&isVz=true'
    const prodSrc = 'https://chat.' + host + '/iframe_new/vz_chat_client.js?host=' + host + '&isVz=true'
    const skipRules = ['mapScheme', 'iframe', 'happynewyear2024', 'happybirthday', 'saransk-discount']

    if (isDevServer || skipRules.find(str => window.location.href.includes(str))) {
      return {}
    }

    function loadChat(src: string) {
      var po = document.createElement('script');
      po.type = 'text/javascript';
      po.async = true;
      po.src = src;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(po, s);
    }

    if (isDevServer || 0 === window.location.hostname.search('vozovoz.dev') || 0 === window.location.hostname.search('vozovoz.org')) {
      loadChat(devSrc);
      return {}
    }
    var bot_value = 0;
    // try {
    //   throw Error();
    // } catch (err) {
    //   var stack_lines = err.stack.split('\n'),
    //       found_this = false;
    //   for (var i in stack_lines) {
    //     var line = stack_lines[i];
    //     if (!found_this && /gtm\.vozovoz\.ru/.test(line) && line.match(/\&bot=(\d):/)) {
    //       bot_value = Number(line.match(/\&bot=(\d):/)[1]);
    //       break;
    //     }
    //   }
    // }
    // if (navigator.userAgent == 'ExcessiveRequestsCheck' && bot_value === 1) {
    //   bot_value = 0;
    // }

    loadChat(prodSrc);
    return {}
  },
})
