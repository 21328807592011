import {isDevServer} from '~/composables/useVzFetch';
import { load } from '@fingerprintjs/botd'

export default defineNuxtPlugin( async (nuxt) => {
  // Вручную регистрируем все плагины
  return {}
  if (process.server || !import.meta.env.PROD) {
    return
  }
  if (import.meta.env?.VITE_ENV_DOMAIN !== 'vozovoz.dev') {
    // Google Tag Manager
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start':
            new Date().getTime(),
        event: 'gtm.js'
      });
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s);
      const dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
          'https://gtm.vozovoz.ru/?id=' + i + dl;
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-T7T7DWZ')

  } else if (isDevServer) {
    const res = (await load())?.detect()
    if (res.bot) {
      return
    }

    // dev Chat
    // (function () {
    //   var po = document.createElement('script');
    //   po.type = 'text/javascript';
    //   po.async = true;
    //   po.src = 'https://chat.vozovoz.org/iframe_new/vz_chat_client.js?t=' + Date.now();
    //   var s = document.getElementsByTagName('script')[0];
    //   console.log({s})
    //   s.parentNode.insertBefore(po, s);
    // })();
  }
})
