<template>
  <vz-dialog
    name="vz-public-discount-modal"
    class="vz-public-discount-modal"
    hide-footer
    hide-header
    @showed="onShow"
  >
    <header ref="header">
      Воспользуйтесь одним из <span>персональных</span> предложений
      <vz-icon
          name="close"
          class="vz-public-discount-modal-close"
          variant="white"
          size="28"
          @click="close"
      />
    </header>
    <div class="vz-public-discount-modal-body">
      <registration-discount @select="close" :show-header="false" />
    </div>
  </vz-dialog>
</template>

<script setup lang="ts">
import VzDialog from '@/components/ui-kit/vz-dialog.vue';
import VzIcon from "~/components/ui-kit/vz-icon.vue";
import RegistrationDiscount from "~/components/public/registration-discount.vue";

const { $hideDialog } = useNuxtApp()
const route = useRoute()

const header = ref()

function onShow() {
  // Почему-то нужно 2 раза, чтобы отработали onClickOutside
  header.value.dispatchEvent(new Event('click'))
  nextTick(() => {
    header.value.dispatchEvent(new Event('click'))
  })
}

function close() {
  $hideDialog({ name: 'vz-public-discount-modal' })
}

watch(() => route.name, close)
</script>

<style lang="scss">
.vz-public-discount-modal {
  width: 960px;
  max-width: 960px;
  border-radius: 12px;
  top: 40%;
  transform: translateY(-50%);

  .vz-dialog-card-content-wrapper {
    padding: 0;

    > header {
      padding: 38px 40px 15px 70px;
      color: $pure-white;
      font-size: 36px;
      line-height: normal;
      font-weight: 800;
      text-transform: uppercase;
      background-image: url("/images/pages/home/public-discount-bg.png");
      position: relative;
    }
  }

  &-body {
    padding: 15px 70px;
  }

  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  @media screen and (min-height: 600px) and (max-height: 875px) {
    top: 50%;
    min-height: 600px;
  }

  @media screen and (max-height: 600px) {
    top: 50%;
    height: 100%;
  }

  @media screen and (max-width: $pub-sm) {
    width: 541px !important;
    top: 50%;

    .vz-dialog-card-content-wrapper {
      max-height: 100vh;

      > header {
        padding-top: 30px;
        padding-bottom: 25px;
        background-image: url("/images/pages/home/public-discount-bg-sm.png");
        background-size: cover;
        > span {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: $sm) {
    transform: unset;
  }

  @media screen and (max-width: 540px) {
    width: 100% !important;
    height: 100%;
    border-radius: unset;
    .vz-dialog-card-content-wrapper {
      max-height: 100vh;

      > header {
        font-size: 26px;
        padding-left: 8px;
      }
    }

    &-body {
      padding: 15px 8px;
    }


    &-close {
      top: 10px;
      right: 10px;
    }
  }
}
</style>
