<template>
  <div class="public-header-menu flex flex-space-between flex-align-items-center">
    <div class="public-header-menu-collapse" @mouseleave="selectedItem = ''">
      <div class="public-header-menu-collapse-items flex flex-space-between">
        <div v-for="menuItem of menuItems" class="public-header-menu-collapse-item">
          <div
            class="public-header-menu-collapse-item-title"
            :class="{open: menuItem.key === selectedItem}"
            @mousemove="selectedItem = menuItem.key"
          >
            <nuxt-link :to="menuItem.link">{{ menuItem.title }}</nuxt-link>
          </div>
          <transition name="fade">
            <div v-show="menuItem.key === selectedItem" class="public-header-menu-collapse-item-content">
              <div v-for="links in menuItem.items" class="public-header-menu-collapse-item-links">
                <nuxt-link
                  v-for="item in links"
                  :key="item.link"
                  :to="item.link"
                  @click="selectedItem = ''"
                >
                  {{ item.title }}
                </nuxt-link>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <transition name="fadeHeight">
        <div v-show="selectedItem" class="public-header-menu-collapse-ground" />
      </transition>
    </div>
    <nuxt-link :to="{ name: 'directions' }" class="public-header-menu-item" no-prefetch>
      Направления
    </nuxt-link>
    <client-only
      v-if="hasDiscount"
    >
      <vz-button
        variant="public-primary"
        big
        class="public-header-menu-discount"
        @click.prevent="emit('open-discount')"
      >
        <div class="flex flex-align-items-center" style="gap: 10px">
          <vz-icon
            url="/svg/star-percent.svg"
            variant="primary"
            size="24"
          />
          <span class="fs-18">Скидки</span>
        </div>
      </vz-button>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import VzButton from '~/components/ui-kit/vz-button.vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import useMenuItems from "~/composables/useMenuItems";
import {computed} from "vue";

const emit = defineEmits(['close', 'open-discount'])

const props = defineProps({
  hasDiscount: {
    type: Boolean,
    default: false
  }
})

const {servicesItems1, servicesItems2, infoItems} = useMenuItems()

const menuItems = computed(() => [
  {
    key: 'services',
    title: 'Услуги',
    link: '/services/',
    items: servicesItems1.value,
  },
  {
    key: 'cargo',
    title: 'Грузоперевозки',
    link: '/cargo/',
    items: servicesItems2,
  },
  {
    key: 'info',
    link: '/information/',
    title: 'Информация',
    items: infoItems,
  },
])

const selectedItem = ref('')
</script>

<style lang="scss" scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.5s ease;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 330px;
}
</style>
