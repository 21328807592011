import validate from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/var/www/2025-03-12_16_14/middleware/auth.global.ts";
import backend_45availability_45global from "/var/www/2025-03-12_16_14/middleware/backend-availability.global.ts";
import redirect_45trailing_45slash_45global from "/var/www/2025-03-12_16_14/middleware/redirect-trailing-slash.global.ts";
import seo_45global from "/var/www/2025-03-12_16_14/middleware/seo.global.ts";
import view_45transitions_45api_45global from "/var/www/2025-03-12_16_14/middleware/view-transitions-api.global.ts";
import manifest_45route_45rule from "/var/www/2025-03-12_16_14/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  backend_45availability_45global,
  redirect_45trailing_45slash_45global,
  seo_45global,
  view_45transitions_45api_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}